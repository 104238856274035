export const photoManagementSteps = [
  {
    key: 0,
    translationKey: 'cropPhoto.photoManagement.uploadPhotoTitle',
  },
  {
    key: 1,
    translationKey: 'cropPhoto.photoManagement.cropPhotoTitle',
  },
  {
    key: 2,
    translationKey: 'cropPhoto.photoManagement.summaryTitle',
  },
];
