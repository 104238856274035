import React from 'react';
import { useTheme } from '../css/Styles'
import { Typography,  Grid, Box, useMediaQuery, ThemeProvider, Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import SquareIcon from '@mui/icons-material/Square';
import { useTranslation } from 'react-i18next';

export default function Registration() {
   const theme = useTheme;
   const { t } = useTranslation();

   const isMobile = useMediaQuery(theme.breakpoints.down('phone'))

   return (
      <div style={{width: '100%', height: 'auto'}}>
      <ThemeProvider theme={theme}>
         <Box sx={{display: 'flex', width:'100%', height: '100%', padding: 2, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>     
            <Grid container sx={{width: '100%'}} alignItems='center' justifyContent='center' direction={'column'}>
               <Card sx={{backgroundColor: theme.palette.additional.main, border: '2px solid', borderColor: theme.palette.secondary.main, marginBottom: isMobile ? '1vh' : '4vh', padding: isMobile ? 0 : 2}} variant="outlined">
                  <CardContent>
                     <Typography sx={{color: theme.palette.secondary.main}} fontWeight='bold' variant={isMobile ? 'h7' : 'h1'}>{t("registration.activationLink")}</Typography>
                  </CardContent>
               </Card>
               <Grid sx={{width: isMobile ? '100%' : '70%'}} container direction='column' alignItems='flex-start'>
                  <List
                     sx={{ width: '100%', bgcolor: theme.palette.additional.main }}
                  >
                     <ListItem>
                        <ListItemIcon>
                           <SquareIcon sx={{color: theme.palette.secondary.main}}/>
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-wifi" primary={t("registration.profileSaved")} primaryTypographyProps={{fontSize: 20}}/>
                     </ListItem>
                     <ListItem>
                        <ListItemIcon>
                           <SquareIcon sx={{color: theme.palette.secondary.main}}/>
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-bluetooth" primary={t("registration.activationRequired")} primaryTypographyProps={{fontSize: 20}}/>
                     </ListItem>
                     <ListItem>
                        <ListItemIcon>
                           <SquareIcon sx={{color: theme.palette.secondary.main}}/>
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-bluetooth" primary={t("registration.activationInstruction")} primaryTypographyProps={{fontSize: 20}}/>
                     </ListItem>
                     <ListItem>
                        <ListItemIcon>
                           <SquareIcon sx={{color: theme.palette.secondary.main}}/>
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-bluetooth" primary={t('registration.spam')} primaryTypographyProps={{fontSize: 20}} />
                     </ListItem>
                  </List>
               </Grid>
            </Grid>
         </Box>
      </ThemeProvider>
      </div>
   );

}