import {createSlice} from "@reduxjs/toolkit";
import OccupationTilesFetchService from './OccupationTilesFetchService'

const emptyOccupationTile = {
    title: undefined,
    comboOptions: [],
    selectedCombo: undefined,
    exams: [],
    errors: {},
    collectiveGrade: 1,
    onlyHasCollectiveGrade: false,
}

const initialState = {
    dictionaries: {}, data: [emptyOccupationTile], dictionariesAreLoaded: false, cachedOccupationData: undefined
}

export const EXAM_TYPE = {
    PRACTICAL: "practical",
    THEORETICAL: "theoretical",
}

function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;

    return arr1.every((value, index) => value == arr2[index]);
}
const occupationTitlesSlice = createSlice({
    name: 'occupationTitles', initialState, reducers: {
        setCachedOccupationData: (state, action) => {
            state.cachedOccupationData = action.payload;
        },
        addOccupationTile: (state, action) => {
            state.data = [...state.data, emptyOccupationTile]
        }, removeOccupationTile: (state, action) => {
            state.data.splice(action.payload, 1);

        }, changeOccupationTile: (state, action) => {
            const {index, value} = action.payload;
            state.data[index].comboOptions = state.dictionaries.ExamsComboArray.filter(combo => combo.code == value);
            state.data[index].selectedCombo = undefined;
            state.data[index].exams = [];
            state.data[index].title = value;
        }, changeOccupationTileCombo: (state, action) => {

            const {index, value} = action.payload;
            let tmpCombo = state.dictionaries.ExamsComboArray.find(combo => combo.id == value)
            state.data[index].selectedCombo = {id: tmpCombo.id, code: value};

            state.data[index].exams = tmpCombo.options.map(option => {
                return ({code: option, practicalGrade: 1, theoreticalGrade: 1})
            });
        }, changeOccupationTileGrade: (state, action) => {

            let {tileIndex, gradeIndex, examType, value} = action.payload;

            if (examType == EXAM_TYPE.PRACTICAL) {
                state.data[tileIndex].exams[gradeIndex].practicalGrade = value;
            } else {
                state.data[tileIndex].exams[gradeIndex].theoreticalGrade = value;
            }
        }, changeOccupationTileCollectiveGrade: (state, action) => {
            let {tileIndex, value} = action.payload;
            state.data[tileIndex].collectiveGrade = value;

        },
        validateOccupationTileGrade: (state, action) => {
            let {tileIndex, gradeIndex, examType, componentName} = action.payload;
            let value = 1;
            if (examType == EXAM_TYPE.PRACTICAL) {
                value = parseInt(state.data[tileIndex].exams[gradeIndex].practicalGrade);
            } else {
                value = parseInt(state.data[tileIndex].exams[gradeIndex].theoreticalGrade);
            }

            let isThereAnError = false;
            if (isNaN(value)) {
                isThereAnError = true;
                value = 1;
            }
            if (value < 1) {
                isThereAnError = true;
                
                value = 1;
            } else if (value > 100) {
                isThereAnError = true;
                
                value = 100;
            }

            if (isThereAnError) {
                state.errors[componentName] = 'school.occupationTileGradeError'
            } else {
                state.errors[componentName] = undefined

            }
            
            if (examType == EXAM_TYPE.PRACTICAL) {
                state.data[tileIndex].exams[gradeIndex].practicalGrade = value;
            } else {
                state.data[tileIndex].exams[gradeIndex].theoreticalGrade = value;
            }
        },
        validateOccupationTileCollectiveGrade: (state, action) => {
            let {tileIndex, componentName} = action.payload;
            let value = parseInt(state.data[tileIndex].collectiveGrade);
            let isThereAnError = false;

            if (isNaN(value)) {
                isThereAnError = true;

                value = 1;
            }
            if (value < 1) {
                isThereAnError = true;

                value = 1;
            } else if (value > 100) {
                isThereAnError = true;

                value = 100;
            }

            if (isThereAnError) {
                state.errors[componentName] = 'school.occupationTileGradeError'
            } else {
                state.errors[componentName] = undefined

            }

            state.data[tileIndex].collectiveGrade = value;
        },
        changeOccupationOnlyHasCollectiveGrade: (state, action) => {
            let {tileIndex, value} = action.payload;
            state.data[tileIndex].collectiveGrade = 1;
            state.data[tileIndex].onlyHasCollectiveGrade = value;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(OccupationTilesFetchService.getAllOccupationDictionaries.fulfilled, (state, action) => {
                state.dictionaries.ExamsArray = action.payload[0].zawartosc;
                state.dictionaries.TitlesArray = action.payload[2].zawartosc.sort((a, b) => {
                    return a.name > b.name ? 1 : (a.name < b.name ? -1 : 0);
                });
                let tmpCombo = action.payload[1].zawartosc.map((combo) => {
                    return ({
                        code: combo.technicalProfessionalTitleCode,
                        id: combo.id,
                        options: combo.examSubjects.sort((a, b) => {
                            return a > b ? 1 : (a < b ? -1 : 0);
                        }),
                        label: combo.examSubjects.map((option) => state.dictionaries.ExamsArray.find((element) => element.code == option)).map(((element) => {
                            if (element) {
                                return (element.code + " - " + element.name)
                            }
                        })).join('\n')
                    })
                });

                state.dictionaries.ExamsComboArray = tmpCombo;


                //Here i am lodaing data from site bcs sites hates asynchronicity
                if (state.cachedOccupationData?.length !== 0) {
                    let tmpData = state.cachedOccupationData;
                    if (!Array.isArray(tmpData)) {
                        tmpData = [tmpData];
                    }

                    tmpData = tmpData.map((occupationTitle) => {

                        let tmpExams = occupationTitle.qualificationPartialGrades.map((exam) => {

                            return ({
                                    code: exam.subject,
                                    practicalGrade: exam.practicalGrade,
                                    theoreticalGrade: exam.theoreticalGrade
                                }
                            )
                        })

                        let tmpComboOptions = state.dictionaries.ExamsComboArray.filter(combo => combo.code == occupationTitle.subject);

                        let mappedOptions = occupationTitle.qualificationPartialGrades.map(exam => {
                            return (exam.subject);
                        })
                        return ({
                            title: occupationTitle.subject,
                            comboOptions: tmpComboOptions,
                            selectedCombo: tmpComboOptions.find((combo) => arraysAreEqual(combo.options, mappedOptions)),
                            exams: tmpExams,
                            collectiveGrade: parseInt(occupationTitle.grade),
                            onlyHasCollectiveGrade: occupationTitle.level == 'TECH_DIPLOMA'
                        });
                    })
                    state.data = [...tmpData]
                    state.errors = {}
                }
                state.dictionariesAreLoaded = true;
                state.errors = {}

            })
    }

});

export const {
    setCachedOccupationData,
    addOccupationTile,
    removeOccupationTile,
    changeOccupationTile,
    changeOccupationTileCombo,
    changeOccupationTileGrade,
    changeOccupationOnlyHasCollectiveGrade,
    validateOccupationTileGrade,
    validateOccupationTileCollectiveGrade,
    changeOccupationTileCollectiveGrade
} = occupationTitlesSlice.actions;

export const occupationTitlesReducer = occupationTitlesSlice.reducer;
