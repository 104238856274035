import React, { useEffect, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { PagesEnums } from "../utils/CustomEnums";

import {DatesContext} from "./GlobalDatesContext";





export const CustomRoute = (props) => {

   //accessContext
   const datesContext = useContext(DatesContext)

   // const dates = useContext(DatesContext)
   const dates = datesContext.dates
   
   const checkDate = () => {
      let currentDate = new Date(dates.currentDateTime)
      let launchDate = new Date(dates.generalDates.portalLaunch)
      let closureDate = new Date(dates.generalDates.portalClosure)

      if (currentDate < launchDate || currentDate > closureDate) {
         return false;
      }

      return true;
   }


   return (
      checkDate() === true ?
         props.children :
         <Navigate to={PagesEnums.SERVICE_DISABLED.link} />
   )
}