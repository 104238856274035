import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {FormHelperText} from "@mui/material";
import React from "react";
import {useStyles} from "../../../../css/Styles";
import {Typography} from "@material-ui/core";


export default function SelectCombo(props) {
    const classes = useStyles();
    return (
        <FormControl fullWidth>   {/*error = {props.error.isError}*/}
            <InputLabel sx={{color: '#808080', "&.Mui-focused": {color: "#808080"}}}
                        id={props.name}>{props.label}</InputLabel>
            <Select
                sx={{minHeight: '130px'}}

                labelId={props.name}
                disabled={props.editState}
                id={props.name}
                name={props.name}
                value={props.value}
                label={props.label}
                onChange={props.onChange}
                className={classes.customSelect}
                variant="outlined"
            >

                {
                    props.options.map((item) =>
                        <MenuItem key={item.id} value={item.id}>
                            <Typography
                                style={{whiteSpace: 'pre-line'}}
                            >
                                {item.label}
                            </Typography>
                        </MenuItem>
                    )
                }
            </Select>
            <FormHelperText>{/*{props.error.isError ? props.error.errorMessage : props.caption}*/}</FormHelperText>
        </FormControl>
    )
}
