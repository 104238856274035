import {createNotificationForResponse} from '../../../../utils/Notifications';
import {apiGatewayUrl as ApiUrl, HTTP_GET, HTTP_POST} from '../../../../utils/Consts';
import AuthHeader from "../../../../services/AuthHeader";
import {createAsyncThunk} from '@reduxjs/toolkit'

const URL = ApiUrl;

class OccupationTilesFetchService {


    getAllOccupationDictionaries = createAsyncThunk('occupationTitles/getAllOccupationDictionaries', async () => {
        const urls = [
            'dictionary/technicalSchools/examSubjects',
            'dictionary/technicalSchools/titleExamSubjects',
            'dictionary/technicalSchools/titles'
        ];

        const dataArray = await Promise.all(
            urls.map(url => {
                return fetch(URL + url, {
                    method: HTTP_GET,
                    headers: AuthHeader()
                })
                    .then(async response => {
                        if (response.ok) {
                            return await response.json();
                        } else {
                            let json = await response.json();
                            createNotificationForResponse(json, "", response.status);
                        }
                    });
            })
        );

        return dataArray;
    });

    getExamSubjects = createAsyncThunk('occupationTitles/getExamSubjects', async () => {
        const response = await fetch(URL + 'dictionary/technicalSchools/examSubjects', {
            method: HTTP_POST, headers: AuthHeader()
        });

        if (response.ok) {
            return await response.json()
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status)
        }
    })

    getExamTitleExamSubjects = createAsyncThunk('occupationTitles/getExamTitleExamSubjects', async () => {
        const response = await fetch(URL + 'dictionary/technicalSchools/titleExamSubjects', {
            method: HTTP_POST, headers: AuthHeader()
        });

        if (response.ok) {
            return await response.json()
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status)
        }
    })

    getTitles = createAsyncThunk('occupationTitles/getTitles', async () => {
        const response = await fetch(URL + 'dictionary/technicalSchools/titles', {
            method: HTTP_POST, headers: AuthHeader()
        });

        if (response.ok) {
            return await response.json()
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status)
        }
    })
}

export default new OccupationTilesFetchService();