import { useContext } from 'react';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import PhotoManagerContext from '../../PhotoManagementContext';

const StepButtons = ({ handleNextStep, handlePreviousStep, handleEndAction, sendingStatus }) => {
  const { activeStep } = useContext(PhotoManagerContext);
  const { t } = useTranslation();

  return (
    <Box textAlign='right' sx={{ mt: 2 }}>
      <Button color='inherit' disabled={activeStep === 0 || sendingStatus} onClick={handlePreviousStep} sx={{ mr: 1 }}>
        {t('cropPhoto.photoManagement.stepBack')}
      </Button>
      {handleEndAction ? (
        <Button onClick={handleEndAction} disabled={sendingStatus}>
          {t('cropPhoto.photoManagement.stepSend')}
        </Button>
      ) : (
        <Button onClick={handleNextStep} disabled={activeStep === 0}>
          {t('cropPhoto.photoManagement.stepNext')}
        </Button>
      )}
    </Box>
  );
};

export default StepButtons;
