import { t } from 'i18next';
import readFileDataAsBase64 from './readFileDataAsBase64';
import { ACTIONS } from '../utils/Consts';

export default function handleFile(event, fileInfo, dispatchFiles) {
   let sizeBytes = fileInfo.maxSize * 1000000
   if (event.target.files[0].size > sizeBytes) {
      alert(t('school.fileSizeAlert') + fileInfo.maxSize.toString() + 'MB!');
   } else {
      readFileDataAsBase64(event).then(fileString => {
         dispatchFiles({ 
            type: ACTIONS.SET_FILE_DATA, 
            payload: { 
               name: event.target.files[0].name, 
               data: fileString.split(',')[1], 
               dataType: fileString.split(',')[0], 
               size: event.target.files[0].size,
               typeId: fileInfo.typeId 
         } })
      })
   }
}