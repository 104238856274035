import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';

import PhotoManagementContext from '../../PhotoManagementContext';

import { ControlsArea, CropArea } from './components';
import { StepButtons } from '../../components';
import { getCroppedImg } from './canvasUtils';

const CropPhoto = () => {
  const { photoFile, setPhotoBaseCropped, handleNextStep, handlePreviousStep } = useContext(PhotoManagementContext);
  const [photoBaseSrc, setPhotoBaseSrc] = useState();
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();

  useEffect(() => {
    if (!photoFile) {
      return;
    }

    changePhotoFileToBase64(photoFile);
  }, [photoFile]);

  const changePhotoFileToBase64 = async photo => {
    const imageDataUrl = await new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(photo);
    });

    setPhotoBaseSrc(imageDataUrl);
  };

  const cropPhoto = useCallback(async () => {
    const croppedPhoto = await getCroppedImg(photoBaseSrc, croppedAreaPixels, rotation);

    setPhotoBaseCropped(croppedPhoto);
  }, [photoBaseSrc, croppedAreaPixels, rotation, setPhotoBaseCropped]);

  const onClickNextStep = () => {
    cropPhoto();
    handleNextStep();
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <ControlsArea zoom={zoom} setZoom={setZoom} rotation={rotation} setRotation={setRotation} />
        <CropArea
          photoBaseSrc={photoBaseSrc}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          setCrop={setCrop}
          setRotation={setRotation}
          setZoom={setZoom}
          setCroppedAreaPixels={setCroppedAreaPixels}
        />
      </Box>
      <StepButtons handleNextStep={onClickNextStep} handlePreviousStep={handlePreviousStep} />
    </>
  );
};

export default CropPhoto;
