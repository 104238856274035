import {Box, Button, Card, CardContent, IconButton, Typography, useMediaQuery} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useEffect} from "react";
import {useStyles, useTheme} from "../../../../css/Styles";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import Divider from '@mui/material/Divider';


import {
    addOccupationTile,
    changeOccupationOnlyHasCollectiveGrade,
    changeOccupationTile,
    changeOccupationTileCollectiveGrade,
    changeOccupationTileCombo,
    changeOccupationTileGrade,
    EXAM_TYPE,
    removeOccupationTile,
    validateOccupationTileCollectiveGrade,
    validateOccupationTileGrade
} from "./OccupationTilesReducer"
import SelectTitle from "./SelectTitle";
import SelectCombo from "./SelectCombo";
import InputGrade from "./InputGrade";
import OccupationTilesFetchService from './OccupationTilesFetchService'
import {Skeleton} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";


export default function OccupationTiles({editState}) {
    const theme = useTheme;
    const {t} = useTranslation();
    const classes = useStyles();


    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('phone'))
    const occupationTitlesData = useSelector(state => state.occupationTitles.data)
    const errors = useSelector(state => state.occupationTitles.errors)
    const occupationTitlesDictionaries = useSelector(state => state.occupationTitles.dictionaries)
    const isLoaded = useSelector(state => state.occupationTitles.dictionariesAreLoaded)

    useEffect(() => {
        dispatch(OccupationTilesFetchService.getAllOccupationDictionaries())
    }, [dispatch])


    return (

        <>
            <Card className={classes.customCardField} variant="outlined">
                <CardContent>
                    {isLoaded ?
                        <>
                            {occupationTitlesData?.map((occupationTitle, i) => {
                                return (
                                    <>

                                        <Box key={i} sx={{
                                            display: 'flex',
                                            width: '100%',
                                            height: '100%',
                                            marginBottom: '5px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: isMobile ? 'column' : 'row',
                                            gap: "5px"
                                        }}>
                                            <SelectTitle editState={editState} id={'title' + i}
                                                         name={'title' + i} label={t('school.title')}
                                                         options={occupationTitlesDictionaries.TitlesArray}
                                                         value={occupationTitle.title}
                                                         onChange={(event) => dispatch(changeOccupationTile({
                                                             index: i,
                                                             value: event.target.value
                                                         }))}/>
                                            <SelectCombo
                                                editState={editState || occupationTitle?.comboOptions.length == 0}
                                                id={'examType' + i}
                                                name={'examType' + i} label={t('school.examType')}
                                                options={occupationTitle.comboOptions}
                                                value={occupationTitle.selectedCombo?.id}
                                                onChange={(event) => dispatch(changeOccupationTileCombo({
                                                    index: i,
                                                    value: event.target.value
                                                }))}/>
                                            <FormControlLabel sx={{marginLeft: '20px', minWidth: '150px'}} disabled={editState}
                                                              control={<Checkbox color='secondary' disabled={editState}
                                                                                 checked={occupationTitle.onlyHasCollectiveGrade}
                                                                                 onChange={(event) => dispatch(changeOccupationOnlyHasCollectiveGrade({
                                                                                     tileIndex: i,
                                                                                     value: event.target.checked
                                                                                 },))}/>}
                                                              label={t('school.onlyDyplom')}/>
                                            {
                                                occupationTitlesData.length > 1 && i + 1 == occupationTitlesData.length ?
                                                    <>
                                                        <Divider/>
                                                        <IconButton disabled={editState} id={'button' + i}
                                                                    color="secondary"
                                                                    size='large'
                                                                    sx={{marginRight: '30px'}}
                                                                    onClick={() => dispatch(removeOccupationTile(i))}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </>
                                                    : null
                                            }
                                        </Box>
                                        {occupationTitle.onlyHasCollectiveGrade ?

                                            <>
                                                <Typography fontWeight='bold'
                                                            sx={{marginTop: '5px', marginBottom: '5px',}}>
                                                    {t('school.collectiveResult')}
                                                </Typography>

                                                <InputGrade
                                                    editState={editState}
                                                    name={'exam' + i + 'collectiveResult'}
                                                    title={t('school.collectiveResult')}
                                                    className={classes.customTextField} //Somwhere someday do it properly 
                                                    label={t('school.collectiveResult')}
                                                    value={occupationTitle.collectiveGrade}
                                                    errors={errors}
                                                    onChange={(event) => dispatch(changeOccupationTileCollectiveGrade({
                                                        tileIndex: i,
                                                        value: event.target.value

                                                    }))}
                                                    onBlur={(event) => dispatch(validateOccupationTileCollectiveGrade({
                                                        tileIndex: i,
                                                        componentName: event.target.name,

                                                    }))}/>
                                            </>
                                            :
                                            <>
                                                <Typography fontWeight='bold' sx={{
                                                    marginTop: '5px',
                                                    marginBottom: '5px',
                                                }}>
                                                    {t('school.separateResults')}
                                                </Typography>
                                                {
                                                    occupationTitle.exams.map((exam, j) => {
                                                        let dicExam = occupationTitlesDictionaries.ExamsArray.find((element) => element.code == exam.code);
                                                        if (dicExam) {
                                                            return (
                                                                <>
                                                                    <Typography sx={{
                                                                        marginTop: '5px',
                                                                        marginBottom: '5px',
                                                                    }}>
                                                                        {dicExam.code + " - " + dicExam.name}
                                                                    </Typography>
                                                                    <Box key={i} sx={{
                                                                        display: 'flex',
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        marginBottom: '10px',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        flexDirection: isMobile ? 'column' : 'row',
                                                                        gap: "5px"
                                                                    }}>
                                                                        <InputGrade editState={editState}
                                                                                    name={'exam' + j + 'resultTheoretical'}
                                                                                    title={t('school.resultTheoretical')}
                                                                                    className={classes.customTextField}
                                                                                    label={t('school.resultTheoretical')}
                                                                                    value={exam.theoreticalGrade}
                                                                                    errors={errors}
                                                                                    
                                                                                    onChange={(event) => dispatch(changeOccupationTileGrade({
                                                                                        tileIndex: i,
                                                                                        gradeIndex: j,
                                                                                        examType: EXAM_TYPE.THEORETICAL,
                                                                                        value: event.target.value
                                                                                    }))}
                                                                                    onBlur={(event) => dispatch(validateOccupationTileGrade({
                                                                                        tileIndex: i,
                                                                                        gradeIndex: j,
                                                                                        componentName: event.target.name,
                                                                                        examType: EXAM_TYPE.THEORETICAL,
                                                                                    }))}
                                                                        />
                                                                        <InputGrade editState={editState}
                                                                                    name={'exam' + j + 'resultPractical'}
                                                                                    title={t('school.resultPractical')}
                                                                                    className={classes.customTextField}
                                                                                    label={t('school.resultPractical')}
                                                                                    value={exam.practicalGrade}
                                                                                    errors={errors}
                                                                                    onChange={(event) => dispatch(changeOccupationTileGrade({
                                                                                        tileIndex: i,
                                                                                        gradeIndex: j,
                                                                                        examType: EXAM_TYPE.PRACTICAL,
                                                                                        value: event.target.value
                                                                                    }))}
                                                                                    onBlur={(event) => dispatch(validateOccupationTileGrade({
                                                                                        tileIndex: i,
                                                                                        gradeIndex: j,
                                                                                        componentName: event.target.name,
                                                                                        examType: EXAM_TYPE.PRACTICAL,
                                                                                    }))}
                                                                        />

                                                                    </Box>
                                                                </>
                                                            )
                                                        }

                                                    })
                                                }
                                            </>

                                        }

                                        {occupationTitlesData.length > 1 && occupationTitlesData.length != i + 1 ?
                                            <Divider sx={{marginBottom: '10px', marginTop: '5px'}}/> : null}

                                    </>)
                            })}

                            <Button
                                sx={{
                                    width: '5%',
                                    marginTop: '5px',
                                    minWidth: 200,
                                    color: theme.palette.primary.main,
                                    backgroundColor: theme.palette.secondary.main,
                                    '&:hover':
                                        {
                                            backgroundColor: theme.palette.secondary.hovered,
                                            borderColor: 'none', boxShadow: 'none',
                                        },
                                    '&.Mui-disabled': {
                                        backgroundColor: theme.palette.additional.disabled,
                                    }
                                }
                                }
                                disabled={editState}
                                onClick={(value) => dispatch(addOccupationTile(value))}
                                variant='standard'>
                                {t('school.addQualification')}
                            </Button>

                        </>
                        : <>
                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                height: '100%',
                                marginBottom: '5px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: isMobile ? 'column' : 'row',
                                gap: "5px"
                            }}>
                                <Skeleton variant="rectangular" width={'100%'} height={130}/>
                                <Skeleton variant="rectangular" width={'100%'} height={130}/>
                                <Skeleton variant="rectangular" width={'15%'} height={130}/>
                            </Box>
                            <Skeleton variant="rectangular" width={'10%'} height={40}/>
                        </>}

                </CardContent>
            </Card>
        </>)
}


