import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import ResignationModal from './ResignationModal';
import PrintApplicationModal from './PrintApplicationModal';
import { useTranslation } from "react-i18next";

export default function StatusButton({buttonType, id, candidateType, fieldOfStudy, recruitmentModeKey, applicationId, fetchRecruitmentResults, setLoadingCandidateCourses}) {

  const [buttonLabel, setButtonLabel] = React.useState();
  const [openModal, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState(null);

  const { t } = useTranslation();

  const modalNames = {
    resignation: ResignationModal,
    print: PrintApplicationModal
  }

  useEffect(() => {
    switch (buttonType) {
      case 1:
        setButtonLabel(t('recruitment.print_application'));
        setCategory("print")
        break;
      case 2:
        setButtonLabel(t('recruitment.resignate'));
        setCategory("resignation")
        break;
    }
  }, [])

  var CustomModal = modalNames[category];

  const closeHandle = () => {
    setOpen(false);
  };

  const clickHandler = () => {
    setOpen(true);
  };

    return (
      <>
        <Button variant="contained" onClick={clickHandler} className="recruitment-button">{buttonLabel}</Button>
        {CustomModal && openModal && <CustomModal openModal={openModal} closeHandle={closeHandle} idCourse={id} candidateType={candidateType} fieldOfStudy = {fieldOfStudy} recruitmentModeKey = {recruitmentModeKey} applicationId = {applicationId} fetchRecruitmentResults={fetchRecruitmentResults} setLoadingCandidateCourses={setLoadingCandidateCourses}/>}
      </>
    )
}