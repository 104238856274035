import React, {useContext, useEffect} from 'react';
import {ThemeProvider, Toolbar, IconButton, Typography, Button, Grid, Box, useMediaQuery} from '@material-ui/core';
import {useStyles, useTheme} from '../css/Styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeIcon from '@mui/icons-material/Home';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import AuthService from '../services/AuthService';
import {getCredentials, getIdWithoutPrefix} from '../services/UserDataService';
import {styled} from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import {PagesEnums, SessionTypesEnums} from '../utils/CustomEnums';
import FetchService from '../services/FetchService';
import i18n from "i18next";
import Popup, {popupReducer} from "../components/popup/Popup";
import {DatesContext} from "../components/GlobalDatesContext";

async function fetchPersonalData() {
    return await FetchService.getAddressPersonal()
}

export default function Header() {
    const theme = useTheme;
    const classes = useStyles();
    const {t} = useTranslation();

    const [czId, setCzId] = React.useState(null);
    const [popup, dispatchPopup] = React.useReducer(popupReducer, null);
    const datesContext = useContext(DatesContext)

    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))


    useEffect(() => {
        if (getCredentials()) {
            fetchPersonalData().then(response => {
                setCzId(response?.zawartosc.czId)
            })
        }
    }, [])

    const BootstrapButton = styled(Button)({
        // boxShadow: 'none',
        // textTransform: 'none',
        fontSize: 14,
        color: 'white',
        padding: '6px 12px',
        // border: '1px solid',
        borderColor: 'none',
        lineHeight: 1,
        backgroundColor: 'none',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: theme.palette.secondary.hovered,
            borderColor: 'none',
            boxShadow: 'none',
        },
        // '&:active': {
        //   boxShadow: 'none',
        //   backgroundColor: '#f2f2f2',
        //   borderColor: 'none',
        // },
        // '&:focus': {
        //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    });

    const logout = () => {
        AuthService.logout();
    }

    const navigateToTulPage = () => {
        window.open("https://p.lodz.pl/")
    }

    const handleHelpButton = () => {
    }


    const handleChangeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
        window.location.reload();
    };





    return (
        <ThemeProvider theme={theme}>
            {Popup(popup, dispatchPopup) /*popup*/}
            <div>
                <Toolbar position="fixed" className={classes.headerWhite}>
                    <Grid container direction='row' className={classes.content} justifyContent='space-between'
                          alignItems='center'>
                        <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                            <Button onClick={navigateToTulPage}>
                                <img alt='PLLogo' className={classes.headerLogo} src="/assets/logotyp.png"/>
                            </Button>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                            <Grid container direction='column' alignItems={isMobile ? 'flex-start' : 'flex-end'}>
                                <Typography variant="h1" className={classes.headerTypo}>
                                    {t("header.title")} <br/>
                                </Typography>
                                {/* TODO style dla buttona */}
                                {getCredentials() ?
                                    <React.Fragment>
                                        <Grid container direction='column' alignItems='flex-end'>
                                            <Typography variant="h2" className={classes.headerTypo}>
                                                {t('header.loggedInAs')} {getCredentials()}
                                            </Typography>
                                            <Typography variant="h2" className={classes.headerTypo}>
                                                {t('header.id')} {getIdWithoutPrefix()}
                                            </Typography>
                                        </Grid>
                                        {/* <Button style={{ 'color': 'black'}} onClick={logout}>{t("header.logout")}</Button> */}
                                    </React.Fragment> :
                                    null
                                }
                            </Grid>
                        </Box>
                    </Grid>
                </Toolbar>
                <Toolbar position="fixed" className={classes.headerBlue}>
                    <Box className={classes.content}>
                        <Grid container direction="row" alignItems="center">
                            {/* <IconButton variant="contained" component={NavLink} sx={{display: 'flex', position: 'relative'}} to={getCredentials() ? PagesEnums.MAIN.link : PagesEnums.HOME.link} children={<HomeIcon sx={{fontSize: 30}} className={classes.iconButtonWhite}/>}/> */}
                            <Typography variant={isMobile ? 'h3' : 'h2'} className={classes.subHeaderTypo}>
                                <Grid container direction={isMobile ? 'column' : 'row'} alignItems='center'>
                                    <IconButton variant="contained" component={NavLink}
                                                to={getCredentials() ? PagesEnums.MAIN.link : PagesEnums.HOME.link}
                                                children={<HomeIcon sx={{fontSize: 30}}
                                                                    className={classes.iconButtonWhite}/>}/>
                                    {t("header.subTitle1")}
                                    {datesContext.dates && datesContext.dates.generalDates.recruitmentModeEntities.name}
                                    {t("header.subTitle2")}
                                </Grid>
                            </Typography>
                        </Grid>
                        <Grid sx={{maxWidth: '250px'}} container direction="row" alignItems="center"
                              justifyContent='flex-end'>
                            <IconButton variant="contained"
                                        onClick={() => dispatchPopup({type: 'OPEN', payload: 'header.help'})}
                                        children={<HelpOutlineIcon sx={{fontSize: 24}}
                                                                   className={classes.iconButtonWhite}/>}/>
                            <ToggleButtonGroup
                                color="primary"
                                value={i18n.language}
                                exclusive
                            >
                                <ToggleButton sx={{fontSize: 12}} className={classes.toggleButton} value="pl"
                                              onClick={() => handleChangeLanguage('pl')}>PL</ToggleButton>
                                <ToggleButton sx={{fontSize: 12}} className={classes.toggleButton} value="en"
                                              onClick={() => handleChangeLanguage('en')}>EN</ToggleButton>
                            </ToggleButtonGroup>
                            {getCredentials() ?
                                <BootstrapButton style={{marginLeft: 15}} startIcon={<LogoutIcon/>} variant="standard"
                                                 onClick={logout}>{t("header.logout")}</BootstrapButton> : null}
                        </Grid>
                    </Box>
                </Toolbar>

            </div>
        </ThemeProvider>
    );
}
