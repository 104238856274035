import Cropper from 'react-easy-crop';
import { Box } from '@material-ui/core';

import { photoValidationRules } from '../../../../photoValidationRules';

const CropArea = ({ photoBaseSrc, crop, rotation, zoom, setCrop, setRotation, setZoom, setCroppedAreaPixels }) => (
  <Box component='section' sx={{ position: 'relative', width: '65%', height: 400, backgroundColor: '#e3e3e3' }}>
    <Cropper
      image={photoBaseSrc}
      crop={crop}
      rotation={rotation}
      zoom={zoom}
      aspect={photoValidationRules.aspectRatio}
      onCropChange={setCrop}
      onRotationChange={setRotation}
      onCropComplete={(croppedArea, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
      onZoomChange={setZoom}
    />
  </Box>
);

export default CropArea;
