import { SessionTypesEnums } from "../utils/CustomEnums";
import { get } from "./StorageService";
import { CANDIDATE_PREFIX } from '../utils/Consts';
let token = require("jsonwebtoken");

export const getCredentials = () => {
    let jwt = get("token", SessionTypesEnums.COOKIE);
    if (jwt) {
        let credentials = token.decode(jwt)["user"];
        return credentials.email;
    } else {
        return "";
    }
}

export const getId = () => {
    let jwt = get("token", SessionTypesEnums.COOKIE);
    if (jwt) {
        let credentials = token.decode(jwt)["user"];
        return credentials.user_id;
    } else {
        return "";
    }
}

export const getIdWithoutPrefix = () => {
    if(getId()) {
        let ids = getId().split('_');
        if(ids[0] === CANDIDATE_PREFIX) {
            return ids[1]
        } else {
            throw new Error()
        }
    } else {
        throw new Error()
    }
}

export const getRoles = () => {
    let jwt = get("token", SessionTypesEnums.COOKIE);
    if (jwt) {
        let credentials = [];
        credentials = token.decode(jwt)["user"]['authorities'];
        let roleSet = [];
        credentials.forEach(function (element) {
            roleSet.push(element.authority);
        })
        return roleSet;
    } else {
        return null;
    }
}

export const hasActiveRole = (roleName) => {
    let roleSet = [];
    roleSet = getRoles();
    if (roleSet != null) {
        if (roleSet.includes(roleName)) {
            return true;
        } else return false;
    } else return false;
}