import React, {useContext} from 'react'
import {useTheme} from '../css/Styles'
import {useTranslation} from 'react-i18next';
import {PagesEnums, SessionTypesEnums} from '../utils/CustomEnums';
import {NavLink} from 'react-router-dom';
import {getCredentials} from '../services/UserDataService';
import AuthService from '../services/AuthService';

import {Typography, Button, useMediaQuery, Card, CardContent} from '@material-ui/core';
import {get as GetFromStorage} from "../services/StorageService";
import i18n from "i18next";
import {DatesContext} from "./GlobalDatesContext";

function getDatesFromStorage() {
    const dates = GetFromStorage('dates', SessionTypesEnums.LOCAL_STORAGE)
    return JSON.parse(dates)
}

export default function ServiceDisabled() {
    const theme = useTheme;
    const isMobile = useMediaQuery(theme.breakpoints.down('phone'))
    const {t} = useTranslation();
    const datesContext = useContext(DatesContext)

    return (
        <div style={{width: '100%', display: 'grid', placeItems: 'center'}}>
            <Card sx={{
                width: '70%',
                backgroundColor: theme.palette.additional.main,
                border: '2px solid',
                borderColor: theme.palette.secondary.main
            }} variant="outlined">
                <CardContent>
                    <Typography sx={{color: theme.palette.secondary.main}} fontWeight='bold'
                                variant={isMobile ? 'h7' : 'h2'}>{t("serviceDisabled")}
                    </Typography>
                    <Typography sx={{color: theme.palette.secondary.main}} fontWeight='bold'
                                variant={isMobile ? 'h7' : 'h2'}>{t("serviceDisabled3")}
                    </Typography>
                    <Typography sx={{color: theme.palette.secondary.main}} fontWeight='bold'
                                variant={isMobile ? 'h7' : 'h2'}>{t("serviceDisabled2")}
                    </Typography>
                </CardContent>
                <Button
                    sx={{
                        fontWeight: 'bold',
                        margin: 5,
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.secondary.main,
                        '&:hover':
                            {
                                backgroundColor: theme.palette.secondary.hovered,
                                borderColor: 'none', boxShadow: 'none',
                            },
                    }
                    }
                    component={NavLink}
                    to={getCredentials() ? '' : PagesEnums.HOME.link}
                    onClick={getCredentials() ? AuthService.logout : null}
                    variant='contained'>
                    {getCredentials() ? t('header.logout') : t('mainPageButton')}
                </Button>
            </Card>

        </div>
    )
}
