export const CustomEnums = Object.freeze({
    "OPERACJA_ZAKONCZONA_POWODZENIEM": "operacja_zakonczona_powodzeniem"
})
//typy przechowywania stanów sesji
export const SessionTypesEnums = Object.freeze({
    "LOCAL_STORAGE": "local_storage",
    "SESSION_STORAGE": "session_storage",
    "COOKIE": "cookie"
})

//routing wraz z informacją o wymaganym uwierzytelnieniu
export const PagesEnums = Object.freeze({
    "HOME": {link: "/", requiredAuth: false},
    "MAIN": {link: "/main", requiredAuth: true},
    "REGISTER": {link: "/registration", requiredAuth: false},
    "HELP": {link: "/help", requiredAuth: false},
    "RESTORE_PASSWORD": {link: "/passrestore", requiredAuth: false},
    "ACCESS_DENIED": {link: "/accessDenied", requiredAuth: false},
    "PERSONAL": {link: "/personal", requiredAuth: true},
    "ACTIVATION": {link: "/activation", requiredAuth: false},
    "SETPASS": {link: "/setpass/:id/:reset", requiredAuth: false},
    "CROP_PHOTO": {link: "/cropPhoto", requiredAuth: false},
    "SCHOOL": {link: "/school", requiredAuth: true},
    "COURSE_SELECTION": {link: "/courseSelection", requiredAuth: true},
    "PASS_RESTORE_LINK": {link: "/passrestorelink", requiredAuth: false},
    "MESSAGES": {link: "/messages", requiredAuth: true},
    "RECRUITMENT_RESULTS": {link: "/recruitmentResults", requiredAuth: true},
    "STUDENT_ZONE": {link: "/studentzone", requiredAuth: true},
    "ACTIVATION_STUDENT_ACCOUNT": {link: "/activationStudentAccount", requiredAuth: true},
    "SERVICE_DISABLED": {link: '/serviceDisabled', required: false}
})

export const PersonalDataAdressesEnums = Object.freeze({
    "STALY": "staly",
    "KORESPONDENCYJNY": "korespondencyjny"
})

export const PersonalDataAdressesAdministrativeTypesEnums = Object.freeze({
    "MIASTO": "miasto",
    "WIES": "wies"
})

//do słowników
export const DictionaryTypeEnums = Object.freeze({
    "ADDRESS_PERSONAL": "ADDRESS_PERSONAL",
    "SCHOOL": "SCHOOL",
    "SCHOOL_SECOND_DEGREE": "SCHOOL_2",
    "SCHOOL_THIRD_DEGREE": "SCHOOL_3",
    "SCHOOL_NAME": "SCHOOL_NAME"
})

