import './App.css'
import { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import { CssBaseline } from '@material-ui/core';
import { ErrorBoundary } from 'react-error-boundary';
import { PrivateRoute } from './components/PrivateRoute';
import { KANDYDAT } from './utils/Roles';
import { PagesEnums } from "./utils/CustomEnums";
import UnexpectedError from './components/UnexpectedError';
import AccessDenied from './components/AccessDenied';
import UserSessionCheck from './components/UserSessionCheck';
import AuthPage from './components/AuthPage';
import PageNotFound from './components/PageNotFound';
import Header from './templates/Header';
import Footer from './templates/Footer';
import Home from './components/Home';
import Main from './components/Main'
import Registration from './components/Registration';
import PassRestoraion from './components/PassRestoration';
import Help from './components/Help';
import Personal from './components/personal/Personal'
import ActivationLink from './components/ActivationLink'
import SetPassword from './components/SetPassword'
import School from './components/school/School';
import Kadrowanie from './components/crop';
import PassRestorationLink from './components/PassRestorationLink'
import CourseSelection from './components/courseSelection/CourseSelection';
import Messages from './components/messages/Messages';
import RecruitmentResults from './components/recruitmentResults/RecruitmentResults';
import StudentZone from './components/StudentZone';
import Activation from './components/activation/Activation'
import ServiceDisabled from './components/ServiceDisabled';
import { CustomRoute } from './components/CustomRoute';
import GlobalDatesContext from './components/GlobalDatesContext';

function App() {
  return (
    <Fragment>
      <GlobalDatesContext>
        <div className="App">
          <Header className='Header' />
          <ReactNotifications />
          <CssBaseline />
          <AuthPage />
          <ErrorBoundary FallbackComponent={UnexpectedError}>
            <UserSessionCheck />
            <div className="Main">
              <Routes>
                <Route exact path={PagesEnums.HOME.link} element={<Home />} />
                <Route path={PagesEnums.MAIN.link} element={
                  <PrivateRoute roles={[KANDYDAT]}>
                      <Main />
                  </PrivateRoute>}
                />
                <Route path={PagesEnums.PERSONAL.link} element={
                  <PrivateRoute roles={[KANDYDAT]}>
                    <Personal />
                  </PrivateRoute>}
                />
                <Route path={PagesEnums.SCHOOL.link + "/:id"} element={
                  <PrivateRoute  roles={[KANDYDAT]}>
                    <School />
                  </PrivateRoute>
                }
                />
                <Route path={PagesEnums.COURSE_SELECTION.link + "/:id"} element={
                  <PrivateRoute  roles={[KANDYDAT]}>
                    <CourseSelection />
                  </PrivateRoute>
                }
                />
                <Route path={PagesEnums.CROP_PHOTO.link} element={
                  <PrivateRoute roles={[KANDYDAT]}>
                    <Kadrowanie />
                  </PrivateRoute>}
                />
                <Route path={PagesEnums.MESSAGES.link} element={
                  <PrivateRoute  roles={[KANDYDAT]}>
                    <Messages />
                  </PrivateRoute>}
                />
                <Route path={PagesEnums.RECRUITMENT_RESULTS.link + "/:id/:candidateType"} element={
                  <PrivateRoute  path={PagesEnums.RECRUITMENT_RESULTS.link} roles={[KANDYDAT]}>
                    <RecruitmentResults />
                  </PrivateRoute>}
                />
                <Route path={PagesEnums.STUDENT_ZONE.link} element={
                  <PrivateRoute roles={[KANDYDAT]}>
                    <StudentZone />
                  </PrivateRoute>}
                />
                <Route path={PagesEnums.ACTIVATION_STUDENT_ACCOUNT.link} element={
                  <PrivateRoute  roles={[KANDYDAT]}>
                    <Activation />
                  </PrivateRoute>}
                />

                <Route path={PagesEnums.REGISTER.link} element={
                  <CustomRoute>
                    <Registration />
                  </CustomRoute>}
                />
                {/* <Route path={PagesEnums.REGISTER.link} element={<Registration />} /> */}


                <Route path={PagesEnums.RESTORE_PASSWORD.link} element={
                  <CustomRoute>
                    <PassRestoraion />
                  </CustomRoute>}
                />
                {/* <Route path={PagesEnums.RESTORE_PASSWORD.link} element={<PassRestoraion />} /> */}
                <Route path={PagesEnums.HELP.link} element={<Help />} />

                <Route path={PagesEnums.ACTIVATION.link} element={
                  <CustomRoute>
                    <ActivationLink />
                  </CustomRoute>}
                />
                {/* <Route path={PagesEnums.ACTIVATION.link} element={<ActivationLink />} /> */}
                <Route path={PagesEnums.SETPASS.link} element={
                  <CustomRoute>
                    <SetPassword />
                  </CustomRoute>}
                />
                {/* <Route path={PagesEnums.SETPASS.link} element={<SetPassword />} /> */}

                <Route path={PagesEnums.PASS_RESTORE_LINK.link} element={
                  <CustomRoute>
                    <PassRestorationLink />
                  </CustomRoute>}
                />
                {/* <Route path={PagesEnums.PASS_RESTORE_LINK.link} element={<PassRestorationLink />} /> */}
                <Route path={PagesEnums.ACTIVATION_STUDENT_ACCOUNT.link} element={<PassRestorationLink />} />

                <Route path={PagesEnums.ACCESS_DENIED.link} element={<AccessDenied />} />
                <Route path={PagesEnums.SERVICE_DISABLED.link} element={<ServiceDisabled />} />
                <Route component={PageNotFound} />
              </Routes>
            </div>
          </ErrorBoundary>
          <Footer className='Footer' />
        </div>
      </GlobalDatesContext>
    </Fragment>
  );
}

export default App;
