export function getDate(pesel){
    var year=parseInt(pesel.substring(0,2),10);
    var month = parseInt(pesel.substring(2,4),10)-1;
    var day = parseInt(pesel.substring(4,6),10);

    if(month>80) {
     year += 1800;
     month -= 80;
    }
    else if(month >= 60) {
     year += 2200;
     month -= 60;
    }
    else if (month >= 40) {
     year += 2100;
     month -= 40;
    }
    else if (month >= 20) {
     year += 2000;
     month -= 20;
    }
    else
    {
     year += 1900;
    }
    
    return new Date(Date.UTC(year, month , day));
}

export function getParsedDate(strDate){
    var strSplitDate = String(strDate).split(' ');
    var date = new Date(strSplitDate[0]);
    var dd = date.getDate();
    var mm = date.getMonth() + 1; 

    var yyyy = date.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    date =  yyyy + "-" + mm + "-" + dd;
    return date.toString();
}

export function getSex(pesel){
    if(parseInt(pesel.substring(9,10),10) % 2 === 1) { 
        return '0';
    } else {
        return '1';
    }
}

export function checkSum(pesel){
    var wagi = [9,7,3,1,9,7,3,1,9,7];
    var sum = 0;
     
    for(var i=0;i<wagi.length;i++) {
        sum+=(parseInt(pesel.substring(i,i+1),10) * wagi[i]);
    }
    sum %= 10;
    return (sum===parseInt(pesel.substring(10,11),10));
}