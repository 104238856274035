import FetchService from '../services/FetchService';

export default function downloadFile(file){

   async function fetchDownloadFile(fileId) {
      return await FetchService.downloadFile(fileId);
   }

   if (file.data) {
      const linkSource = `${file.dataType},${file.data}`;
      const element = document.createElement('a');
      document.body.appendChild(element);
      element.href = linkSource;
      element.target = '_self';
      element.download = file.attachmentMetadataList[0]?.fileName
      element.click();
   } else {
      fetchDownloadFile(file.attachmentMetadataList[0]?.fileId).then(response => {
         //TODO ustawwić typ pobieranego pliku na ten z request - błąd pobierania na firefox, gdy np. dodano plik jpg 
         const linkSource = `data:application/pdf;base64,${response.zawartosc?.data}`;
         const element = document.createElement('a');
         document.body.appendChild(element);
         element.href = linkSource;
         element.target = '_self';
         element.download = response.zawartosc?.fileName;
         element.click();
      })
   }
};