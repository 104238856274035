import { makeStyles } from '@material-ui/styles';

export const useStepperStyles = makeStyles(() => ({
  step: {
    '& $completed': {
      color: '#800000',
    },
    '& $active': {
      color: '#800000',
    },
  },
  // potrzebne, żeby poprawnie się wyświetlały style ze `step`
  active: {},
  completed: {},
}));
