import { isEmpty } from 'lodash';

import { errorNotification } from '../../../../utils/Notifications';

// kody błędów pochodzą z biblioteki `react-dropzone`
const errorCodes = {
  fileInvalidType: 'file-invalid-type',
  fileTooLarge: 'file-too-large',
  tooManyFiles: 'too-many-files',
};

export const handleErrorCodes = fileWithErrors => {
  if (isEmpty(fileWithErrors)) {
    return;
  }

  const errors = [];

  fileWithErrors.forEach(file => file.errors.forEach(e => errors.push(e.code)));

  // w razie znalezienia kodu błędu o zbyt dużej ilości plików wyświetlana jest tylko ta jedna notyfikacja
  if (errors.includes(errorCodes.tooManyFiles)) {
    errorNotification('cropPhoto.uploadPhotoNotification.errorTitle', 'cropPhoto.uploadPhotoNotification.tooManyFiles');
    return;
  }

  errors.forEach(errorCode => {
    switch (errorCode) {
      case errorCodes.fileInvalidType:
        errorNotification('cropPhoto.uploadPhotoNotification.errorTitle', 'cropPhoto.uploadPhotoNotification.fileInvalidType');
        break;
      case errorCodes.fileTooLarge:
        errorNotification('cropPhoto.uploadPhotoNotification.errorTitle', 'cropPhoto.uploadPhotoNotification.fileTooLarge');
        break;
      default:
        break;
    }
  });
};
