import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stepper, Step, StepLabel, Grid, Paper, useMediaQuery, Button } from '@material-ui/core';
import PhotoManagementContext from './PhotoManagementContext';

import { ComponentSwitcher } from './components';
import { photoManagementSteps } from './PhotoManagementSteps';

import { useStepperStyles } from './style';
import { useTheme } from '../../css/Styles';

import { PagesEnums } from '../../utils/CustomEnums';
import { NavLink } from 'react-router-dom';

const PhotoManagement = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [photoFile, setPhotoFile] = useState();
  const [photoBaseCropped, setPhotoBaseCropped] = useState();
  const classes = useStepperStyles();
  const theme = useTheme;
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))

  const handleNextStep = () => setActiveStep(prevActiveStep => prevActiveStep + 1);

  const handlePreviousStep = () => setActiveStep(prevActiveStep => prevActiveStep - 1);

  const handleResetActiveStep = () => {
    setActiveStep(0);
    setPhotoFile(null);
    setPhotoBaseCropped(null);
  };

  return (
    <PhotoManagementContext.Provider
      value={{
        activeStep,
        photoFile,
        setPhotoFile,
        photoBaseCropped,
        setPhotoBaseCropped,
        handleNextStep,
        handlePreviousStep,
        handleResetActiveStep,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant='outlined'>
            <Stepper activeStep={activeStep} alternativeLabel>
              {photoManagementSteps.map(step => (
                <Step
                  key={step.key}
                  classes={{
                    root: classes.step,
                    completed: classes.completed,
                  }}
                >
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                        active: classes.active,
                        disabled: classes.disabled,
                      },
                    }}
                  >
                    {t(step.translationKey)}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant='outlined'>
            <Box p={2}>
              <ComponentSwitcher />
            </Box>
            <Grid container style={{width: '100%'}} justifyContent={isMobile ? 'center' : "flex-end"}>
                     <Button 
                        sx={{fontWeight: 'bold', 
                        margin: 1,
                        color: theme.palette.primary.main, 
                        backgroundColor: theme.palette.secondary.main, 
                        '&:hover': 
                           {backgroundColor: theme.palette.secondary.hovered, 
                           borderColor: 'none', boxShadow: 'none',},}
                        } 
                        component={NavLink}
                        to={PagesEnums.MAIN.link}
                        variant='contained'>
                        {t('backButton')}
                     </Button>
                  </Grid>
          </Paper>
        </Grid>
      </Grid>
    </PhotoManagementContext.Provider>
  );
};

export default PhotoManagement;
