import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import Slide from '@mui/material/Slide';
import FetchService from './recruitmentResultsFetchService';
import { Document, Page, pdfjs } from "react-pdf";
import {decode} from 'base-64'
import i18next from "i18next";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PrintApplicationModal({openModal, closeHandle, idCourse, recruitmentModeKey}) {

    const { t } = useTranslation();
    const [open, setOpen] = useState(openModal);
    const [application, setApplication] = useState();
    const [blobApplication, setBlobApplication] = useState();
    const [pageNum, setPageNum] = useState(1);
    const [pagesNumber, setPagesNumber] = useState(1);
    

    async function fetchDownloadApplication(courseId, recruitmentModeKey, lang) {
      return await FetchService.downloadApplication(courseId, recruitmentModeKey, lang);
   }

   function dataURItoBlob(dataURI) {
      const byteString = decode(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: 'application/pdf'});
      return blob;
    }



    const getPagesNum = (linkSource) => {
      let pdfDocument = pdfjs.getDocument(linkSource).promise;
        pdfDocument.then((elem)=>{
          setPagesNumber(elem.numPages)
        });
    }

    useEffect(
        ()=>{
            fetchDownloadApplication(idCourse, recruitmentModeKey, i18next.language.toUpperCase()).then(response => {
              const linkSource = `data:application/pdf;base64,${response.zawartosc?.data}`;
              const byteString = dataURItoBlob(response.zawartosc?.data);
              const url = URL.createObjectURL(byteString);
              setApplication(linkSource);
              setBlobApplication(url);

              // pdfjs.getDocument(linkSource).then((pdf) => {
              //   setPagesNumber(pdf.numPages);
              // })
              
              getPagesNum(linkSource);




           }).then( () =>{
            setOpen(openModal)
           })
        }
    , [])

    const printIframe = (id) => {
      const iframe = document.getElementById(id);
          iframe.focus();
          iframe.contentWindow.print();
    };

    const onPrevPage = () => {
      if (pageNum <= 1) {
        return;
      }
      setPageNum(pageNum-1);
    }

    const onNextPage = () => {
      if (pageNum >= pagesNumber) {
        return;
      }
      setPageNum(pageNum+1);
    }

  return (
    <div> 
      <Dialog
        fullScreen
        open={open}
        onClose={closeHandle}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeHandle}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t('recruitment.application')}
            </Typography>
            <Button variant="contained"  autoFocus color="inherit" sx={{mr: 1}}>
              <a href={application} target="_blank" className="download-link" download>
                {t('recruitment.download')}
              </a>
            </Button>

            <iframe
              id={idCourse} 
              src={blobApplication}
              style={{ display: 'none' }}
              title="Podanie"
            />
            <Button variant="contained" autoFocus color="inherit" onClick={() => printIframe(idCourse)}>
              {t('recruitment.print')}
            </Button>   

          </Toolbar>
        </AppBar>
        <div align="center">
          <Document 
          file={application}
          noData="Generuję podanie..."
          loading="Generuję podanie..."
          >
            <Page pageNumber={pageNum} />
          </Document>
          {
              pagesNumber>1
              ?
                <>
                  <button id="prev" onClick={()=> onPrevPage()}>Poprzednia</button>
                  <button id="next" onClick={()=> onNextPage()}>Następna</button>
                </> 
              : 
                null
          }
        </div>
      </Dialog>
    </div>
  );

}