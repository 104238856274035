import {PersonalDataAdressesEnums} from "../utils/CustomEnums";
import {getCredentials, getIdWithoutPrefix} from '../services/UserDataService';
import {stringToBoolean} from './AppUtils'


export const getAddressPersonalFormDataFromRequest = (request, dictionaries) => {
    let adresses = []

    if (request.permanentAddress) {
        let tempPermanentTown = dictionaries.miasta.find(miejscowosc => miejscowosc.id === request.permanentAddress.town)

        let permanentAddress = {
            typ: PersonalDataAdressesEnums.STALY,
            kraj: dictionaries.kraje.find(kraj => kraj.id === request.permanentAddress.country),
            wojewodztwo: dictionaries.wojewodztwa.find(wojewodztwo => wojewodztwo.id === request.permanentAddress.voivodeship),
            typAdministracyjny: request.permanentAddress.cityVillage,
            email: request.permanentAddress.email,
            telefon: request.permanentAddress.phoneNumber,
            ulica: request.permanentAddress.street,
            numerDomu: request.permanentAddress.houseNumber,
            numerMieszkania: request.permanentAddress.apartmentNumber,
            kodPocztowy: request.permanentAddress.postCode,
            miejscowosc: tempPermanentTown ? tempPermanentTown : request.permanentAddress.town
        }
        adresses.push(permanentAddress)
    }

    if (request.correspondenceAddress) {
        let tempCorrespondenceTown = dictionaries.miasta.find(miejscowosc => miejscowosc.id === request.correspondenceAddress.town)

        let correspondenceAddress = {
            typ: PersonalDataAdressesEnums.KORESPONDENCYJNY,
            kraj: dictionaries.kraje.find(kraj => kraj.id === request.correspondenceAddress.country),
            wojewodztwo: dictionaries.wojewodztwa.find(wojewodztwo => wojewodztwo.id === request.correspondenceAddress.voivodeship),
            ulica: request.correspondenceAddress.street,
            numerDomu: request.correspondenceAddress.houseNumber,
            numerMieszkania: request.correspondenceAddress.apartmentNumber,
            kodPocztowy: request.correspondenceAddress.postCode,
            miejscowosc: tempCorrespondenceTown ? tempCorrespondenceTown : request.correspondenceAddress.town
        }
        adresses.push(correspondenceAddress)
    }

    let tempPlaceOfBirth = dictionaries.miasta.find(miejscowosc => miejscowosc.id === request.userPersonalData.placeOfBirth)
    
    return {
        czId: request.czId,
        imie: request.userPersonalData.firstName,
        imie2: request.userPersonalData.middleName,
        nazwisko: request.userPersonalData.surname,
        narodowosc: dictionaries.narodowosci.find(narodowosc => narodowosc.id === request.userPersonalData.nationality),
        obywatelstwo: dictionaries.obywatelstwa.find(obywatelstwo => obywatelstwo.id === request.userPersonalData.citizenship),
        obywatelstwo2: dictionaries.obywatelstwa.find(obywatelstwo => obywatelstwo.id === request.userPersonalData.secondCitizenship),
        dataUrodzenia: request.userPersonalData.dateOfBirth,
        miejsceUrodzenia: tempPlaceOfBirth ? tempPlaceOfBirth : request.userPersonalData.placeOfBirth,
        krajUrodzenia: dictionaries.kraje.find(kraj => kraj.id === request.userPersonalData.countryOfBirth),
        imieOjca: request.userPersonalData.fathersName,
        imieMatki: request.userPersonalData.mothersName,
        nazwiskoRodowe: request.userPersonalData.maidenName,
        pesel: request.userPersonalData.pesel,
        czyPesel: (request.userPersonalData.havePesel !== null && request.userPersonalData.havePesel !== undefined) ? !request.userPersonalData.havePesel : null,
        czyPierwszaOsobaStudiujaca: request.userPersonalData.firstPerson,
        plec: dictionaries.plcie.find(plec => plec.id === request.userPersonalData.sex),
        seriaNumerPaszportu: request.userPersonalData.passportNumber,
        krajWydaniaPaszportu: dictionaries.kraje.find(kraj => kraj.id === request.userPersonalData.passportCountryOfIssue),
        cudzoziemiecRodzaj: request.userPersonalData.selectionId,
        czyAdresKorespondencyjny: (request.permanentAddress && request.permanentAddress.sameCorrespondenceAddressAsPermanent !== undefined && request.permanentAddress.sameCorrespondenceAddressAsPermanent !== null) ? !request.permanentAddress.sameCorrespondenceAddressAsPermanent : null,
        adresy: adresses,
        czyStopienNiepelnosprawnosci: (request.disabled !== undefined && request.disabled !== null) ? stringToBoolean(request.disabled) : null,
        czySpecjalnePotrzeby: (request.disabledWithoutCertificate !== undefined && request.disabledWithoutCertificate !== null) ? stringToBoolean(request.disabledWithoutCertificate) : null
    }
}


export const createSaveAddressPersonalRequest = (inputPersonalData) => {

    let fields = {...inputPersonalData};
    Object.entries(fields).map(([key, value]) => {
        if (typeof value === 'string' && value.trim().length === 0) {
            fields[key] = null;
        }
    })

    inputPersonalData = fields;
    

    let userPersonalData = {
        "firstName": inputPersonalData.imie,
        "middleName": inputPersonalData.imie2,
        "surname": inputPersonalData.nazwisko,
        "nationality": inputPersonalData.narodowosc ? inputPersonalData.narodowosc.id : null,
        "citizenship": inputPersonalData.obywatelstwo ? inputPersonalData.obywatelstwo.id : null,
        "secondCitizenship": inputPersonalData.obywatelstwo2 ? inputPersonalData.obywatelstwo2.id : null,
        "dateOfBirth": inputPersonalData.dataUrodzenia,
        "placeOfBirth": (inputPersonalData.miejsceUrodzenia && inputPersonalData.miejsceUrodzenia.id) ? inputPersonalData.miejsceUrodzenia.id : (inputPersonalData.miejsceUrodzenia ? inputPersonalData.miejsceUrodzenia : null),
        "countryOfBirth": inputPersonalData.krajUrodzenia ? inputPersonalData.krajUrodzenia.id : null,
        "fathersName": inputPersonalData.imieOjca,
        "mothersName": inputPersonalData.imieMatki,
        "maidenName": inputPersonalData.nazwiskoRodowe,
        "pesel": inputPersonalData.pesel,
        "havePesel": !inputPersonalData.czyPesel,
        "firstPerson": inputPersonalData.czyPierwszaOsobaStudiujaca,
        "sex": inputPersonalData.plec ? inputPersonalData.plec.id : null,
        "passportNumber": inputPersonalData.seriaNumerPaszportu,
        "passportCountryOfIssue": inputPersonalData.krajWydaniaPaszportu ? inputPersonalData.krajWydaniaPaszportu.id : null,
        "selectionId": inputPersonalData.cudzoziemiecRodzaj,
    }

    let permanentAddressForm = inputPersonalData.adresy.find(adres => adres.typ === PersonalDataAdressesEnums.STALY)
    let correspondenceAddressForm = inputPersonalData.adresy.find(adres => adres.typ === PersonalDataAdressesEnums.KORESPONDENCYJNY)

    let permanentAddress = null
    let correspondenceAddress = null

    if (permanentAddressForm) {
        permanentAddress = {
            "country": permanentAddressForm.kraj ? permanentAddressForm.kraj.id : null,
            "voivodeship": permanentAddressForm.wojewodztwo ? permanentAddressForm.wojewodztwo.id : null,
            "cityVillage": permanentAddressForm.typAdministracyjny,
            "email": permanentAddressForm.email,
            "phoneNumber": permanentAddressForm.telefon,
            "street": permanentAddressForm.ulica,
            "houseNumber": permanentAddressForm.numerDomu,
            "apartmentNumber": permanentAddressForm.numerMieszkania,
            "postCode": permanentAddressForm.kodPocztowy,
            "town": (permanentAddressForm.miejscowosc && permanentAddressForm.miejscowosc.id) ? permanentAddressForm.miejscowosc.id : (permanentAddressForm.miejscowosc ? permanentAddressForm.miejscowosc : null),
            "sameCorrespondenceAddressAsPermanent": !inputPersonalData.czyAdresKorespondencyjny
        }
    }

    if (correspondenceAddressForm) {
        correspondenceAddress = {
            "country": correspondenceAddressForm.kraj ? correspondenceAddressForm.kraj.id : null,
            "voivodeship": correspondenceAddressForm.wojewodztwo ? correspondenceAddressForm.wojewodztwo.id : null,
            "street": correspondenceAddressForm.ulica,
            "houseNumber": correspondenceAddressForm.numerDomu,
            "apartmentNumber": correspondenceAddressForm.numerMieszkania,
            "postCode": correspondenceAddressForm.kodPocztowy,
            "town": (correspondenceAddressForm.miejscowosc && correspondenceAddressForm.miejscowosc.id) ? correspondenceAddressForm.miejscowosc.id : (correspondenceAddressForm.miejscowosc ? correspondenceAddressForm.miejscowosc : null),

        }
    }

    let profileId = getIdWithoutPrefix()

    return JSON.stringify({
        "profileId": profileId,
        "userPersonalData": userPersonalData,
        "permanentAddress": permanentAddress,
        "correspondenceAddress": correspondenceAddress,
        "disabled": (inputPersonalData.czyStopienNiepelnosprawnosci !== undefined && inputPersonalData.czyStopienNiepelnosprawnosci !== null) ? inputPersonalData.czyStopienNiepelnosprawnosci.toString().toUpperCase() : null,
        "disabledWithoutCertificate": (inputPersonalData.czySpecjalnePotrzeby !== undefined && inputPersonalData.czySpecjalnePotrzeby !== null) ? inputPersonalData.czySpecjalnePotrzeby.toString().toUpperCase() : null,
        "czId": inputPersonalData.czId
    })
}


// Szkoła 2 stopień
export const getSchoolSecondDegreeFormDataFromRequest = (request, dictionaries, gradeScaleValuesSetter) => {
    let tempCountry = dictionaries.countries.find(country => country.id === request.country)
    let tempCity = dictionaries.cities.find(cities => cities.id === request.city)
    let tempGradeScale = dictionaries.gradeScales.find(gradeScale => gradeScale.id === request.gradeScale)
    let tempGraduationResult

    switch (tempGradeScale?.id) {
        case "5_levels": {
            tempGraduationResult = dictionaries.gradeScales5Levels.find(gradeScale => gradeScale.id === request.universityDiplomaGrade)
            gradeScaleValuesSetter(dictionaries.gradeScales5Levels);
            break;
        }
        case "6_levels": {
            tempGraduationResult = dictionaries.gradeScales6Levels.find(gradeScale => gradeScale.id === request.universityDiplomaGrade)
            gradeScaleValuesSetter(dictionaries.gradeScales6Levels);
            break;
        }
        default:
            tempGraduationResult = request.universityDiplomaGrade
            gradeScaleValuesSetter([]);
    }
    return {
        schoolName: request.schoolName,
        country: tempCountry ? tempCountry : request.country,
        city: tempCity ? tempCity : request.city,
        graduationDate: request.graduationDate ? request.graduationDate : null,
        professionalTitle: dictionaries.professionalTitles.find(professionalTitle => professionalTitle.id === request.professionalTitle),
        graduatedCourse: request.fieldOfStudy,
        diplomaType: request.certificate || false,
        diplomaOwnership: request.certificateOwnership || false,
        diplomaNumber: request.certificateNumber,
        diplomaIssueDate: request.certificationDate ? request.certificationDate : null,
        diplomaIssuePlace: request.universityDiplomaPlace,
        gradeScale: tempGradeScale,
        graduationResult: tempGraduationResult,
        languageCertificate: stringToBoolean(request.languageCertificate),
        skype: request.skype,
        languageExam: stringToBoolean(request.languageExam),
        languageExamPl: stringToBoolean(request.languageExamPl),
        languageExamEn: stringToBoolean(request.languageExamEn),
        languageExamEmail: getCredentials(),
        matriculationCertificate: (request.alCzySwiadPozaPol !== undefined && request.alCzySwiadPozaPol !== null) ? stringToBoolean(request.alCzySwiadPozaPol) : null,
        contest: stringToBoolean(request.contest)
    }
}

// Szkoła 3 stopień
export const getSchoolThirdDegreeFormDataFromRequest = (request, dictionaries) => {
    let tempCountry = dictionaries.countries.find(country => country.id === request.country)
    let tempCity = dictionaries.cities.find(cities => cities.id === request.city)
    let tempProfessionalTitle = dictionaries.professionalTitles.find(professionalTitle => professionalTitle.id === request.professionalTitle)
    let tempAcceptanceType = dictionaries.acceptanceTypes.find(acceptanceType => acceptanceType.id === request.acceptance)

    return {
        certificate: request.certificate ? request.certificate : 'DR',
        country: tempCountry ? tempCountry : request.country,
        city: tempCity ? tempCity : request.city,
        schoolName: request.schoolName,
        fieldOfStudy: request.fieldOfStudy,
        graduationDate: request.graduationDate,
        professionalTitle: tempProfessionalTitle ? tempProfessionalTitle : request.professionalTitle,
        schoolDiplomaTitle: request.schoolDiplomaTitle,
        mastersThesisSupervisor: request.mastersThesisSupervisor,
        alCzySwiadPozaPol: stringToBoolean(request.alCzySwiadPozaPol),
        szBeneficjentGrant: stringToBoolean(request.szBeneficjentGrant),
        acceptance: tempAcceptanceType?.value
    }
}

export const createSaveSchoolSecondDegreeRequest = (schoolData) => {
    return {
        "schoolDictionaryId": null,
        "schoolName": schoolData.schoolName,
        "country": (schoolData.country && schoolData.country.id) ? schoolData.country.id : (schoolData.country ? schoolData.country : null),
        "city": (schoolData.city && schoolData.city.id) ? schoolData.city.id : (schoolData.city ? schoolData.city : null),
        "graduationDate": schoolData.graduationDate,
        "professionalTitle": schoolData.professionalTitle?.id,
        "fieldOfStudy": schoolData.graduatedCourse,
        "certificate": "NEW",
        "certificateOwnership": schoolData.diplomaOwnership,
        "certificateNumber": schoolData.diplomaNumber,
        "certificationDate": schoolData.diplomaIssueDate,
        "universityDiplomaPlace": schoolData.diplomaIssuePlace,
        "gradeScale": schoolData.gradeScale?.id,
        "universityDiplomaGrade": (schoolData.graduationResult && schoolData.graduationResult.id) ? schoolData.graduationResult.id : (schoolData.graduationResult ? schoolData.graduationResult : null),
        "alCzySwiadPozaPol": (schoolData.matriculationCertificate !== undefined && schoolData.matriculationCertificate !== null) ? schoolData.matriculationCertificate ? "TRUE" : "FALSE" : null,
        "languageCertificate": schoolData.languageCertificate,
        "contest": stringToBoolean(schoolData.contest),
        "languageExam": schoolData.languageExam,
        "languageExamPl": schoolData.languageExamPl,
        "languageExamEn": schoolData.languageExamEn,
        "skype": schoolData.skype,
        "languageExamEmail": schoolData.languageExamEmail
    }
}

export const createSaveSchoolThirdDegreeRequest = (schoolData, acceptanceTypes) => {
    let tempAcceptanceType = acceptanceTypes.find(type => type.value === schoolData.acceptance)

    return {
        "certificate": schoolData.certificate,
        "country": (schoolData.country && schoolData.country.id) ? schoolData.country.id : (schoolData.country ? schoolData.country : null),
        "city": (schoolData.city && schoolData.city.id) ? schoolData.city.id : (schoolData.city ? schoolData.city : null),
        "schoolName": schoolData.schoolName,
        "fieldOfStudy": schoolData.fieldOfStudy,
        "graduationDate": schoolData.graduationDate,
        "professionalTitle": schoolData.professionalTitle.id,
        "schoolDiplomaTitle": schoolData.schoolDiplomaTitle,
        "mastersThesisSupervisor": schoolData.mastersThesisSupervisor,
        "alCzySwiadPozaPol": (schoolData.alCzySwiadPozaPol !== undefined && schoolData.alCzySwiadPozaPol !== null) ? schoolData.alCzySwiadPozaPol ? "TRUE" : "FALSE" : null,
        "szBeneficjentGrant": (schoolData.szBeneficjentGrant !== undefined && schoolData.szBeneficjentGrant !== null) ? schoolData.szBeneficjentGrant ? "TRUE" : "FALSE" : null,
        "acceptance": tempAcceptanceType?.id
    }
}

