import * as React from "react";
import { Typography } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import 'react-phone-input-2/lib/style.css'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import './style.css';



const Desktop = ({messagesData, setMessageAsRead, setMessageAsUnread}) => {
    const [messageDetails, setMessageDetails] = React.useState([]);
    const [activeLi, setActiveLi] = React.useState(0);

    return(
        <Grid sx={{ padding: '3vh'}} container item spacing={4} xs={12} lg={10} style={{ whiteSpace: 'pre-line' }}>
            <Grid item xs={4}>
                <List 
                className="list"
                subheader={<li />}
                >
                    {
                        
                        messagesData.map((value)=>{
                            return(
                                <ListItem 
                                key={value.id}
                                onClick={() => {
                                    setMessageDetails(value);
                                    setActiveLi(value.id);
                                    setMessageAsRead(value.id);
                                }}
                                className={activeLi===value.id?'active list-elem':'list-elem'}
                                >
                                    <ListItemText 
                                        primary={value.title} 
                                        secondary={value.sendDate}
                                        className={value.isRead? 'li-text':'li-text unread'}
                                    />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Grid>
            <Grid item xs={8}  align="left">
                {messageDetails.length !== 0 && 
                    <Chip icon={<MarkAsUnreadIcon/>}
                        sx={{ marginTop: 2, }}
                        label="Oznacz jako nieprzeczytaną" 
                        variant="outlined" 
                        onClick={() => {
                            setMessageDetails([]);
                            setActiveLi(0);
                            setMessageAsUnread(messageDetails.id);
                        }}/>
                }
                <Typography sx={{ marginTop: 5, }} align="left" variant="caption" display="block" gutterBottom>
                    {messageDetails.sendDate}
                </Typography>
                <Typography sx={{ marginBottom: 4, }} align="left" variant="h5" gutterBottom>
                    {messageDetails.title}
                </Typography>
                <Typography align="left" variant="body1" gutterBottom>
                    {messageDetails.description}  
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Desktop;