import {ACTIONS} from "../Consts";

export function filesReducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_INITIAL_FILES: {
            let tempState = action.payload.map(payload => {
                let data = {...payload}
                data.inputRefs = action.inputRefs;
                return data;
            })

            return state = tempState;
        }
        case ACTIONS.SET_FILE_DATA: {
            let changedFile = state
                .filter(file => file.attachmentInfo.typeId === action.payload.typeId)
                .map(file => {
                    file.attachmentMetadataList = [].concat({fileName: action.payload.name, size: action.payload.size});
                    file.data = action.payload.data
                    file.dataType = action.payload.dataType
                    return file;
                })

            let notChangedFile = state.filter(file => file.attachmentInfo.typeId !== action.payload.typeId)


            // return state = notChangedFile.concat(changedFile).sort((a, b) => a.attachmentInfo.typeId - b.attachmentInfo.typeId);
            return state = notChangedFile.concat(changedFile)
        }
        default:
            throw new Error();
    }
}