import { DictionaryTypeEnums } from '../utils/CustomEnums';
import FetchService from '../services/FetchService';
import { get as getFromStorage, save as setStorage } from '../services/StorageService';
import { DICTIONARY_STORAGE_PREFIX, DICTIONARY_STORAGE_TYPE } from '../utils/Consts';
import logLevel from '../utils/LoggerHelper';
import { createNotificationForResponse } from '../utils/Notifications';
import { artisticCourses } from '../utils/Consts';
import { SessionTypesEnums } from '../utils/CustomEnums';
import { get as GetFromStorage, save as SaveToStorage } from '../services/StorageService';
import CryptoJS from 'crypto-js';
import { getIdWithoutPrefix } from '../services/UserDataService';

export const stringToBoolean = (stringBooleanValue) => {
  if (stringBooleanValue === undefined || stringBooleanValue === null) {
    return false;
  }

  switch (stringBooleanValue.toString().toLowerCase().trim()) {
    case "true":
    case "yes":
    case "1":
      return true;

    case "false":
    case "no":
    case "0":
    case null:
      return false;

    default:
      return Boolean(stringBooleanValue);
  }
}

async function fetchAddressPersonalDictionary() {
  return await FetchService.getAddressPersonalDictionary();
}

async function fetchSchoolDictionary() {
  return await FetchService.getSchoolDictionary();
}

async function fetchSchoolSecondDegreeDictionary() {
  return await FetchService.getSchoolSecondDegreeDictionary();
}

async function fetchSchoolThirdDegreeDictionary() {
  return await FetchService.getSchoolThirdDegreeDictionary();
}

async function fetchCheckDictExistsInCache(type, key) {
  return await FetchService.checkDictionaryKeyExistsInCache(type, key);
}

async function fetchKREMSchoolNames() {
  return await FetchService.getKREMSchoolNamesDictionary();
}

async function checkIfPhotoExists() {
  return await FetchService.checkIfPhotoExists()
};

async function fetchPersonalData() {
  return await FetchService.getAddressPersonal()
};

async function fetchChosenRecruitmentModes() {
  return await FetchService.getChosenRecruitmentModes()
};

async function fetchSchoolData(modeKey) {
  return await FetchService.getSchoolData(modeKey)
}

async function fetchAndStoreDictByType(dictionaryEnum) {
  switch (dictionaryEnum) {
    case DictionaryTypeEnums.ADDRESS_PERSONAL:
      return await fetchAddressPersonalDictionary().then(response => {
        logLevel.debug("Pobieranie słowników dla danych personalnych")
        if (response && response.zawartosc) {
          setStorage(DICTIONARY_STORAGE_PREFIX + response.zawartosc.cacheKeyModel.type, JSON.stringify({ uuid: response.zawartosc.cacheKeyModel.uuid, value: response.zawartosc.listOfValues }), DICTIONARY_STORAGE_TYPE)
          return { uuid: response.zawartosc.cacheKeyModel.uuid, value: response.zawartosc.listOfValues };
        } else {
          createNotificationForResponse(response);
        }
      })
    case DictionaryTypeEnums.SCHOOL:
      return await fetchSchoolDictionary().then(response => {
        logLevel.debug("Pobieranie słowników dla szkoły")
        if (response && response.zawartosc) {
          setStorage(DICTIONARY_STORAGE_PREFIX + response.zawartosc.cacheKeyModel.type, JSON.stringify({ uuid: response.zawartosc.cacheKeyModel.uuid, value: response.zawartosc.listOfValues }), DICTIONARY_STORAGE_TYPE)
          return { uuid: response.zawartosc.cacheKeyModel.uuid, value: response.zawartosc.listOfValues };
        } else {
          createNotificationForResponse(response);
        }
      })
    case DictionaryTypeEnums.SCHOOL_NAME:
      return await fetchKREMSchoolNames().then(response => {
        logLevel.debug("Pobieranie słownika szkół KREM")
        if (response && response.zawartosc) {
          setStorage(DICTIONARY_STORAGE_PREFIX + response.zawartosc.cacheKeyModel.type, JSON.stringify({ uuid: response.zawartosc.cacheKeyModel.uuid, value: response.zawartosc.listOfValues }), DICTIONARY_STORAGE_TYPE)
          return { uuid: response.zawartosc.cacheKeyModel.uuid, value: response.zawartosc.listOfValues };
        } else {
          createNotificationForResponse(response);
        }
      })
    case DictionaryTypeEnums.SCHOOL_SECOND_DEGREE:
      return await fetchSchoolSecondDegreeDictionary().then(response => {
        logLevel.debug("Pobieranie słownika dla szkoły 2 stopnia")
        if (response && response.zawartosc) {
          setStorage(DICTIONARY_STORAGE_PREFIX + response.zawartosc.cacheKeyModel.type, JSON.stringify({ uuid: response.zawartosc.cacheKeyModel.uuid, value: response.zawartosc.listOfValues }), DICTIONARY_STORAGE_TYPE)
          return { uuid: response.zawartosc.cacheKeyModel.uuid, value: response.zawartosc.listOfValues };
        } else {
          createNotificationForResponse(response);
        }
      })
    case DictionaryTypeEnums.SCHOOL_THIRD_DEGREE:
      return await fetchSchoolThirdDegreeDictionary().then(response => {
        logLevel.debug("Pobieranie słownika dla szkoły 3 stopnia")
        if (response && response.zawartosc) {
          setStorage(DICTIONARY_STORAGE_PREFIX + response.zawartosc.cacheKeyModel.type, JSON.stringify({ uuid: response.zawartosc.cacheKeyModel.uuid, value: response.zawartosc.listOfValues }), DICTIONARY_STORAGE_TYPE)
          return { uuid: response.zawartosc.cacheKeyModel.uuid, value: response.zawartosc.listOfValues };
        } else {
          createNotificationForResponse(response);
        }
      })
    default:
      logLevel.debug("Nie dopasowano słownika do pobrania")
  }
}

export const getDictionaries = async (dictionaryTypeEnum) => {
  if (dictionaryTypeEnum !== undefined && dictionaryTypeEnum !== null && dictionaryTypeEnum.length !== 0) {
    let storageDictValue = getFromStorage(DICTIONARY_STORAGE_PREFIX + dictionaryTypeEnum, DICTIONARY_STORAGE_TYPE);
    if (!storageDictValue) {
      return await fetchAndStoreDictByType(dictionaryTypeEnum)
    } else {
      await fetchCheckDictExistsInCache(dictionaryTypeEnum, JSON.parse(storageDictValue).uuid).then(response => {
        if (response && response.zawartosc === true) {
          logLevel.debug("Pobieranie słownika ze storage.");
        } else {
          logLevel.debug("Pobieranie nowych danych do słownika.");
          return fetchAndStoreDictByType(dictionaryTypeEnum)
        }
      })
    }

    return await JSON.parse(getFromStorage(DICTIONARY_STORAGE_PREFIX + dictionaryTypeEnum, DICTIONARY_STORAGE_TYPE))
  }
}

export function checkArtisticCourse(id, dates,degree) {
  const isArtistic = artisticCourses.some(el => el === id)
    if ((new Date(dates.currentDateTime) >= new Date(dates[degree].recruitmentClosureForExam)) && isArtistic) {
      return true
    
  }
 
  return false
}

export function modifyAccessStorage(field, value = true) {
  const id = getIdWithoutPrefix()
  const result = GetFromStorage("access"+id, SessionTypesEnums.LOCAL_STORAGE)
  let decrypted = {}

  if (result) {
    decrypted = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(result, 'secret12345',
      {
        keySize: 128 / 8,
        iv: 'secret12345',
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })));

    decrypted[field] = value
  }
  decrypted[field] = value

  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(decrypted), 'secret12345',
    {
      keySize: 128 / 8,
      iv: 'secret12345',
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();

  SaveToStorage("access"+id, encrypted, SessionTypesEnums.LOCAL_STORAGE)

}

export function getAccessStorageValue() {
  const id = getIdWithoutPrefix()
  const result = GetFromStorage("access"+id, SessionTypesEnums.LOCAL_STORAGE)
  if (result) {
    return JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(result, 'secret12345',
      {
        keySize: 128 / 8,
        iv: 'secret12345',
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })));
  }
  return null
}

export function setInitialAccess() {
  const id = getIdWithoutPrefix()

  checkAccess().then(obj => {
    if (obj) {
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(obj), 'secret12345',
        {
          keySize: 128 / 8,
          iv: 'secret12345',
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }).toString();

      SaveToStorage("access"+id, encrypted, SessionTypesEnums.LOCAL_STORAGE)
    }
    window.location.reload()

  })
}

async function checkAccess() {
  let initialAccessObject = {
    photo: false,
    personalData: false,
  }

  const photo = await checkIfPhotoExists()
  if (photo.zawartosc) initialAccessObject.photo = true

  const personal = await fetchPersonalData()
  if (personal && personal.zawartosc && personal.zawartosc.userPersonalData.sex !== null) initialAccessObject.personalData = true

  const result = await checkSchool(initialAccessObject)
  return result
}

async function checkSchool(initialAccessObject) {
  const rModes = await fetchChosenRecruitmentModes()
  for await (const tryb of rModes.zawartosc) {
    const school = await fetchSchoolData(tryb.key)
    if (school && school.zawartosc && school.zawartosc.city && school.zawartosc.country) initialAccessObject[tryb.key] = true
  }

  return initialAccessObject
}