import {Grid, FormControlLabel, Checkbox, ThemeProvider, Link} from '@material-ui/core';
import {useStyles, useTheme} from '../css/Styles';

export default function CustomCheckboxField({
                                                label,
                                                value,
                                                disabledState = true,
                                                changeHandler,
                                                name,
                                                className,
                                                color,
                                                link,
                                                to,
                                                fontSize = 13
                                            }) {
    const classes = useStyles();
    const theme = useTheme;

    const linkClickedHandler = (e) => {
        e.preventDefault();
        window.open(to, '_blank', 'noopener,noreferrer');
    }

    return (<ThemeProvider theme={theme}>
            <Grid item xs className={className}>
                {link ? <FormControlLabel className={classes.daneOsoboweCheckboxField} sx={{
                        '& .MuiFormControlLabel-label': {fontSize: fontSize}, color: color ? color : 'black'
                    }} label={<p>
                        {label}
                        <Link
                            disabled={disabledState}
                            sx={{textDecoration: 'none', color: theme.palette.secondary.clicked}}
                            onClick={linkClickedHandler}>
                            .&nbsp; {link}
                        </Link>
                    </p>} name={name} control={<Checkbox color='secondary' checked={value} disabled={disabledState}
                                                         onChange={changeHandler} data-testid={name}
                                                         sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>}
                    />


                    : <FormControlLabel className={classes.daneOsoboweCheckboxField} sx={{
                        '& .MuiFormControlLabel-label': {fontSize: fontSize}, color: color ? color : 'black'
                    }} label={label} name={name}
                                        control={<Checkbox color='secondary' checked={value} disabled={disabledState}
                                                           onChange={changeHandler} data-testid={name}
                                                           sx={{'& .MuiSvgIcon-root': {fontSize: 30}}}/>}
                    />}
            </Grid>
        </ThemeProvider>)
}