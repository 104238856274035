import { styled } from '@material-ui/styles';
import { Button } from '@material-ui/core';

export const ButtonModal = styled(Button)({
  color: '#800000',
  textTransform: 'none',

  '&:hover': {
    backgroundColor: '#FFF',
    color: '#a83232',
    textTransform: 'none',
  },
});
