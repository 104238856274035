import FormControl from "@mui/material/FormControl";
import {FormHelperText} from "@mui/material";
import React from "react";
import {TextField} from "@material-ui/core";
import {useStyles} from "../../../../css/Styles";
import {useTranslation} from "react-i18next";

export default function InputGrade(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    
    return (
        <FormControl sx={{marginBottom: (props.errors && props.errors[props.name]) ? '0px' : '20px'}}
                     error={props.errors && props.errors[props.name]} fullWidth>

            <TextField
                labelId={props.name}
                id={props.name}
                name={props.name}
                disabled={props.editState}
                value={props.value}
                label={props.label}
                onChange={props.onChange}
                onBlur={props.onBlur}
                className={((props.errors && props.errors[props.name]) ? classes.customTextFieldError : classes.customTextField)}
                type="number"
                sx={{
                    marginTop: '5px'
                }}
                inputProps={{
                    // The step property allows floating point numbers
                    // By setting it to '1', we only allow integer values
                    step: '1',
                }}
            />

            <FormHelperText
                sx={{color: '#f44336'}}>{(props.errors && props.errors[props.name]) ? t(props.errors[props.name]) : props.caption}</FormHelperText>
        </FormControl>
    )
}
