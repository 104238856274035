import {Card, CardContent, FormControlLabel, Grid, ThemeProvider, Typography} from '@material-ui/core';
import {useStyles, useTheme} from '../css/Styles';
import {Radio, RadioGroup} from '@mui/material';
import {useTranslation} from 'react-i18next';
import ErrorInfoGrid from '../templates/ErrorInfoGrid';


const Link = ({link, linkName}) => {
    if (link) {
        return (
            <a href={link} target="_blanc">{linkName}</a>
        )
    }
}

export default function CustomRadioGroup({
                                             value,
                                             disabledState = true,
                                             changeHandler,
                                             name,
                                             radioData,
                                             row,
                                             errors,
                                             title
                                         }) {
    const classes = useStyles();
    const theme = useTheme;
    const {t} = useTranslation();
    return (
        <ThemeProvider theme={theme}>
            <Grid container alignItems='flex-start' className={classes.daneOsoboweGridField}
                  style={{display: 'table-cell'}}>
                <Card
                    className={(errors && errors.find(errors => errors.id === name)) ? classes.customCardFieldError : classes.customCardField}
                    variant="outlined">
                    <CardContent>
                        <Typography fontWeight='bold'
                                    variant='h3'>{(title ? title : t('pickOneBestSuitingYou'))}</Typography>
                        {(radioData && radioData.length) ?
                            <RadioGroup
                                name={name}
                                data-testid={name}
                                value={value}
                                onChange={changeHandler}
                                disabled={disabledState}
                                row={row}
                            >
                                {radioData.map((radioButtonData) => {
                                    return (
                                        <div key={radioData.indexOf(radioButtonData)}>
                                            <FormControlLabel disabled={disabledState}
                                                              key={radioData.indexOf(radioButtonData)}
                                                              sx={{marginTop: '0.5vh'}} value={radioButtonData.value}
                                                              control={<Radio color='secondary'/>}
                                                              label={t(radioButtonData.label)}/>
                                            <Link link={radioButtonData.link} linkName={radioButtonData.linkName}/>
                                        </div>
                                    )
                                })}
                            </RadioGroup> :
                            null
                        }
                    </CardContent>
                </Card>
                <ErrorInfoGrid errors={errors} fieldName={name}></ErrorInfoGrid>
            </Grid>
        </ThemeProvider>
    )
}