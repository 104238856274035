import React from 'react'
import { Grid, useMediaQuery, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css'
import { useTheme } from '../css/Styles';
import { PagesEnums } from '../utils/CustomEnums';
import { NavLink } from 'react-router-dom';
import { getCredentials } from '../services/UserDataService';
import LinearProgress from '@mui/material/LinearProgress';

function BackAndSaveButtons({ saveButtonFunction, loading, readOnly }) {
   const theme = useTheme;
   const { t } = useTranslation();
   const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))

   return (
         <>
            {loading ?
               <Grid container style={{ width: '100%' }} alignItems='center' justifyContent='center'>
                  <LinearProgress sx={{ width: '80%' }} color="secondary" />
               </Grid> :
               <Grid container style={{ width: '100%' }} justifyContent={isMobile ? 'center' : "flex-end"}>
                  <Button
                     sx={{
                        fontWeight: 'bold',
                        margin: 1,
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.secondary.main,
                        '&:hover':
                        {
                           backgroundColor: theme.palette.secondary.hovered,
                           borderColor: 'none', boxShadow: 'none',
                        },
                        '&.Mui-disabled': {
                           backgroundColor: theme.palette.additional.disabled,
                        }
                     }
                     }
                     component={NavLink}
                     to={getCredentials() ? PagesEnums.MAIN.link : PagesEnums.HOME.link}
                     variant='contained'>
                     {t('backButton')}
                  </Button>
                  <Button
                     sx={{
                        fontWeight: 'bold',
                        margin: 1,
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.secondary.main,
                        '&:hover':
                        {
                           backgroundColor: theme.palette.secondary.hovered,
                           borderColor: 'none', boxShadow: 'none',
                        },
                        '&.Mui-disabled': {
                           backgroundColor: theme.palette.additional.disabled,
                        }
                     }}
                     onClick={saveButtonFunction}
                     disabled={readOnly}
                     variant='contained'>
                     {t('saveButton')}
                  </Button>
               </Grid>}
         </>
      )
   }

   export default BackAndSaveButtons
