import { Typography, Slider, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useControlAreaStyles } from './style';

const ControlsArea = ({ zoom, setZoom, rotation, setRotation }) => {
  const { t } = useTranslation();
  const classes = useControlAreaStyles();
  return (
    <Box component='section' sx={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
      <Box component='article' sx={{ padding: 16, width: 'auto' }}>
        <Typography>{t('cropPhoto.controlsArea.zoom')}</Typography>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby='Zoom'
          classes={classes}
          onChange={(e, zoom) => setZoom(zoom)}
        />
      </Box>
      <Box component='article' sx={{ padding: 16, width: 'auto' }}>
        <Typography>{t('cropPhoto.controlsArea.rotation')}</Typography>
        <Slider
          value={rotation}
          min={0}
          max={360}
          step={1}
          aria-labelledby='Rotation'
          classes={classes}
          onChange={(e, rotation) => setRotation(rotation)}
        />
      </Box>
    </Box>
  );
};

export default ControlsArea;
