import React, {useEffect} from 'react';
import {useStyles, useTheme} from '../css/Styles'
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    List,
    ListItem,
    TextField,
    ThemeProvider,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import CustomCheckboxField from '../templates/CustomCheckboxField';
import {styled} from '@mui/material/styles';
import FetchService from '../services/FetchService'
import {NavLink, useNavigate} from 'react-router-dom';
import {getCredentials} from '../services/UserDataService';
import {PagesEnums} from '../utils/CustomEnums';
import RefreshIcon from '@mui/icons-material/Refresh';
import {createNotificationForResponse} from '../utils/Notifications';
import LinearProgress from '@mui/material/LinearProgress';
import i18n from "i18next";

const registrationData = {
    email: '',
    agreement1: false,
    agreement2: false,
    agreement3: false,
    agreement4: false,
    agreement5: false,
    agreement6: false,
    captcha: ''
}

async function FetchCaptcha() {
    return await FetchService.getCaptcha();
}

async function FetchCreateAccount(captchaAnswer, captchaId, email, language) {
    return await FetchService.createAccount(captchaAnswer, captchaId, email, language);
}


export default function Registration() {
    const theme = useTheme;
    const classes = useStyles();
    const {t} = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('phone'))
    const navigate = useNavigate();

    const [emailValidity, setEmailValidity] = React.useState(false);
    const [inputData, setInputData] = React.useState(registrationData)
    const [buttonClick, setButtonClick] = React.useState(0)
    const [captchaImage, setCaptchaImage] = React.useState()
    const [capchaId, setCaptchaId] = React.useState()
    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        FetchCaptcha().then(response => {
            setCaptchaImage(response.zawartosc.image)
            setCaptchaId(response.zawartosc.id)
        })
    }, [])

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function validateCheckboxes() {
        return (inputData.agreement1 && inputData.agreement2 && inputData.agreement3 &&
            inputData.agreement4 && inputData.agreement5 && inputData.agreement6) ? true : false;
    }

    const dataHandler = (event) => {
        let data = {...inputData}
        if (event.target.name === "email") {
            if (event.target.value !== '' && !isValidEmail(event.target.value)) {
                setEmailValidity(true)
            } else {
                setEmailValidity(false)
            }
            data.email = event.target.value
        } else if (event.target.name === "captcha") {
            data.captcha = event.target.value
        } else {
            data[event.target.name] = event.target.checked
        }
        setInputData(data)
    }

    const registerButtonHandler = (lang) => {
        setButtonClick(1)
        if (validateCheckboxes() && isValidEmail(inputData.email)) {
            setLoading(true)
            FetchCreateAccount(inputData.captcha, capchaId, inputData.email, lang).then(response => {
                if (response.wiadomoscTyp === 'success') {
                    navigate(PagesEnums.ACTIVATION.link)
                } else {
                    FetchCaptcha().then(response => {
                        setCaptchaImage(response.zawartosc.image)
                        setCaptchaId(response.zawartosc.id)
                    })
                }
                createNotificationForResponse(response)
                setLoading(false)
            })
        }
    }

    const refreshCaptchaHandler = () => {
        FetchCaptcha().then(response => {
            setCaptchaImage(response.zawartosc.image)
            setCaptchaId(response.zawartosc.id)
        })
    }


    const BootstrapButton = styled(Button)({
        // boxShadow: 'none',
        // textTransform: 'none',
        fontSize: 15,
        color: 'white',
        // border: '1px solid',
        borderColor: 'none',
        lineHeight: 1,
        minHeight: 60,
        backgroundColor: '#30376c',
        fontFamily: [
            "Arial",
            "sans-serif"
        ].join(','),
        '&:hover': {
            backgroundColor: theme.palette.secondary.hovered,
            borderColor: 'none',
            boxShadow: 'none',
        },
        // '&:active': {
        //   boxShadow: 'none',
        //   backgroundColor: '#f2f2f2',
        //   borderColor: 'none',
        // },
        // '&:focus': {
        //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    });

    return (
        <div style={{width: '100%', height: 'auto'}}>
            <ThemeProvider theme={theme}>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    padding: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <Grid container sx={{width: '80%'}} alignItems='flex-start' justifyContent='space-around'
                          direction={isMobile ? 'column' : 'row'}>
                        <Grid sx={{width: isMobile ? '100%' : '45%', marginBottom: isMobile ? '2vh' : 0}} container
                              alignItems={isMobile ? 'center' : 'flex-start'}>
                            <Grid container alignItems='center' direction='column'>
                                <Typography sx={{padding: 1}} className={classes.homeText} variant='h7'>
                                    {t("registration.registration")}
                                </Typography>
                            </Grid>
                            <TextField
                                name="email"
                                value={inputData.email}
                                sx={{width: '100%', marginBottom: 1}}
                                error={emailValidity}
                                className={classes.customTextField}
                                label={t("home.emailLabel")}
                                variant="outlined"
                                onChange={dataHandler}
                            />
                            {emailValidity ?
                                <Typography variant='h5' color={theme.palette.delete.main}>
                                    {t("registration.wrongEmail")}
                                </Typography> : null}
                            {inputData.email === '' && buttonClick !== 0 ?
                                <Typography variant='h5' color={theme.palette.delete.main}>
                                    {t("registration.insertEmail")}
                                </Typography> : null}
                            <FormControl
                                required
                                error={!validateCheckboxes() && buttonClick !== 0 ? true : false}
                                variant="standard"
                            >
                                <List size="sm" variant="outlined" sx={{
                                    width: '100%',
                                    bgcolor: theme.palette.additional.main,
                                    alignItems: 'flex-start'
                                }}>
                                    <ListItem sx={{paddingLeft: 0}}>
                                        <CustomCheckboxField name="agreement1" className={classes.registrationCheckBox}
                                                             color={buttonClick === 0 ? null : (inputData.agreement1 ? null : theme.palette.delete.main)}
                                                             value={inputData.agreement1} changeHandler={dataHandler}
                                                             label={t('registration.agreement1')} disabledState={false}
                                                             link={t('registration.rules')}
                                                             to={"https://rekrutacja.p.lodz.pl/sites/default/files/2024-05/regulamin_rekrutacji%20_2024_2025.pdf"}/>
                                    </ListItem>
                                    <ListItem sx={{paddingLeft: 0}}>
                                        <CustomCheckboxField name="agreement2" className={classes.registrationCheckBox}
                                                             color={buttonClick === 0 ? null : (inputData.agreement2 ? null : theme.palette.delete.main)}
                                                             value={inputData.agreement2} changeHandler={dataHandler}
                                                             label={t('registration.agreement2')} disabledState={false}
                                                             link={t('registration.rodo')}
                                                             to={"https://rekrutacja.p.lodz.pl/sites/default/files/2024-05/RODO_GDPR_2024.pdf"}/>
                                    </ListItem>
                                    <ListItem sx={{paddingLeft: 0}}>
                                        <CustomCheckboxField name="agreement3" className={classes.registrationCheckBox}
                                                             color={buttonClick === 0 ? null : (inputData.agreement3 ? null : theme.palette.delete.main)}
                                                             value={inputData.agreement3} changeHandler={dataHandler}
                                                             label={t('registration.agreement3')}
                                                             disabledState={false}/>
                                    </ListItem>
                                    <ListItem sx={{paddingLeft: 0}}>
                                        <CustomCheckboxField name="agreement4" className={classes.registrationCheckBox}
                                                             color={buttonClick === 0 ? null : (inputData.agreement4 ? null : theme.palette.delete.main)}
                                                             value={inputData.agreement4} changeHandler={dataHandler}
                                                             label={t('registration.agreement4')} disabledState={false}
                                                             link={t('registration.rodo')}
                                                             to={"https://rekrutacja.p.lodz.pl/sites/default/files/2024-05/RODO_GDPR_2024.pdf"}/>
                                    </ListItem>
                                    <ListItem sx={{paddingLeft: 0}}>
                                        <CustomCheckboxField name="agreement5" className={classes.registrationCheckBox}
                                                             color={buttonClick === 0 ? null : (inputData.agreement5 ? null : theme.palette.delete.main)}
                                                             value={inputData.agreement5} changeHandler={dataHandler}
                                                             label={t('registration.agreement5')}
                                                             disabledState={false}/>
                                    </ListItem>
                                    <ListItem sx={{paddingLeft: 0}}>
                                        <CustomCheckboxField name="agreement6" className={classes.registrationCheckBox}
                                                             color={buttonClick === 0 ? null : (inputData.agreement6 ? null : theme.palette.delete.main)}
                                                             value={inputData.agreement6} changeHandler={dataHandler}
                                                             label={t('registration.agreement6')} disabledState={false}
                                                             link={t('registration.rodo')}
                                                             to={"https://rekrutacja.p.lodz.pl/sites/default/files/2024-05/RODO_GDPR_2024.pdf"}/>
                                    </ListItem>
                                </List>
                                {!validateCheckboxes() && buttonClick !== 0 ?
                                    <FormHelperText>{t("registration.allAgreementsNeeded")}</FormHelperText> : null}
                            </FormControl>
                        </Grid>
                        <Grid sx={{width: isMobile ? '100%' : '45%',}} container alignItems='center'
                              justifyContent='center'>
                            <Typography className={classes.homeText} variant='h7'>
                                {t('registration.rewriteText')}
                                <IconButton color='secondary' size="large" onClick={refreshCaptchaHandler}>
                                    <RefreshIcon sx={{fontSize: 28}}/>
                                </IconButton>
                            </Typography>
                            <Grid sx={{width: '100%'}} container alignItems='flex-start' direction='column'>
                                <img style={{width: '100%'}} src={`data:image/jpeg;base64,${captchaImage}`}
                                     alt='captcha'/>
                                <TextField
                                    name="captcha"
                                    value={inputData.captcha}
                                    sx={{width: '100%', marginTop: 2}}
                                    className={classes.customTextField}
                                    label={t("registration.captchaLabel")}
                                    variant="outlined"
                                    onChange={dataHandler}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <BootstrapButton onClick={() => registerButtonHandler(i18n.language)}
                                     sx={{width: isMobile ? '100%' : '40%', marginTop: '4vh'}}
                                     variant='standard'>{t("registration.register")}</BootstrapButton>
                    {loading ?
                        <LinearProgress sx={{width: isMobile ? '100%' : '40%', marginTop: '2vh'}} color="secondary"/> :
                        <div></div>}
                    <Grid container style={{width: '80%', marginBottom: isMobile ? '2vh' : 0, marginTop: '2vh'}}
                          justifyContent={isMobile ? 'center' : "flex-end"}>
                        <Button
                            sx={{
                                width: '5%',
                                minWidth: 100,
                                color: theme.palette.primary.main,
                                backgroundColor: theme.palette.secondary.main,
                                '&:hover':
                                    {
                                        backgroundColor: theme.palette.secondary.hovered,
                                        borderColor: 'none', boxShadow: 'none',
                                    },
                            }
                            }
                            component={NavLink}
                            to={getCredentials() ? PagesEnums.MAIN.link : PagesEnums.HOME.link}
                            variant='standard'>
                            {t('backButton')}
                        </Button>
                    </Grid>
                </Box>
            </ThemeProvider>
        </div>
    );

}