export default function readFileDataAsBase64(e) {
   const file = e.target.files[0];

   return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
         resolve(event.target.result);
      };

      reader.onerror = (err) => {
         reject(err);
      };

      reader.readAsDataURL(file);
   });
}