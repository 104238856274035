import FetchService from "../../services/FetchService";

export function saveFilesToDatabase(filesInfo) {
    const request = []
    filesInfo.filter(file => file.data)
        .forEach(file => {
            request.push({
                'data': file.data,
                'metadata': {
                    'fileName': file.attachmentMetadataList[0].fileName,
                    'size': file.attachmentMetadataList[0].size,
                    'type': file.attachmentInfo.typeId
                }
            })
        })
    if (request.length > 0) {
        FetchService.saveFiles(request)
    }
}