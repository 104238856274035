import {Component} from 'react';
import AuthService from '../services/AuthService';
import {get as GetFromStorage, refreshTokenStorage, save as SaveToStorage} from '../services/StorageService';
import {
    useLocation, useNavigate, useParams
} from "react-router-dom";
import {successNotification} from '../utils/Notifications';
import logLevel from '../utils/LoggerHelper';
import {SessionTypesEnums} from '../utils/CustomEnums';
import {PagesEnums} from "../utils/CustomEnums";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (<Component
                {...props}
                router={{location, navigate, params}}
            />);
    }

    return ComponentWithRouterProp;
}

class AuthPage extends Component {

    componentDidMount() {
        const isCheckAuthRequired = Object.values(PagesEnums)
            .filter(page => window.location.pathname === page.link && page.requiredAuth === true)

        let urlParams = new URLSearchParams(window.location.search);
        let token = urlParams.get("token");
        let refreshToken = urlParams.get("refreshToken");
        if (token && refreshToken) {
            SaveToStorage('token', token, SessionTypesEnums.COOKIE);
            SaveToStorage('refreshToken', refreshToken, SessionTypesEnums.COOKIE);
            window.location.href = PagesEnums.MAIN.link;
        }
        
        if (isCheckAuthRequired && isCheckAuthRequired.length) {
            this.checkAuth();
        }
    }

    checkAuth = () => {
        const token = GetFromStorage('token', SessionTypesEnums.COOKIE);
        if (token) {
            // token jest w ciasteczku - uzytkownik uwierzytelniony, wywolanie checkToken
            logLevel.debug('Token jest w ciasteczku');
            AuthService.checkToken()
                .then(() => {
                    //token w ciasteczku jest ważny
                    logLevel.debug("Token jest ważny");
                    //successNotification('auth.successfull', " ");
                }).catch(error => {
                // token w ciasteczku stracił ważność
                logLevel.debug("Token staracił ważność");
                logLevel.error(error);
                const refreshToken = GetFromStorage("refreshToken", SessionTypesEnums.COOKIE);
                AuthService.refreshToken(refreshToken)
                    .then(response => {
                        if (response.status === "success"){
                            refreshTokenStorage(response);
                            window.location.href = PagesEnums.MAIN.link;
                        }
                    })
            })
        } else {
            // nie ma ani tokenu - przekierowanie do strony logowania
            logLevel.debug('Brak tokenu');
            //TODO - do zastanowienia czy na brak tokenu przekierować do strony logowania
            // window.location.replace(PagesEnums.HOME.link);
        }
    }

    render() {
        return (<div/>)
    }
}

export default withRouter(AuthPage);