import React, {useContext, useEffect, useRef, useState} from 'react'
import {Box, Grid, TextField, ThemeProvider, Typography, useMediaQuery} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import 'react-phone-input-2/lib/style.css'
import MenuTile from '../../templates/MenuTile';
import {useStyles, useTheme} from '../../css/Styles';
import InputMask from 'react-input-mask';
import CustomSelectField from '../../templates/CustomSelectField';
import CustomDatePicker from '../../templates/CustomDatePicker';
import CustomRadioGroup from '../../templates/CustomRadioGroup';
import ErrorInfoGrid from '../../templates/ErrorInfoGrid';
import {DictionaryTypeEnums} from '../../utils/CustomEnums';
import {getDictionaries, modifyAccessStorage} from '../../utils/AppUtils';
import FetchService from '../../services/FetchService';
import {createNotificationForResponse, errorNotification, warningNotification} from '../../utils/Notifications';
import {createSaveSchoolThirdDegreeRequest, getSchoolThirdDegreeFormDataFromRequest} from '../../utils/MapperUtils';
import DialogComponent from '../../templates/DialogComponent';

import logLevel from '../../utils/LoggerHelper';
import {ACTIONS} from '../../utils/Consts';
import FileUploadComponent from '../../templates/FileUploadComponent';
import CustomCheckboxField from '../../templates/CustomCheckboxField';
import BackAndSaveButtons from '../../templates/BackAndSaveButtons';
import Popup, {popupReducer} from '../popup/Popup';
import {DatesContext} from "../GlobalDatesContext";
import {filesReducer} from "../../utils/Files/FilesReducer";
import {saveFilesToDatabase} from "../../utils/Files/SaveFilesToDatabase";
import {checkCyrillicInObject} from "../../utils/Validation/CheckForCyrillic";

const filesType = 'GRADUATED_SCHOOL_THIRD_DEGREE'

const defaultDictValue = [{id: "-", label: "-"}]
const defaultRadiosValue = [{id: "-", label: "-", value: "-"}]

const initialSchoolData = {
    certificate: 'DR',
    country: null,
    city: null,
    schoolName: null,
    fieldOfStudy: null,
    graduationDate: null,
    professionalTitle: null,
    schoolDiplomaTitle: null,
    mastersThesisSupervisor: null,
    alCzySwiadPozaPol: null,
    szBeneficjentGrant: false,
    acceptance: null,
}
const selectInputs = [{id: "country", name: "country"}, {id: "city", name: "city"}, {
    id: "professionalTitle",
    name: "professionalTitle"
},]
// const toggleButtonInputs = ["alCzySwiadPozaPol", "szBeneficjentGrant"]
const requiredFields = ['country', 'city', 'schoolName', 'fieldOfStudy', 'graduationDate', 'professionalTitle', 'schoolDiplomaTitle', 'mastersThesisSupervisor', 'acceptance']

export default function SchoolThirdDegree({degree}) {
    const {t} = useTranslation();
    const theme = useTheme;
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))
    const classes = useStyles();

    const [countires, setCountires] = React.useState(defaultDictValue)
    const [cities, setCities] = React.useState(defaultDictValue)
    const [professionalTitles, setProfessionalTitles] = React.useState(defaultDictValue)
    const [acceptanceTypes, setAcceptanceTypes] = React.useState(defaultRadiosValue)

    //data
    const [schoolData, setSchoolData] = useState(initialSchoolData)
    const [formErrors, setFormErrors] = React.useState([])

    //files info reducer
    const [filesInfo, dispatchFilesInfo] = React.useReducer(filesReducer, [])

    //popup reducer
    const [popups, dispatchPopup] = React.useReducer(popupReducer, []);

    //accessContext
    const datesContext = useContext(DatesContext)

    // const dates = useContext(DatesContext)

    const [dates, setDates] = useState()
    //state for disabling edition
    const [readOnly, setReadOnly] = React.useState(false)


    //files refs
    const inputRefs = useRef([])

    //dialog
    const [isDialogSaveOpen, setIsDialogSaveOpen] = React.useState(false)

    //loading awaiting save
    const [loading, setLoading] = React.useState(false)


    useEffect(() => {

        FetchService.getFilesInfo(degree).then(response => {
            let tempInputRefs = response.zawartosc?.map((element, index) => {
                return inputRefs.current[index] = React.createRef();
            })
            dispatchFilesInfo({type: ACTIONS.SET_INITIAL_FILES, payload: response.zawartosc, inputRefs: tempInputRefs})
        })


        prepareDictionaries().then(responseDict => {
            FetchService.getSchoolData(degree).then(response => {
                if (response && response.zawartosc) {
                    setSchoolData(getSchoolThirdDegreeFormDataFromRequest(response.zawartosc, responseDict))
                } else {
                    createNotificationForResponse(response);
                }
            }).catch(error => {
                logLevel.debug("Błąd podczas odczytu danych szkoły 3 stopnia, Error " + error);
                errorNotification('default.errorOnGet', ' ');
            })
        })

        datesContext.fetchGetMoreDates(degree,datesContext.currentSemester).then(result => {
            setDates(result)
            manageAccess(result)
        })
    }, [])

    function manageAccess(dates) {
        setReadOnly(true)
        if (new Date(dates[degree]?.recruitmentStart) <= new Date(dates?.currentDateTime) && new Date(dates?.currentDateTime) <= new Date(dates[degree]?.recruitmentClosure)) {
            setReadOnly(false)
        }
    }


    async function prepareDictionaries() {
        return getDictionaries(DictionaryTypeEnums.SCHOOL_THIRD_DEGREE).then(thirdDegreeSchoolDict => {
            if (thirdDegreeSchoolDict) {
                let thirdDegreeSchoolDictValues = thirdDegreeSchoolDict.value

                let countriesTemp = [];
                let citiesTemp = [];
                let professionalTitlesTemp = [];
                let acceptanceTypesTemp = []

                let countiresDict = thirdDegreeSchoolDictValues.kraj;
                let citiesDict = thirdDegreeSchoolDictValues.miasta;
                let professionalTitlesDict = thirdDegreeSchoolDictValues.dyplom_tytul;
                let acceptanceTypesDict = thirdDegreeSchoolDictValues.tryby_przyjec;


                if (countiresDict) {
                    countiresDict.forEach(country => {
                        countriesTemp.push({id: country.valuePL, label: country.valuePL})
                    });
                    setCountires(countriesTemp)
                }

                if (citiesDict) {
                    citiesDict.forEach(city => {
                        citiesTemp.push({id: city.valuePL, label: city.valuePL})
                    });
                    setCities(citiesTemp)
                }

                if (professionalTitlesDict) {
                    professionalTitlesDict.forEach(title => {
                        professionalTitlesTemp.push({id: title.key.split('|')[1], label: title.valuePL})
                    });
                    setProfessionalTitles(professionalTitlesTemp)
                }

                if (acceptanceTypesDict) {
                    acceptanceTypesDict.forEach(type => {
                        acceptanceTypesTemp.push({id: type.key.split('|')[1], value: type.valuePL, label: type.valuePL})
                    })
                    setAcceptanceTypes(acceptanceTypesTemp)
                }

                return {
                    countries: countriesTemp,
                    cities: citiesTemp,
                    professionalTitles: professionalTitlesTemp,
                    acceptanceTypes: acceptanceTypesTemp
                }
            }
        });
    }

    const changeDataHandler = (event, newValue, ref) => {
        let fields = {...schoolData};

        if (ref && (ref === 'selectOption' || ref === 'blur')) {
            let inputSelectField = selectInputs.find(selectInput => event.target.id.startsWith(selectInput.id))
            fields[inputSelectField.name] = newValue
            // } 
            // else if (toggleButtonInputs.includes(event.target.name)) {
            //   if (fields[event.target.name] !== null && fields[event.target.name] === stringToBoolean(event.target.value)) {
            //     fields[event.target.name] = null
            //   } else {
            //     fields[event.target.name] = stringToBoolean(event.target.value);
            //   }
        } else if (event.target.name === 'szBeneficjentGrant' || event.target.name === 'alCzySwiadPozaPol') {
            fields[event.target.name] = event.target.checked;
        } else if (event.target.name === 'acceptance') {
            fields[event.target.name] = event.target.value;
        } else {
            if (event.target.value !== '') {
                fields[event.target.name] = event.target?.value[0].toUpperCase() + event.target?.value.slice(1)
            } else {
                fields[event.target.name] = null
            }
        }
        setSchoolData(fields)
    }

    const graduationDateHandler = (value) => {
        let fields = {...schoolData};
        fields.graduationDate = value
        setSchoolData(fields)
    }

    const handleCloseSaveConfirmDialog = () => {
        setIsDialogSaveOpen(false)
    }

    const handleValidation = () => {
        let errors = [];
        let formIsValid = true;
        let tempRequiredFieldsvalidation = requiredFields;

        let fields = {...schoolData};

        tempRequiredFieldsvalidation.filter(reqField => (fields[reqField] === undefined || fields[reqField] === null || (typeof fields[reqField] === 'string' && fields[reqField].trim().length === 0)))
            .forEach(reqField => {
                errors = errors.concat({id: reqField, value: "default.fieldRequired"});
                formIsValid = false;
            })

        if ( checkCyrillicInObject(fields)){
            errors.push({id: 'cyrillicError',value: "cyrillicError"});
            formIsValid = false;
        }
        
        setFormErrors(errors)
        return formIsValid;
    }

    const handleOpenSaveConfirmDialog = () => {
        if (handleValidation()) {
            setIsDialogSaveOpen(true)
        } else {
            warningNotification("personal.formValidationError", " ");
        }
    }

    const handleSave = () => {
        setLoading(true)
        saveFilesToDatabase(filesInfo)
        handleCloseSaveConfirmDialog()
        FetchService.saveSchoolData(createSaveSchoolThirdDegreeRequest(schoolData, acceptanceTypes), degree).then(() => {
            modifyAccessStorage(degree)
            setLoading(false)
        })
    }


    return (<>
            <ThemeProvider theme={theme}>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: isMobile ? 'center' : 'flex-start',
                    flexDirection: isMobile ? 'column' : 'row'
                }}>
                    <Grid sx={{padding: '2.5vh'}} display="flex" justifyItems="end">
                        <MenuTile title={t('school.schoolThirdDegree')} locked={false}/>
                    </Grid>
                    <Grid sx={{padding: isMobile ? '1vh' : '3vh'}} container direction={'row'}
                          justifyContent="flex-start" alignItems="flex-start">
                        <Grid style={{
                            width: '100%',
                            textAlign: 'left',
                            marginBottom: '2vh',
                            marginTop: '2vh',
                            paddingLeft: '1.5vw'
                        }}>
                            <Typography fontWeight='bold' variant='h2'>{t('school.graduatedUniversity')}</Typography>
                        </Grid>
                        <Grid style={{width: '100%'}} container direction={isMobile ? 'column' : 'row'}
                              justifyContent="space-between">
                            <Grid style={{width: isMobile ? '100%' : '45%'}}>
                                <CustomSelectField editState={readOnly} name="country" id="country"
                                                   title={t('personal.kraj')} options={countires}
                                                   value={schoolData.country} freeSolo={false} required={true}
                                                   changeHandler={changeDataHandler} errors={formErrors}/>
                            </Grid>
                            <Grid style={{width: isMobile ? '100%' : '45%'}}>
                                <CustomSelectField editState={readOnly} name="city" id="city" title={t('school.city')}
                                                   options={cities} value={schoolData.city} freeSolo={true}
                                                   required={true} changeHandler={changeDataHandler}
                                                   errors={formErrors}/>
                            </Grid>
                            <CustomGridField disabledState={readOnly} name="schoolName" title={t('school.university')}
                                             label={t('school.university')} value={schoolData.schoolName}
                                             changeHandler={changeDataHandler} required={true} errors={formErrors}/>
                            <CustomGridField disabledState={readOnly} name="fieldOfStudy"
                                             title={t('school.graduatedCourse')} label={t('school.graduatedCourse')}
                                             value={schoolData.fieldOfStudy} changeHandler={changeDataHandler}
                                             required={true} errors={formErrors}/>
                            <Grid style={{width: isMobile ? '100%' : '45%'}}>
                                <CustomDatePicker editState={readOnly} name="graduationDate"
                                                  title={t('school.thirdDegreeGraduationDate')}
                                                  label={t('school.thirdDegreeGraduationDate')}
                                                  value={schoolData.graduationDate} disabledState={false}
                                                  changeHandler={graduationDateHandler} required={true}
                                                  errors={formErrors} disableFuture={false}/>
                            </Grid>
                            <Grid style={{width: isMobile ? '100%' : '45%'}}>
                                <CustomSelectField editState={readOnly} name="professionalTitle" id="professionalTitle"
                                                   title={t('school.professionalTitle')} options={professionalTitles}
                                                   value={schoolData.professionalTitle} freeSolo={false} required={true}
                                                   changeHandler={changeDataHandler} errors={formErrors}/>
                            </Grid>
                            <CustomGridField disabledState={readOnly} name="schoolDiplomaTitle"
                                             title={t('school.diplomaTitle')} label={t('school.diplomaTitle')}
                                             value={schoolData.schoolDiplomaTitle} changeHandler={changeDataHandler}
                                             required={true} errors={formErrors}/>
                            <CustomGridField disabledState={readOnly} name="mastersThesisSupervisor"
                                             title={t('school.promoter')} label={t('school.promoter')}
                                             value={schoolData.mastersThesisSupervisor}
                                             changeHandler={changeDataHandler} required={true} errors={formErrors}/>
                            <Grid style={{
                                width: '100%',
                                textAlign: 'left',
                                marginBottom: '2vh',
                                marginTop: '2vh',
                                paddingLeft: '1.5vw'
                            }}>
                                <Typography fontWeight='bold'
                                            variant='h2'>{t('school.certificateForeignInfo')}</Typography>
                            </Grid>
                            {/* <CustomToggleButtonTrueFalseField name="alCzySwiadPozaPol" title={t('school.certificateForeign')} label={t('school.certificateForeign')} value={schoolData.alCzySwiadPozaPol} changeHandler={changeDataHandler} inlineLabel={true} /> */}
                            <CustomCheckboxField disabledState={readOnly} name="alCzySwiadPozaPol"
                                                 title={t('school.certificateForeign')}
                                                 label={t('school.certificateForeign')}
                                                 value={schoolData.alCzySwiadPozaPol} changeHandler={changeDataHandler}
                                                 className={classes.daneOsoboweGridField}/>

                            <Grid style={{
                                width: '100%',
                                textAlign: 'left',
                                marginBottom: '2vh',
                                marginTop: '2vh',
                                paddingLeft: '1.5vw'
                            }}>
                                <Typography fontWeight='bold' variant='h2'>{t('school.beneficiaryInfo')}</Typography>
                            </Grid>
                            {/* <CustomToggleButtonTrueFalseField name="szBeneficjentGrant" title={t('school.beneficiary')} label={t('school.beneficiary')} value={schoolData.szBeneficjentGrant} changeHandler={changeDataHandler} inlineLabel={true} /> */}
                            <CustomCheckboxField disabledState={readOnly} name="szBeneficjentGrant"
                                                 title={t('school.beneficiary')} label={t('school.beneficiary')}
                                                 value={schoolData.szBeneficjentGrant} changeHandler={changeDataHandler}
                                                 className={classes.daneOsoboweGridField}/>
                            <Grid style={{
                                width: '100%',
                                textAlign: 'left',
                                marginBottom: '2vh',
                                marginTop: '2vh',
                                paddingLeft: '1.5vw'
                            }}>
                                <Typography fontWeight='bold' variant='h2'>{t('school.acceptanceType')}</Typography>
                            </Grid>
                            <CustomRadioGroup disabledState={readOnly} name={`acceptance`} value={schoolData.acceptance}
                                              radioData={acceptanceTypes} changeHandler={changeDataHandler} row={false}
                                              errors={formErrors}/>
                        </Grid>
                        {/* FILE UPLOAD */}
                        <Grid style={{
                            width: '100%',
                            textAlign: 'left',
                            marginBottom: '2vh',
                            marginTop: '2vh',
                            paddingLeft: '1.5vw'
                        }}>
                            <Typography fontWeight='bold' variant='h2'>{t('school.requiredFiles')}</Typography>
                            <Typography variant='h6'>{t('school.filesNaming')}</Typography>
                        </Grid>
                        {filesInfo?.map((element, index) => {
                            return (

                                <>
                                    <React.Fragment key={element.attachmentInfo.typeId}>
                                        {element.attachmentInfo.typeId === 31 || element.attachmentInfo.typeId === 32 || element.attachmentInfo.typeId === 33 ? FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, 30, readOnly) : (element.attachmentInfo.typeId === 35 || element.attachmentInfo.typeId === 36 || element.attachmentInfo.typeId === 37 ? FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, 34, readOnly) : (element.attachmentInfo.typeId === 39 ? FileUploadComponent(index, element, dispatchFilesInfo, readOnly) : FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, element.attachmentInfo.typeId, readOnly)))}

                                    </React.Fragment>
                                </>)
                        })}
                        <BackAndSaveButtons saveButtonFunction={handleOpenSaveConfirmDialog} loading={loading}
                                            readOnly={readOnly}/>
                    </Grid>
                </Box>
                {/* ------------------------POPUPS------------------------ */}
                {popups?.map(popup => {
                    return Popup(popup, dispatchPopup)
                })}
                {/* ------------------------POPUPS------------------------ */}
            </ThemeProvider>
            <DialogComponent
                title={t('default.confirmSave')}
                content={t('school.confirmSave')}
                visible={isDialogSaveOpen}
                buttons={[{
                    buttonlabel: 'default.cancel',
                    buttonAction: () => handleCloseSaveConfirmDialog()
                }, {buttonlabel: 'yes', buttonAction: () => handleSave()}]}
            />
        </>)
}

export function CustomGridField({
                                    label,
                                    value,
                                    disabledState = false,
                                    dataType = 'text',
                                    changeHandler,
                                    name,
                                    required,
                                    capital,
                                    mask,
                                    year,
                                    errors
                                }) {

    const classes = useStyles();
    if (value == null) {
        value = ''
    }

    return (<Grid item xs className={classes.daneOsoboweGridField}>
            {mask ? <InputMask
                    mask={year ? "9999" : "99999999999"}
                    value={value}
                    disabled={disabledState}
                    maskChar=" "
                    onChange={changeHandler}
                >
                    {inputProps => <TextField
                        {...inputProps}
                        className={(errors && errors.find(errors => errors.id === name)) ? classes.customTextFieldError : classes.customTextField}
                        InputProps={{
                            classes: {
                                input: classes.resizeDaneOsobowe,
                            },
                        }}
                        variant="outlined"
                        label={label} name={name} required={required}
                    />}
                </InputMask>

                : <TextField
                    className={(errors && errors.find(errors => errors.id === name)) ? classes.customTextFieldError : classes.customTextField}
                    type={dataType}
                    InputProps={{
                        classes: {
                            input: capital ? classes.resizeDaneOsoboweCapital : classes.resizeDaneOsobowe,
                        },
                    }}
                    variant="outlined"
                    label={label} value={value} name={name} onChange={changeHandler} disabled={disabledState}
                    required={required}
                />}
            <ErrorInfoGrid errors={errors} fieldName={name}></ErrorInfoGrid>
        </Grid>)
}