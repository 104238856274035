import {useStyles} from "../../css/Styles";
import {Grid, TextField} from "@material-ui/core";
import InputMask from "react-input-mask";
import ErrorInfoGrid from "../../templates/ErrorInfoGrid";
import React from "react";

export function CustomGridField({
                                    label,
                                    value,
                                    disabledState = true,
                                    dataType = 'text',
                                    changeHandler,
                                    name,
                                    required,
                                    capital,
                                    pesel,
                                    errors
                                }) {
    const classes = useStyles();
    if (value == null) {
        value = ''
    }

    const numberRegex = /(^\d*$)/

    const checkIfNumber = (event) => {
        const regex = new RegExp(numberRegex);
        if (event.keyCode === 8) {
            return true
        } else {
            return !event.key.match(regex) && event.preventDefault();
        }
    }

    return (
        <Grid item xs className={classes.daneOsoboweGridField}>
            {pesel ?
                <InputMask
                    mask="99999999999"
                    value={value}
                    disabled={disabledState}
                    maskChar=" "
                    onChange={changeHandler}
                >
                    {inputProps => <TextField
                        {...inputProps}
                        className={(errors && errors.find(errors => errors.id === name)) ? classes.customTextFieldError : classes.customTextField}
                        InputProps={{
                            classes: {
                                input: classes.resizeDaneOsobowe,
                            },
                        }}
                        data-testid={name}
                        variant="outlined"
                        label={label} name={name} required={required}
                    />}
                </InputMask>

                :
                <TextField
                    className={(errors && errors.find(errors => errors.id === name)) ? classes.customTextFieldError : classes.customTextField}
                    type={dataType}
                    onKeyDown={(dataType === 'number') ? (event) => checkIfNumber(event) : null}
                    InputProps={{
                        classes: {
                            input: capital ? classes.resizeDaneOsoboweCapital : classes.resizeDaneOsobowe,
                        },
                    }}
                    variant="outlined"
                    label={label} value={value} name={name} onChange={changeHandler} disabled={disabledState}
                    data-testid={name}
                    required={required}
                />}
            <ErrorInfoGrid errors={errors} fieldName={name}></ErrorInfoGrid>
        </Grid>
    )
}