import {Autocomplete, Grid, TextField} from '@material-ui/core';
import {useStyles} from '../css/Styles';
import ErrorInfoGrid from '../templates/ErrorInfoGrid';
import {useTranslation} from "react-i18next";


export default function CustomSelectField({
                                              title,
                                              options,
                                              value,
                                              inputValue,
                                              editState,
                                              changeHandler,
                                              inputHandler,
                                              maxWidth = false,
                                              name,
                                              id,
                                              required,
                                              selectOptionSufix,
                                              freeSolo = false,
                                              errors
                                          }) {

    const classes = useStyles();
    const {t} = useTranslation();


    return (
        <Grid item xs style={{maxWidth: maxWidth}} className={classes.daneOsoboweGridField}>
            <Autocomplete
                id={`${id}${selectOptionSufix}`}
                classes={{
                    input: classes.autoComplete,
                }}
                name={name}
                disableClearable={(required) ? true : false}
                clearIcon={null}
                autoSelect
                blurOnSelect
                freeSolo={freeSolo}
                options={options}
                value={value}
                onChange={changeHandler}
                inputValue={inputValue}
                onInputChange={inputHandler}
                onBlur={e => {
                    e.preventDefault();
                }}
                disabled={editState}
                autoComplete={true}
                selectOnFocus={true}
                clearOnEscape={true}
                style={{width: "100%"}}
                ListboxProps={{style: {maxHeight: '30vh', overflow: 'auto'}}}
                //TODO - może się przydać na przyszłość - kolor dla listy rozwijanej
                //  PaperComponent={({ children }) => (
                //    <Paper style={{ background: "yellow" }}>{children}</Paper>
                //  )}
                renderInput={(params) =>
                    <TextField {...params}
                               className={(errors && errors.find(errors => errors.id === name)) ? classes.customTextFieldError : classes.customTextField}
                               label={t(title)}
                               disabled={editState}
                               data-testid={name}
                               variant="outlined"
                               required={required}
                    />
                }
            />
            <ErrorInfoGrid errors={errors} fieldName={name}></ErrorInfoGrid>
        </Grid>
    )
}