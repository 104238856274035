import { useStyles } from '../css/Styles'
import { Typography,  Grid, Box } from '@material-ui/core';


export default function Help() {
   // const theme = useTheme;
   const classes = useStyles();

   return (
      <div>
         <Grid container direction="row" alignItems="flex-start" justifyContent="center">
            <Box sx={{display: 'flex', justifyContent: 'center',  padding: 3, alignItems: 'flex-start'}}>         
               <Grid container alignItems='center' justifyContent='space-between' direction='column'>
                  <Typography sx={{padding: 2}} className={classes.homeText} variant='h5'>
                    TODO Pomoc
                  </Typography>   
                  <img src="rejestracjaPL.png" />
               </Grid>
            </Box>
         </Grid>            
      </div>
   );

}