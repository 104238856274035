import { useStyles, useTheme } from '../css/Styles';
import { ThemeProvider, Typography,  Grid, Box, Paper} from '@material-ui/core';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';

export default function MenuTile({title, locked, messagesCount}) {
   const theme = useTheme;
   const classes = useStyles();

   return (
      <ThemeProvider theme={theme}>
         <Paper sx={{backgroundColor: theme.palette.secondary.main, color: theme.palette.primary.main}} className={classes.tile} elevation={5}>
            <Grid container sx={{height: '100%'}} direction='column' justifyContent="space-between" alignItems="flex-start">
               <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>
                  {locked ? <LockOutlinedIcon sx={{fontSize: 30, color: theme.palette.primary.main, display: 'flex'}}/> : <LockOpenOutlinedIcon sx={{fontSize: 30, color: theme.palette.allow.main, display: 'flex'}}/>}
               </Box>
               <Typography width={'100%'} align='left' variant='h6'>
                  {title}
                  {messagesCount !== null && messagesCount > 0 && 
                     <Badge badgeContent={messagesCount} color="success" sx={{float:'right', marginRight:'10px'}}>
                        <MailIcon color="action" sx={{color: '#fff'}}/>
                     </Badge>
                  }
               </Typography>
            </Grid>
         </Paper>
      </ThemeProvider>
   );

}
