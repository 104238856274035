import { useContext } from 'react';
import PhotoManagementContext from '../../PhotoManagementContext';

import { CropPhoto, Summary, UploadPhoto } from '..';

const ComponentSwitcher = () => {
  const { activeStep } = useContext(PhotoManagementContext);

  switch (activeStep) {
    case 0:
      return <UploadPhoto />;
    case 1:
      return <CropPhoto />;
    case 2:
      return <Summary />;
    default:
      return null;
  }
};

export default ComponentSwitcher;
