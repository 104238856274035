import React, { useEffect, useState } from 'react';
import { useStyles, useTheme } from '../css/Styles'
import { Typography, Grid, Box, Button, TextField, ThemeProvider, useMediaQuery } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthService from '../services/AuthService';
import { PagesEnums } from "../utils/CustomEnums";
import { getCredentials } from '../services/UserDataService';
import Popup, { popupReducer } from './popup/Popup';

export default function Home() {
   const theme = useTheme;
   const classes = useStyles();
   const { t } = useTranslation();

   const [email, setEmail] = React.useState();
   const [pass, setPass] = React.useState();

   const [popup, dispatchPopup] = React.useReducer(popupReducer, null);
   


   const isMobile = useMediaQuery(theme.breakpoints.down('phone'))
   
   useEffect(() => {
      if (getCredentials()) {
         window.location.href = PagesEnums.MAIN.link;
      }
   }, [])

   const emailHandler = (event) => {
      setEmail(event.target.value)
   }

   const passHandler = (event) => {
      setPass(event.target.value)
   }

   const login = () => {
      AuthService.login(email, pass);

   }

   const handleKeyPress = (e) => {
      if (e.keyCode === 13) {
         login();
      }
   }

   const BootstrapButton = styled(Button)({
      // boxShadow: 'none',
      // textTransform: 'none',
      fontSize: 15,
      color: 'white',
      // border: '1px solid',
      borderColor: 'none',
      lineHeight: 1,
      minHeight: 60,
      backgroundColor: '#30376c',
      fontFamily: [
         "Arial",
         "sans-serif"
      ].join(','),
      '&:hover': {
         backgroundColor: theme.palette.secondary.hovered,
         borderColor: 'none',
         boxShadow: 'none',
      },
      // '&:active': {
      //   boxShadow: 'none',
      //   backgroundColor: '#f2f2f2',
      //   borderColor: 'none',
      // },
      // '&:focus': {
      //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      // },
   });



   return (
      <ThemeProvider theme={theme}>
         <div style={{ width: '100%', height: 'auto' }}>
            <Grid sx={{ height: '100%' }} container direction="row" alignItems="center" justifyContent="center">
               <Grid container direction='row' sx={{ display: 'sticky' }} justifyContent='center'>
                  <Box sx={{ display: 'sticky', justifyContent: 'center', alignItems: 'flex-start' }}>
                     <Grid container sx={{ width: isMobile ? 600 : 800 }} alignItems='center' justifyContent='space-between' direction='column'>
                        <Typography sx={{ padding: 2 }} className={classes.homeText} variant='h2' >
                           {t("home.loggingPanel")}
                        </Typography>
                        <TextField autoFocus type="email" sx={{ marginBottom: 2, width: isMobile ? '60%' : '90%' }} className={classes.customTextField} label={t("home.emailLabel")} variant="outlined" onChange={emailHandler}
                           onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                 login()
                              }
                           }} />
                        <TextField type="password" sx={{ marginBottom: 2, width: isMobile ? '60%' : '90%' }} className={classes.customTextField} label={t("home.passwordLabel")} variant="outlined" onChange={passHandler}
                           onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                 login()
                              }
                           }} />
                        <BootstrapButton sx={{ width: isMobile ? '40%' : '70%' }} variant='standard' onKeyPress={handleKeyPress} onClick={login}>{t("home.loginButton")}</BootstrapButton>
                        <Typography sx={{ padding: 2 }} className={classes.homeText} component={NavLink} to="/passrestore">
                           {t("home.passwordRestore")}
                        </Typography>
                     </Grid>
                  </Box>
                  <Box sx={{ display: 'sticky', justifyContent: 'center', alignItems: 'flex-start' }}>
                     <Grid container sx={{ width: isMobile ? 600 : 800 }} alignItems='center' justifyContent='space-between' direction='column'>
                        <Typography sx={{ padding: 2 }} className={classes.homeText} variant='h2' >
                           {t("home.noAccount")}
                        </Typography>
                        <BootstrapButton sx={{ width: isMobile ? '40%' : '70%' }} variant='standard' component={NavLink} to={PagesEnums.REGISTER.link}>{t("home.registerButton")}</BootstrapButton>


                        <Typography sx={{ padding: 2 }} className={classes.homeText} onClick={() => dispatchPopup({ type: 'OPEN', payload:  'help' })}>
                           {t("home.help")}
                        </Typography>
                     </Grid>
                  </Box>
               </Grid>

            </Grid>
            {Popup(popup, dispatchPopup) /*popup*/}
         </div>
      </ThemeProvider>
   );

}