import React from 'react';
import { Typography, Grid, Box, useMediaQuery, ThemeProvider, Card, CardContent, Button, TextField, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useStyles, useTheme } from '../../css/Styles'


function Activation(props) {
    const theme = useTheme;
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('phone'))
    const classes = useStyles();

    const [open, setOpen] = React.useState(false)

    const BootstrapButton = styled(Button)({
        // boxShadow: 'none',
        // textTransform: 'none',
        fontSize: 15,
        color: 'white',
        // border: '1px solid',
        borderColor: 'none',
        lineHeight: 1,
        minHeight: 60,
        backgroundColor: '#30376c',
        fontFamily: [
            "Arial",
            "sans-serif"
        ].join(','),
        '&:hover': {
            backgroundColor: theme.palette.secondary.hovered,
            borderColor: 'none',
            boxShadow: 'none',
        },
        // '&:active': {
        //   boxShadow: 'none',
        //   backgroundColor: '#f2f2f2',
        //   borderColor: 'none',
        // },
        // '&:focus': {
        //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    });

    return (
        <ThemeProvider theme={theme}>
            <div style={{ width: '100%', height: 'auto', textAlign: 'center' }}>

                <Grid container sx={{ width: '80%', height: '100%', margin: 'auto' }} alignItems='center' justifyContent='center' direction={'column'}>
                    <Card sx={{
                        backgroundColor: theme.palette.additional.main,
                        marginTop: '2vh',
                        border: '2px solid',
                        borderColor: theme.palette.secondary.main,
                        marginBottom: isMobile ? '1vh' : '4vh',
                        padding: isMobile ? 0 : 2
                    }}
                        variant="outlined">
                        <CardContent>
                            <Typography sx={{ color: theme.palette.secondary.main }} fontWeight='bold' variant={isMobile ? 'h7' : 'h1'}>{t("activationStudentAccount.activation")}</Typography>
                        </CardContent>
                    </Card>
                    <Grid sx={{ width: isMobile ? '100%' : '70%' }} container direction='column' alignItems='center'>
                        <BootstrapButton onClick={() => { window.location.href = "http://aktywacja.p.lodz.pl/"; }} sx={{ width: isMobile ? '100%' : '40%', marginTop: '3vh', marginBottom: '2vh' }} variant='standard'>{t("activationStudentAccount.goTo")}</BootstrapButton>
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    )
}

export default Activation
