function containsCyrillic(text) {
    return /[а-яА-ЯЁё]/.test(text);
}

function containsChinese(text) {
    return /[а-яА-ЯЁё]/.test(text);
}

function containsJapanese(text) {
    return /[а-яА-ЯЁё]/.test(text);
}

export function checkCyrillicInObject(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let value = obj[key];

            // If the value is a string, test it
            if (typeof value === 'string' && containsCyrillic(value)) {
                return true;
            }

            // If the value is an object, recurse
            else if (typeof value === 'object' && value !== null) {
                if (checkCyrillicInObject(value)) {
                    return true;
                }
            }
        }
    }

    // If nothing was found
    return false;
}

export function checkChineseInObject(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let value = obj[key];

            // If the value is a string, test it
            if (typeof value === 'string' && containsChinese(value)) {
                return true;
            }

            // If the value is an object, recurse
            else if (typeof value === 'object' && value !== null) {
                if (checkCyrillicInObject(value)) {
                    return true;
                }
            }
        }
    }

    // If nothing was found
    return false;
}

export function checkJapaneseInObject(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let value = obj[key];

            // If the value is a string, test it
            if (typeof value === 'string' && containsJapanese(value)) {
                return true;
            }

            // If the value is an object, recurse
            else if (typeof value === 'object' && value !== null) {
                if (checkCyrillicInObject(value)) {
                    return true;
                }
            }
        }
    }

    // If nothing was found
    return false;
}


