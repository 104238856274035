import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {FormHelperText} from "@mui/material";
import React from "react";
import {useStyles} from "../../../../css/Styles";


export default function SelectTitle(props) {
    const classes = useStyles();

    return (
        <FormControl fullWidth>   {/*error = {props.error.isError}*/}
            <InputLabel sx={{color: '#808080', "&.Mui-focused": {color: "#808080"}}}
                        id={props.name}>{props.label}</InputLabel>
            <Select
                sx={{minHeight: '130px'}}
                labelId={props.name}
                id={props.name}
                name={props.name}
                disabled={props.editState}
                value={props.value}
                label={props.label}
                onChange={props.onChange}
                className={classes.customSelect}
                variant="outlined"

            >
                {
                    props.options.map((item) =>
                        <MenuItem value={item.code}> {item.code + " - " + item.name}</MenuItem>
                    )
                }
            </Select>
            <FormHelperText>{/*{props.error.isError ? props.error.errorMessage : props.caption}*/}</FormHelperText>
        </FormControl>
    )
}
