import DefaultErrorPage from './DefaultErrorPage';
import { Grid } from '@material-ui/core';

export default function AccessDenied() {

    return (
        <div style={{width: '100%', height: 'auto'}}>
            <Grid container sx={{height: '100%', width: '100%'}} alignItems='center' justifyContent='center'>
                <DefaultErrorPage message={'default.accessDenied'} />
            </Grid>
        </div>
    )
}