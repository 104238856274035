import React, {createContext, useEffect, useState} from 'react'
import {getAccessStorageValue, setInitialAccess} from '../utils/AppUtils'
import {getCredentials} from '../services/UserDataService'
import FetchService from "../services/FetchService";
import {get as GetFromStorage} from "../services/StorageService";
import {SessionTypesEnums} from "../utils/CustomEnums";

async function fetchGetTrybDates(tryb, currentSemester) {
    return await FetchService.getDates(tryb + '_' + currentSemester)
}
export const DatesContext = createContext()

export default function GlobalDatesContext(params) {
    const [dates, setDates] = useState()
    const [currentSemester, setCurrentSemester] = useState()

    async function fetchGetMoreDates(tryb) {
        if (tryb) {
            if (!dates[tryb]) {
                const data = await fetchGetTrybDates(tryb, currentSemester);
                let tempDates = data.zawartosc.dates;
                let prevDates = dates;
                prevDates[tryb] = tempDates;
                setDates(prevDates);
                return prevDates;
            } else {
                return dates;
            }
        } else {
            return dates;
        }
    }


    async function fetchData() {
        try {
            if (currentSemester) {

            }
            const date = await FetchService.getDates('_' + currentSemester)
            setDates(date.zawartosc)
        } catch (error) {
            console.error('Error fetching dates:', error)
        }
    }


    useEffect(() => {
        if (GetFromStorage('token', SessionTypesEnums.COOKIE)) {
            FetchService.getDates("_undefiended").then((reasponse) => {
                setCurrentSemester(reasponse?.zawartosc?.generalDates?.recruitmentModeEntities?.ptRoksem)
                setDates(reasponse?.zawartosc)
            }).finally(() => {
                if (getCredentials()) {
                    const access = getAccessStorageValue()
                    if (access === null) {
                        setInitialAccess()
                    }
                }
            })
        }else{
            fetchData()
        }
    }, [])

    if (dates) {
        return (
            <DatesContext.Provider value={{dates, setDates, currentSemester, fetchGetMoreDates}}>
                {params.children}
            </DatesContext.Provider>)
    }


}
