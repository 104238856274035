import React from 'react';
import {Grid, TextField, ThemeProvider} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import 'react-phone-input-2/lib/style.css'
import 'dayjs/locale/en-gb';
import {useStyles, useTheme} from '../css/Styles';
import ErrorInfoGrid from '../templates/ErrorInfoGrid';

export default function CustomDatePicker({ label, value, editState = false, disabledState = true, changeHandler, name, required, disableFuture, errors }) {

   const classes = useStyles();
   const { t } = useTranslation();
   const theme = useTheme;
   return (
      <ThemeProvider theme={theme}>
         <Grid className={classes.daneOsoboweGridField}>
                     <TextField
                         InputLabelProps={{shrink: true}}
                         className={(errors && errors.find(errors => errors.id === name)) ? classes.customTextFieldError : classes.customTextField}
                         label={t(label)}
                         data-testid={name}
                         disabled={editState}
                         disableFuture={disableFuture}
                         required={required}
                         type="date"
                         defaultValue="2000-01-01"
                         value={value || ""}
                         onChange={(event) => {
                             changeHandler(event.target.value);
                         }}
                         inputProps={{ min: "1900-01-01", max: "2100-01-01" }}
                         variant="outlined"
                         name={name} 
                     />
            <ErrorInfoGrid errors={errors} fieldName={name}></ErrorInfoGrid>
         </Grid>
      </ThemeProvider>
   )
}