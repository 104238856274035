import NotFoundImage from './NotFoundImage';

export default function PageNotFound() {

    return (
        // TODO skalowanie
        <div className="errorDiv">
            <NotFoundImage />
            <div className="message-box" style={{marginTop: "-275px" }}>
                <h1>Strona której szukasz nie istnieje</h1>
            </div>
        </div>
    )
}