import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import {I18nextProvider} from 'react-i18next';
import {Store} from "react-notifications-component";
import {t} from "i18next";
import {configureStore} from '@reduxjs/toolkit';
import {occupationTitlesReducer} from "./components/school/partials/OccupationTiles/OccupationTilesReducer";
import {Provider} from "react-redux";

const root = ReactDOM.createRoot(document.getElementById('root'));

export const store = configureStore({
    reducer: {
        occupationTitles: occupationTitlesReducer, // spread the rootReducer
    },
});

function checkAndNotifyLocalStorageEnabled() {
    try {
        const testKey = "test";
        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);
        return true;
    } catch (e) {
        Store.addNotification({
            title: "LocalStorage Error",
            message: t('localStorage'),
            type: "warning",
            container: "bottom-right",
            dismiss: {
                duration: 10000,
                onScreen: true,
                pauseOnHover: true

            }
        });
    }
}

function AppWrapper() {
    const [isReady, setIsReady] = useState(false);
    useEffect(() => {
        setLanguageOnStart();
        checkAndNotifyLocalStorageEnabled();
    }, []);

    const setLanguageOnStart = () => {
        var lng = localStorage.getItem('language');
        lng = lng == null ? 'pl' : lng;
        i18n.changeLanguage(lng);
        setIsReady(true); // To 100% ensure is loaded
    };


    return (
        <div>
            {isReady && (
                <Provider store={store}>
                    <I18nextProvider i18n={i18n}>
                        <Router>
                            <App/>
                        </Router>
                    </I18nextProvider>
                </Provider>
            )}
        </div>
    );
}


root.render(<AppWrapper/>);

reportWebVitals();
