export function MapToSaveArray({occupationTitlesData}) {
    let titlesArray = []
    occupationTitlesData?.forEach(title => {
        let exams = title.exams.map((exam) => {
            return ({
                "practicalGrade": exam.practicalGrade,
                "subject": exam.code,
                "theoreticalGrade": exam.theoreticalGrade
            })
        })
        if (title.title && title.selectedCombo) {
            titlesArray.push(
                {
                    "grade": title?.collectiveGrade,
                    "level": (title.onlyHasCollectiveGrade ? 'TECH_DIPLOMA' : 'TECH_QUALI'),
                    "percentagePoints": 0, //Olać
                    "qualificationPartialGrades": exams,
                    "subject": title?.title,
                    "type": "DIPLOMA"
                }
            )
        }

    })
    return titlesArray;
}