import { useContext, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import PhotoManagementContext from '../../PhotoManagementContext';

import { StepButtons } from '..';
import { FaceRecognizedModal } from './components';
import { photoValidationRulesApi } from '../../photoValidationRules';

import FetchService from '../../../../services/FetchService';
import { modifyAccessStorage } from '../../../../utils/AppUtils';
import { errorNotification, successNotification } from '../../../../utils/Notifications';

const Summary = () => {
  const { t } = useTranslation();
  const { photoBaseCropped, handlePreviousStep, setPhotoBaseCropped, handleResetActiveStep } = useContext(PhotoManagementContext);
  const [photoValidatingStatus, setPhotoValidatingStatus] = useState(false);
  const [photoSendingStatus, setPhotoSendingStatus] = useState(false);
  const [faceRecognizedModalOpen, setFaceRecognizedModalOpen] = useState(false);

  const onClickPreviousStep = () => {
    setPhotoBaseCropped(null);
    handlePreviousStep();
  };

  const onClickEndAction = async () => {
    setPhotoValidatingStatus(true);
    const responseValidation = await FetchService.sendPhotoToValidate(photoBaseCropped, photoValidationRulesApi, t);

    // warunek musi być, bo odpowiedź nie może być nullem
    if (responseValidation) {
      responseValidation.responseObject.faceRecognized ? handleSend() : setFaceRecognizedModalOpen(true);
      modifyAccessStorage('photo')
    }

    setPhotoValidatingStatus(false);
  };

  const handleSend = async () => {
    setPhotoSendingStatus(true);
    const responseSave = await FetchService.sendPhotoToSave(photoBaseCropped);

    setPhotoSendingStatus(false);

    if (responseSave?.status === 'success') {
      handleResetActiveStep();
      FetchService.savePhotoStatus().then(response => {
        if (response) {
          if (response.status === 200) {

            successNotification(response.message)
          } else {
            errorNotification(response.message)
          }
        } else {
          errorNotification("Wystąpił nieoczekiwany problem podczas zmiany statusu zdjęcia")
        }
      })
    }
  };

  return (
    <>
      <Box
        component='section'
        sx={{ height: '500px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <img src={photoBaseCropped} alt='' style={{ height: '100%' }} />
      </Box>
      <StepButtons
        handlePreviousStep={onClickPreviousStep}
        handleEndAction={onClickEndAction}
        sendingStatus={photoValidatingStatus || photoSendingStatus}
      />
      <FaceRecognizedModal
        faceRecognizedModalOpen={faceRecognizedModalOpen}
        handleClose={() => setFaceRecognizedModalOpen(false)}
        handleSend={handleSend}
        sendingStatus={photoSendingStatus}
      />
    </>
  );
};

export default Summary;
