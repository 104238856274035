import * as React from "react";
import { Typography } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import 'react-phone-input-2/lib/style.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './style.css';



const Mobile = ({messagesData, setMessageAsRead, setMessageAsUnread}) => {
    return(
        <Grid item xs={12} lg={10}>
            
            {
                messagesData.map((value)=>{
                    return(
                        <Accordion key={value.id} sx={{textAlign: 'left',  whiteSpace: 'pre-line' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={() => {
                                    setMessageAsRead(value.id);
                                }}
                            >
                            <Typography variant="h6" className={value.isRead? '':'unread'}>{value.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography sx={{ marginTop: 2, }} align="left" variant="caption" display="block" gutterBottom>
                                {value.sendDate}
                            </Typography>
                            <Typography align="left" variant="body1" gutterBottom>
                                {value.description}  
                            </Typography>
                            </AccordionDetails>
                        </Accordion>)
                })}
        </Grid>
    );
}

    export default Mobile;