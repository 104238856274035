import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { useStyles } from '../css/Styles';
import { useTranslation } from 'react-i18next';

export default function DefaultErrorPage({message}) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        // TODO poprawa tej strony na docelową, skalowanie, tekst, logo itp.
        <div>
                <img src={`${process.env.PUBLIC_URL}/assets/logo.svg`} alt="logo" />
                <div className={classes.messageBox}>
                    <h1>{t(message)}</h1>
                </div>
            <SentimentVeryDissatisfiedIcon className={classes.sadIcon} />
        </div>
    )
}