import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import CustomRadioGroup from '../../templates/CustomRadioGroup';
import ErrorInfoGrid from '../../templates/ErrorInfoGrid';
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";
import resignationImage from "./resignationImage.png";
import logo from "./logo.svg";
import FetchService from './recruitmentResultsFetchService';
import logLevel from '../../utils/LoggerHelper';
import { errorNotification, createNotificationForResponse } from '../../utils/Notifications';

export default function ResignationModal({openModal, closeHandle, idCourse, candidateType, fieldOfStudy, applicationId, fetchRecruitmentResults, setLoadingCandidateCourses}) {
  let { id } = useParams();
  const { t } = useTranslation();
  const message = "Zrezygnowałeś z kierunku";
  const title = "Rezygnacja z kierunku"

    const documentsReceiptList = [
      createDocumentsReceiptData(1, t('recruitment.general_op1'), ['GENERAL', 'DOCTORAL'], null, null),
      createDocumentsReceiptData(2, t('recruitment.general_op2'), ['GENERAL'], t('recruitment.general_op2_link'), t('default.address_data')),
      createDocumentsReceiptData(3, t('recruitment.abroad_op1'), ['ABROAD'], null, null),
      createDocumentsReceiptData(1, t('recruitment.abroad_op2'), ['ABROAD'], null, null),
      createDocumentsReceiptData(2, t('recruitment.abroad_op3'), ['ABROAD'], t('recruitment.abroad_op3_link'), t('default.address_data')),
      createDocumentsReceiptData(2, t('recruitment.doctoral_op2'), ['DOCTORAL'], t('recruitment.doctoral_op2_link'), t('default.address_data')),
      ];
    const validationString = "Tej technologii już z nami nie stworzysz. Szkoda.";
    const [userValidationString, setUserValidationString] = React.useState("");
    const [documentsReceiptSelection, setDocumentsReceiptSelection] = React.useState(null);
    const [documentsReceipt, setDocumentsReceipt] = React.useState(documentsReceiptList)

    function createDocumentsReceiptData(value, label, candidateType, link, linkName ) {
      return { value, label, candidateType, link, linkName };
    }

    const [open, setOpen] = React.useState(openModal);
    const [formErrors, setFormErrors] = React.useState('')

    useEffect(
        ()=>{
            setOpen(openModal);
        }
    )

  useEffect(()=>{
    let documentsReceiptTemp = documentsReceiptList.filter((elem)=>Object.values(elem.candidateType).includes(candidateType));
    setDocumentsReceipt(documentsReceiptTemp);
  }, []);

    const validationFieldHandler = (event)=>{
      setUserValidationString(event.target.value);
    }

    const changeRadioGroupHandler = (event) => {
      setDocumentsReceiptSelection(event.target.value);
    }

    async function fetchQualificationStatus(applicationId, statusId, documentsReceiptSelection, id) {
      return await FetchService.setQualificationStatus(applicationId, statusId, documentsReceiptSelection, id)
    };
    async function fetchSendMessage(message, title) {
      return await FetchService.sendMessage(message, title)
    };


    const sendHandle = () => {
      
      let errors = [];
      setLoadingCandidateCourses(true);
      if(documentsReceiptSelection===null){
        errors.push({ id: 'recruitmentType', value: t('recruitment.select_option') });
      }

      if(validationString !== userValidationString){
        errors.push({ id: 'validationString', value: t('recruitment.text_to_write_action') });
      }

      if(errors.length === 0){
          // fetchQualificationStatus(applicationId, 'resignation', documentsReceiptSelection, idCourse)
          
          // .then
          closeHandle();
          setLoadingCandidateCourses(true);

          Promise.all([
            fetchQualificationStatus(applicationId, 'resignation', documentsReceiptSelection, idCourse),
            fetchSendMessage(message, title)
   
         ]).then(([responseStatus, responseMessage]) => {
            if (responseStatus && responseStatus.status === 200) {
                fetchRecruitmentResults(id);
            } else {
               createNotificationForResponse(responseStatus);
               setLoadingCandidateCourses(false);
            }
         }).catch(error => {
            setLoadingCandidateCourses(false);
            logLevel.debug("Błąd podczas odczytu danych, Error " + error);
            errorNotification('default.errorOnGet', ' ');
         });
          setDocumentsReceiptSelection(null);
          setFormErrors([]);
          setUserValidationString("");
        }else{
          setLoadingCandidateCourses(false);
          setFormErrors(errors);
        }


    }
    const closeModalHandle = ()=>{
      setDocumentsReceiptSelection(null);
      setFormErrors([]);
      setUserValidationString("");
      closeHandle();
    }


return (

    <Dialog
        open={open}
        onClose={closeModalHandle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Potwierdzenie rezygnacji z kierunku: { fieldOfStudy }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ mb: '2rem', width: "100%"}}>
           {t('recruitment.resignation_alert')} <br />
          </DialogContentText>
          <CustomRadioGroup
            name="recruitmentType"
            title={t("personal.cudzoziemiecRodzaj")}
            value={documentsReceiptSelection}
            radioData={documentsReceipt}
            disabledState={false}
            changeHandler={changeRadioGroupHandler}
            errors={formErrors}
        />
          <div style={{marginLeft: '2.5rem', marginBottom: '1rem', marginTop: '2rem', width: "calc(100% - 4.5rem)"}}>
            <Box
              sx={{
                width: 300,
                height: 200,
                mb: '1rem',
                backgroundImage:  `url(${resignationImage})`,
              }}
              className="resignationTextImage"
            >
              <img src={logo} className='resignationLogo'/>
              <span className='resignationText'>{validationString}</span>
            </Box>
            <span>{t('recruitment.text_to_write_action')}</span>
            <TextField name="validationString" value={userValidationString} onChange={validationFieldHandler} label="Wpisz właściwy tekst" id="outlined" variant="outlined" className={(formErrors && formErrors.find(formErrors => formErrors.id === "validationString")) ? 'validationStringInput validationStringInputError' : 'validationStringInput'}/>
            <ErrorInfoGrid errors={formErrors} fieldName="validationString"></ErrorInfoGrid>
          </div>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={closeModalHandle}>{t('default.cancel')}</Button>
          <Button variant="contained" onClick={()=>sendHandle()} autoFocus>{t('recruitment.resignate')}</Button>
        </DialogActions>
      </Dialog>
)

}