import * as logLevel from 'loglevel';

if (process.env.REACT_APP_STAGE === 'dev') {
    logLevel.setLevel(logLevel.levels.DEBUG);
} else if (process.env.REACT_APP_STAGE === 'test') {
    logLevel.setLevel(logLevel.levels.INFO);
} else {
    logLevel.setLevel(logLevel.levels.SILENT);
}


export default logLevel;