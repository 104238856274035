import { Grid, Typography, ToggleButtonGroup, ToggleButton, ThemeProvider } from '@material-ui/core';
import { useStyles, useTheme } from '../css/Styles';
import { useTranslation } from 'react-i18next';
import ErrorInfoGrid from '../templates/ErrorInfoGrid';


export default function CustomToggleButtonTrueFalseField({ title, label, value, disabledState=false, changeHandler, defaultValue, name, errors, inlineLabel=false}) {
   const classes = useStyles();
   const { t } = useTranslation();
   const theme = useTheme;

   return (
      <ThemeProvider theme = {theme}>
         <Grid item xs container = {inlineLabel} className={classes.daneOsoboweGridField}>
            <ToggleButtonGroup
                  className={(errors && errors.find(errors => errors.id === name)) ? classes.daneOsoboweToogleButtonFieldError : classes.daneOsoboweToogleButtonField} 
                  color='secondary'
                  value={value}
                  data-testid={name}
                  disabled={disabledState}
                  exclusive
                  onChange={changeHandler}
               >
               <ToggleButton disabled={disabledState} name={name} value={true}>{t('yes')}</ToggleButton>
               <ToggleButton disabled={disabledState} name={name} value={false}>{t('no')}</ToggleButton>
               </ToggleButtonGroup>
               <Grid item alignSelf={'center'}>
                  <Typography style={{overflowWrap: 'break-word', marginLeft:2}}>{label}</Typography>
               </Grid>
         <ErrorInfoGrid errors={errors} fieldName={name}></ErrorInfoGrid>   
         </Grid>
       </ThemeProvider>
   )
}