import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Grid, ThemeProvider, Typography, useMediaQuery,} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useStyles, useTheme} from "../../css/Styles";
import {NavLink, useLocation, useParams} from 'react-router-dom';
import {getCredentials} from '../../services/UserDataService';
import "react-phone-input-2/lib/style.css";
import MenuTile from "../../templates/MenuTile";
import CustomSimpleTable from "./CustomSimpleTable";
import CustomSecondaryTable from "./CustomSecondaryTable";
import "./style.css";
import LinearProgress from '@mui/material/LinearProgress';
import FetchService from './recruitmentResultsFetchService';
import {createNotificationForResponse, errorNotification, warningNotification} from '../../utils/Notifications';
import logLevel from '../../utils/LoggerHelper';
import {PagesEnums} from '../../utils/CustomEnums';
import {ACTIONS} from "../../utils/Consts";
import FileUploadComponent from "../../templates/FileUploadComponent";
import BackAndSaveButtons from "../../templates/BackAndSaveButtons";
import DialogComponent from "../../templates/DialogComponent";
import {modifyAccessStorage} from "../../utils/AppUtils";


function filesReducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_INITIAL_FILES: {
            let tempState = action.payload.map(payload => {
                let data = { ...payload }
                data.inputRefs = action.inputRefs;
                return data;
            })

            return tempState;
        }
        case ACTIONS.SET_FILE_DATA: {
            let changedFile = state
                .filter(file => file.attachmentInfo.typeId === action.payload.typeId)
                .map(file => {
                    file.attachmentMetadataList = [].concat({ fileName: action.payload.name, size: action.payload.size });
                    file.data = action.payload.data
                    file.dataType = action.payload.dataType
                    return file;
                })

            let notChangedFile = state.filter(file => file.attachmentInfo.typeId !== action.payload.typeId)


            // return state = notChangedFile.concat(changedFile).sort((a, b) => a.attachmentInfo.typeId - b.attachmentInfo.typeId);
            return state = notChangedFile.concat(changedFile)

        }
        default:
            throw new Error();
    }
}

export default function RecruitmentResults() {
    const location = useLocation();
    const { id, candidateType } = useParams();
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme;
    const [mobile, setMobile] = React.useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
    const [loadingCandidateCourses, setLoadingCandidateCourses] = React.useState(false)
    const [filesInfo, dispatchFilesInfo] = React.useReducer(filesReducer, [])
    const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(false)
    const [abroadSelection, setAbroadSelection] = useState(0);
    const [qualification, setQualification] = useState(false);

    const [studyDegree, setStudyDegree] = useState(0);
    const inputRefs = useRef([])
    const [exams, setExams] = React.useState({
        artisticTalentsArchitectureExamResult: "",
        artisticTalentsDesignExamResult: "",
        englishLanguageExamResult: "",
        polishLanguageExamResult: ""
    });


    const [isDialogSaveOpen, setIsDialogSaveOpen] = React.useState(false)


    function createStatusData(id, name, buttonType) {
        return { id, name, buttonType };
    }

    const handleOpenSaveConfirmDialog = () => {
        if (handleValidation()) {
            setIsDialogSaveOpen(true)
        } else {
            warningNotification("personal.formValidationError", " ");
        }
    }

    const handleValidation = () => {
        let errors = [];
        let formIsValid = true;

        //setFormErrors(errors)
        return formIsValid;
    }

    const handleSave = () => {
        setSaveButtonDisabled(true)
        handleCloseSaveConfirmDialog()
        if (handleValidation()) {
            saveFilesToDatabase()
        } else {
            warningNotification("personal.formValidationError", " ");
            setSaveButtonDisabled(false)
        }
        setSaveButtonDisabled(false)
    }

    const handleCloseSaveConfirmDialog = () => {
        setIsDialogSaveOpen(false)
    }

    const saveFilesToDatabase = () => {
        const request = []
        filesInfo.filter(file => file.data)
            .forEach(file => {
                request.push({
                    'data': file.data,
                    'metadata': {
                        'fileName': file.attachmentMetadataList[0].fileName,
                        'size': file.attachmentMetadataList[0].size,
                        'type': file.attachmentInfo.typeId
                    }
                })
            })
        if (request.length > 0) {
            saveFiles(request).then(response => {
                if (response) {
                    createNotificationForResponse(response);
                }
                modifyAccessStorage('personalData')
                setSaveButtonDisabled(false)
            }).catch(error => {
                logLevel.debug("Błąd podczas zapisu danych personalnych.", error);
                errorNotification('default.errorOnSave', ' ');
                setSaveButtonDisabled(false)
            })
        }
    }

    async function saveFiles(request) {
        return await FetchService.saveFiles(request);
    }

    const [certificateType, setCertificateType] = useState();

    const [graduationPoints, setGraduationPoints] = React.useState([]);

    function createGraduationPointsData(data) {

        let qualificationPointsTable = data.qualificationPointsTable ? data.qualificationPointsTable : [];

        let qualificationTechnicalSchoolPointsTable = data.qualificationTechnicalSchoolPointsTable ? data.qualificationTechnicalSchoolPointsTable : [];
        let mergeData = qualificationPointsTable.concat(qualificationTechnicalSchoolPointsTable);

        return mergeData.map(elem => {

            if (elem.grade || elem.level) {

                if (elem.level === 'P') {
                    elem.level = 'basicLevel';
                } else if (elem.level === 'R') {
                    elem.level = 'advancedLevel';
                } else if (elem.level === 'D') {
                    elem.level = 'duo';
                } else if (elem.level === 'graduation') {
                    elem.level = 'graduationCert';
                } else if (elem.level === 'mature') {
                    elem.level = 'maturityCert';
                }

                elem.qualificationTechnicalSchoolGrades = [{ "grade": elem.grade, "level": elem.level }];
            }
            let rowspan = elem.qualificationTechnicalSchoolGrades ? elem.qualificationTechnicalSchoolGrades.length : 1;
            return createGraduationPointsRow(elem.subject, elem.qualificationTechnicalSchoolGrades, elem.score, rowspan)
        });
    }

    const filesType = 'QUALIFICATION_DATA'

    async function fetchFilesInfo(modeKey = 'etap_1') {
        return await FetchService.getFilesInfo(modeKey, filesType);
    }

    async function fetchGetRecruitmentMode(recruitmentModeKey) {
        return await FetchService.getRecruitmentMode(recruitmentModeKey)
    }

    async function fetchPersonalData() {
        return await FetchService.getAddressPersonal()
    }

    function createGraduationPointsRow(subject, qualificationTechnicalSchoolGrades, pointsPL, rowspan) {
        return { subject, qualificationTechnicalSchoolGrades, pointsPL, rowspan };
    }

    const [recruitmentResults, setRecruitmentResults] = React.useState([]);

    function createRecruitmentResultsData(data) {
        return data.map(elem => createRecruitmentResultsRow(elem.courseId, elem.course, elem.lang, elem.points, elem.status, elem.applicationId));
    }

    function createRecruitmentResultsRow(id, fieldOfStudy, lang, points, reqruitmentStatus, applicationId) {
        let statusData = "";
        if (reqruitmentStatus) {
            let buttonType = null
            if (reqruitmentStatus.qualified === true) {
                buttonType = 1;
            } else if (reqruitmentStatus.admitted === true) {
                buttonType = 2;
            }
            statusData = { name: t('recruitment.status_enums.' + reqruitmentStatus.statusEnum), buttonType };
        }

        return { id, fieldOfStudy, lang, points, statusData, applicationId };
    }

    const fetchRecruitmentResults = async (recruitmentModeKey) => {
        setLoadingCandidateCourses(true)
        await FetchService.getRecruitmentResults(recruitmentModeKey).then(response => {
            if (response && response.zawartosc) {
                setRecruitmentResults(createRecruitmentResultsData(response.zawartosc.qualificationResultTable ? response.zawartosc.qualificationResultTable : []));
                setGraduationPoints(createGraduationPointsData(response.zawartosc));
                setCertificateType(response.zawartosc.certificate);
                setExams({
                    artisticTalentsArchitectureExamResult: response.zawartosc.artisticTalentsArchitectureExamResult,
                    artisticTalentsDesignExamResult: response.zawartosc.artisticTalentsDesignExamResult,
                    englishLanguageExamResult: response.zawartosc.englishLanguageExamResult,
                    polishLanguageExamResult: response.zawartosc.polishLanguageExamResult
                });
                if (response.zawartosc.qualificationResultTable) {
                    if (response.zawartosc.qualificationResultTable.some(
                        (obj) => (obj?.status?.qualified == true)
                    )) {
                        setQualification(true)
                    }
                }


                setLoadingCandidateCourses(false)
            } else {
                createNotificationForResponse(response);
                setLoadingCandidateCourses(false)
            }
        }).catch(error => {
            logLevel.debug("Błąd podczas odczytu danych, Error " + error);
            errorNotification('default.errorOnGet', ' ');
        })
    }

    useEffect(() => {
        /*
        fetchGetRecruitmentMode(id).then(response => {
            if (response.zawartosc[0].key == id) {
                setStudyDegree(response.zawartosc[0].degree);
            } else {
                setStudyDegree(0);
            }
        })

        if (!abroadSelection) {
            fetchPersonalData().then(response => {
                abroadSelection = response?.zawartosc.userPersonalData.selectionId
            })
        }
        */
        fetchRecruitmentResults(id);

        fetchPersonalData().then(response => {
            setAbroadSelection(response?.zawartosc.userPersonalData.selectionId)
        })

        fetchFilesInfo().then(response => {
            let tempInputRefs = response.zawartosc?.map((element, index) => {
                return inputRefs.current[index] = React.createRef();
            })
            dispatchFilesInfo({ type: ACTIONS.SET_INITIAL_FILES, payload: response.zawartosc, inputRefs: tempInputRefs })
        })
    }, [])

    const mql = window.matchMedia("(max-width: 1100px)");
    let mobileView = mql.matches;

    mql.addEventListener("change", (e) => {
        const mobileView = e.matches;
        if (mobileView) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    });

    return (<div className="recruitment-results-wrapper">

        <DialogComponent
            title={t('default.confirmSave')}
            content={t('qualification.confirmSave')}
            visible={isDialogSaveOpen}
            buttons={[
                { buttonLabel: t('default.cancel'), buttonAction: () => handleCloseSaveConfirmDialog() },
                { buttonLabel: t('yes'), buttonAction: () => handleSave() }
            ]}
        />

        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: isMobile ? "center" : "flex-start",
                    flexDirection: isMobile ? "column" : "row",
                }}
            >
                <Grid sx={{ padding: "2.5vh" }} display="flex" justifyItems="end">
                    <MenuTile title={t('main.qualification')} locked={false} />
                </Grid>

                {loadingCandidateCourses ?
                    <Grid container style={{ width: '100%', marginTop: '50px' }} alignItems='center'
                        justifyContent='center'>
                        <LinearProgress sx={{ width: '80%' }} color="secondary" />
                    </Grid> :

                    <Grid
                        sx={{ padding: isMobile ? "1vh" : "3vh" }}
                        container
                        direction={"row"}
                        justifyContent="space-around"
                        alignItems="flex-start"
                    >
                        <Grid
                            style={{ width: "100%" }}
                            container
                            direction={isMobile ? "column" : "row"}
                            justifyContent="flex-start"
                        >
                            <Grid
                                container
                                style={{ width: isMobile ? "100%" : "70%" }}
                                justifyContent="space-between"
                            >


                                <Grid item style={{ width: "100%" }}>

                                    <Typography
                                        sx={{ marginBottom: 2, marginTop: 4, width: "100%" }}
                                        align="left"
                                        variant="h2"
                                        gutterBottom
                                        fontWeight="bold"
                                    >
                                        {t('recruitment.results')}
                                    </Typography>
                                    <Typography
                                        sx={{ mb: '2rem', width: "100%" }}
                                        align="left"
                                        gutterBottom

                                    >
                                        {t('recruitment.application_printout')}
                                        <br />
                                        {t('recruitment.not_submitting_documents')}
                                    </Typography>

                                    <CustomSecondaryTable
                                        tableData={recruitmentResults}
                                        candidateType={candidateType}
                                        recruitmentModeKey={id}
                                        fetchRecruitmentResults={fetchRecruitmentResults}
                                        setLoadingCandidateCourses={setLoadingCandidateCourses}
                                    />

                                    <Grid style={{ margin: "20px" }}>
                                        {abroadSelection > 0 && qualification ?
                                            (filesInfo?.map((element, index) => {
                                                if (element.attachmentInfo.typeId == 58) {
                                                    return (<React.Fragment key={element.attachmentInfo.typeId}>
                                                        {FileUploadComponent(index, element, dispatchFilesInfo)}
                                                    </React.Fragment>)
                                                }
                                            })) :
                                            null}
                                    </Grid>

                                </Grid>

                                <Grid item style={{ width: "100%" }}>

                                    {(exams.polishLanguageExamResult || exams.englishLanguageExamResult || exams.artisticTalentsArchitectureExamResult || exams.artisticTalentsDesignExamResult) &&
                                        <Typography
                                            sx={{ marginBottom: 2, marginTop: 4, width: "100%" }}
                                            align="left"
                                            variant="h2"
                                            fontWeight="bold"
                                            gutterBottom
                                        >
                                            {t('recruitment.exam')}
                                        </Typography>}

                                    <ul align="left">
                                        {exams.polishLanguageExamResult &&
                                            <li style={{
                                                display: 'flex',
                                                alignItems: 'left'
                                            }}>{t('recruitment.exam_pl')} {exams.polishLanguageExamResult}</li>}
                                        {exams.englishLanguageExamResult &&
                                            <li style={{
                                                display: 'flex',
                                                alignItems: 'left'
                                            }}>{t('recruitment.exam_en')} {exams.englishLanguageExamResult}</li>}
                                        {exams.artisticTalentsArchitectureExamResult &&
                                            <li style={{
                                                display: 'flex',
                                                alignItems: 'left'
                                            }}>{t('recruitment.art_architecture')} {exams.artisticTalentsArchitectureExamResult}</li>}
                                        {exams.artisticTalentsDesignExamResult &&
                                            <li style={{
                                                display: 'flex',
                                                alignItems: 'left'
                                            }}>{t('recruitment.art_design')} {exams.artisticTalentsDesignExamResult}</li>}
                                    </ul>
                                </Grid>

                                <Grid item style={{ width: "100%" }}>
                                    <Typography
                                        sx={{ marginBottom: 2, marginTop: 4 }}
                                        align="left"
                                        variant="h2"
                                        gutterBottom
                                        fontWeight="bold"
                                    >
                                        {(id == "konkurs_D_L" || id == "konkursD" || id == "zagranica_st_II_II_tura" || id == "zagranica_st_I_II_tura" || id == "zagranica_st_2") ? t('recruitment.graduation_points_2sec') : t('recruitment.graduation_points_1st')} {/*TODO make something that makes sense here.*/}
                                    </Typography>

                                    <CustomSimpleTable tableData={graduationPoints} certificateType={certificateType} />

                                    <Typography
                                        sx={{ mt: '1rem', width: "100%" }}
                                        align="left"
                                        gutterBottom
                                    >
                                        {t('recruitment.incorrectly_calculated_points')}
                                    </Typography>

                                    <Typography variant="body1" align="left">
                                        <ul>
                                            <li>{t('recruitment.candidates_with_citizenship')}</li>
                                            <li>{t('recruitment.candidates_without_citizenship')}</li>
                                        </ul>
                                    </Typography>
                                </Grid>


                            </Grid>
                        </Grid>
                        <Grid container style={{ width: '100%' }} justifyContent={isMobile ? 'center' : "flex-end"}
                            alignItems='flex-end'>
                            {abroadSelection > 0 && qualification ?
                                <BackAndSaveButtons saveButtonFunction={handleOpenSaveConfirmDialog} />
                                :
                                <Button
                                    sx={{
                                        fontWeight: 'bold',
                                        marginLeft: 1,
                                        marginTop: 1,
                                        marginBottom: 1,
                                        height: '3vh',
                                        minHeight: '40px',
                                        color: theme.palette.primary.main,
                                        backgroundColor: theme.palette.secondary.main,
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.hovered,
                                            borderColor: 'none',
                                            boxShadow: 'none',
                                        },
                                    }}
                                    component={NavLink}
                                    to={getCredentials() ? PagesEnums.MAIN.link : PagesEnums.HOME.link}
                                    variant='contained'>
                                    {t('backButton')}
                                </Button>
                            }
                        </Grid>
                    </Grid>}
            </Box>
        </ThemeProvider>
    </div>);
}

