import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ButtonModal } from './style';

const FaceRecognizedModal = ({ faceRecognizedModalOpen, handleClose, handleSend, sendingStatus }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={faceRecognizedModalOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{t('cropPhoto.sendPhotoNotification.faceRecognizedFailTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {t('cropPhoto.sendPhotoNotification.faceRecognizedFailDescription')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonModal onClick={handleClose} color='primary' disabled={sendingStatus}>
          {t('cropPhoto.sendPhotoNotification.faceRecognizedFailCancel')}
        </ButtonModal>
        <ButtonModal onClick={handleSend} color='primary' autoFocus disabled={sendingStatus}>
          {t('cropPhoto.sendPhotoNotification.faceRecognizedFailAccept')}
        </ButtonModal>
      </DialogActions>
    </Dialog>
  );
};

export default FaceRecognizedModal;
