import Cookies from 'js-cookie';
import {cookieDomain} from '../utils/Consts';
import logLevel from '../utils/LoggerHelper';
import {SessionTypesEnums} from '../utils/CustomEnums';

export const save = (key, value, sessionTypes) => {
    try {
        switch (sessionTypes) {
            case SessionTypesEnums.LOCAL_STORAGE:
                localStorage.setItem(key, value);
                break;
            case SessionTypesEnums.SESSION_STORAGE:
                sessionStorage.setItem(key, value);
                break;
            case SessionTypesEnums.COOKIE:
                Cookies.set(key, value, {path: '/', domain: cookieDomain, sameSite: 'strict'});
                break;
            default:
                sessionStorage.setItem(key, value);
                break;
        }
    } catch (error) {
        console.warn("An error occurred:", error.message);
        try {

            switch (sessionTypes) {
                case SessionTypesEnums.LOCAL_STORAGE:
                    localStorage.removeItem(key);
                    break;
                case SessionTypesEnums.SESSION_STORAGE:
                    sessionStorage.removeItem(key);
                    break;
                case SessionTypesEnums.COOKIE:
                    Cookies.removeItem(key);
                    break;
                default:
                    sessionStorage.removeItem(key);
                    break;
            }

        } catch (error) {
        }
    }
}
export const get = (key, sessionTypes) => {
    try {
        switch (sessionTypes) {
            case SessionTypesEnums.LOCAL_STORAGE:
                return localStorage.getItem(key);
            case SessionTypesEnums.SESSION_STORAGE:
                return sessionStorage.getItem(key);
            case SessionTypesEnums.COOKIE:
                return Cookies.get(key)
            default:
                return null;
        }
    } catch (error) {
        console.warn("An error occurred:", error.message);
        return null;
    }
}

export const clear = async () => {
    sessionStorage.clear();
    Cookies.remove('token', {path: '/', domain: cookieDomain});
    Cookies.remove('refreshToken', {path: '/', domain: cookieDomain});
}

export const refreshTokenStorage = (data) => {
    clear();
    save('token', data.token, SessionTypesEnums.COOKIE);
    save('refreshToken', data.refreshToken, SessionTypesEnums.COOKIE);
    logLevel.debug("Token został odświeżony");
}