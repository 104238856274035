import * as React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useTranslation} from "react-i18next";

export default function CustomSimpleTable({tableData, certificateType}) {

    const {t} = useTranslation();


    let levelHeader = certificateType === 'OLD' ? 'examType' : 'level';

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead className="blue-header">
                    <TableRow>
                        <TableCell>{t('recruitment.subject')}</TableCell>
                        <TableCell align="center">{t('recruitment.candidate_points')}</TableCell>
                        <TableCell align="center">{t('recruitment.' + levelHeader)}</TableCell>
                        <TableCell align="center">{t('recruitment.pl_points')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row, index) => {
                        return (
                            <TableRow
                                key={row.subject + index}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row" rowSpan={row.rowspan}>
                                    {row.subject ? t(row.subject) : '---'}
                                </TableCell>

                                <TableCell align="center">
                                    {row.qualificationTechnicalSchoolGrades && row.qualificationTechnicalSchoolGrades.map((grade, index) => {
                                        return (
                                            <TableRow key={row.subject + grade.grade + index}
                                                      sx={{textAlign: "center", width: "100%", display: "block"}}>
                                                <TableCell sx={{
                                                    textAlign: "center",
                                                    width: "100%",
                                                    display: "block",
                                                    borderBottom: "0"
                                                }}>
                                                    {grade.level ? t(grade.grade) : '---'}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableCell>


                                <TableCell align="center">
                                    {row.qualificationTechnicalSchoolGrades && row.qualificationTechnicalSchoolGrades.map((grade, index) => {

                                        return (
                                            <TableRow sx={{textAlign: "center", width: "100%", display: "block"}}
                                                      key={row.subject + grade.level + index}>
                                                <TableCell sx={{
                                                    textAlign: "center",
                                                    width: "100%",
                                                    display: "block",
                                                    borderBottom: "0"
                                                }}>
                                                    {grade.level ? t(grade.level) : '---'}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableCell>
                                <TableCell align="center" rowSpan={row.rowspan}>
                                    {row.pointsPL ? row.pointsPL : '---'}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}