import { SessionTypesEnums } from '../utils/CustomEnums';

export const apiGatewayUrl = process.env.REACT_APP_API_GATEWAY_URL ? process.env.REACT_APP_API_GATEWAY_URL : 'https://e-rekrutacja-gateway.test.p.lodz.pl/';
export const SIDapiGatewayUrl = process.env.REACT_APP_SID_API_GATEWAY_URL ? process.env.REACT_APP_SID_API_GATEWAY_URL : 'https://gateway.test.p.lodz.pl/';

// TODO - do zmiany na docelową domenę
export const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN_URL ? process.env.REACT_APP_COOKIE_DOMAIN_URL : 'e-rekrutacja2.p.lodz.pl';

export const HTTP_POST = 'POST';
export const HTTP_GET = 'GET';
export const HTTP_PUT = 'PUT';
export const HTTP_DELETE = 'DELETE';
export const HTTP_PATCH = 'PATCH';

// Ile sekund przed wygaśnięciem tokenu JWT ma pojawić się informacja o wygasaniu sesji
export const secondsBeforeJwtExpire = 180;



export const DICTIONARY_CACHE_NAME = 'dictionary';
//prefix dla wartosci slownikowych zapisywanych w storage
export const DICTIONARY_STORAGE_PREFIX = 'dict_'
export const DICTIONARY_STORAGE_TYPE = SessionTypesEnums.LOCAL_STORAGE


export const CANDIDATE_PREFIX = 'k'
export const CANDIDATE_ISD_PREFIX = 'ki'

export const ACTIONS = {
   SET_INITIAL_FILES: 'setInitialFiles',
   SET_FILE_DATA: 'setFileData',
   SET_CERTIFICATE_FILE: 'setCertificateFile',
   SET_OCCUPATIONAL_DIPLOMA_FILE: 'setOccupationalDiplomaFile',
   SET_OLYMPIC_FILE: 'setOlympicFile',
   SET_APOSTILLE_FILE: 'setApostilleFile',
   SET_TRANSLATION_FILE: 'setTranslationFile',
   SET_COLLAGE_APPROVAL_FILE: 'setCollageApprovalFile',
   SET_CURATOR_FILE: 'setCuratorFile',
   SET_EXAM_PAYMENT_FILE: 'setExamPaymentFile',
   SET_APPLICATION_FILE: 'setApplicationFile',
   SET_ACHIEVEMENTS_FILE: 'setAchievementsFile',
   SET_LANGUAGE_CERTIFICATE_FILE: 'setLanguageCertificateFile',
   SET_VISA_FILE: 'setVisaFile',
   SET_PASSPORT_FILE: 'setPassportFile',
   SET_ANNEXES_FILE: 'setAnnexesFile'
}
export const artisticCourses = [ // TODO and this????
   82,
   85,
   1168,
   1169,
   1520,
   1676,
   4294,
   4326,
   4297,
   4141,
   4246,
   4579,
   4830,
   4827,
   4829,
   5185,
   5618,
   5632,
   5631,
   5827,
   5811,
   6057,
   6075,
   6302,
   6291,
   6372,
   6344,
   6647,
   6959,
   6958,
   6927,
   6906,
]