import React, {useContext, useEffect, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {getCredentials, hasActiveRole} from '../services/UserDataService';
import {PagesEnums} from "../utils/CustomEnums";

import {DatesContext} from "./GlobalDatesContext";

export const PrivateRoute = ({roles, path, children}) => {
    //accessContext
    const datesContext = useContext(DatesContext)
    const {id} = useParams();
    const [isDateValid, setDateValid] = useState(true);


    useEffect(() => {
        checkDate();
    }, []);

    async function checkDate() {
        let currentDate = new Date(datesContext.dates.currentDateTime);
        let launchDate = new Date(datesContext.dates.generalDates.portalLaunch);
        let closureDate = new Date(datesContext.dates.generalDates.portalClosure);

        if (currentDate < launchDate || currentDate > closureDate) {
            setDateValid(false);
            return;
        }

        if (path && id) {
            datesContext.fetchGetMoreDates(id).then(result => {
                switch (path) {
                    default:
                        setDateValid(true);
                        break;
                    // case PagesEnums.RECRUITMENT_RESULTS.link: 
                    //     let documentVerificationDate = new Date(result[id].documentVerification);
                    //     setDateValid(documentVerificationDate < currentDate);
                    //     break;
                }
            });
        } else {
            setDateValid(true);
        }
    }

    if (isDateValid) {
        return getCredentials() !== "" && checkRoles(roles) ? children : <Navigate to={PagesEnums.ACCESS_DENIED.link} />;
    } else {
        return <Navigate to={PagesEnums.SERVICE_DISABLED.link} />;
    }
};

const checkRoles = (roles) => {
    let hasRole = false;
    if (roles) {
        roles.forEach(role => {
            if (hasActiveRole(role)) {
                hasRole = true;
            }
        });
    }
    return hasRole;
};