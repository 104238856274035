import DialogContent from '@mui/material/DialogContent';
import {Box, IconButton, Link, ThemeProvider, Typography} from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import i18n from "i18next";
import i18next from "i18next";
import {useTheme} from "../../css/Styles";

const theme = useTheme;

theme.typography.popupTitle = {
    fontSize: '1rem',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
    },
};

theme.typography.popupContent = {
    fontSize: '0.8rem',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
    },
};


export function popupReducer(state, action) {

    switch (action.type) {
        case 'CLOSE':
            return null;
            break;
        case 'OPEN':
            switch (action.payload) {
                case 'MAIN.RULES':
                    return ({
                        title: <></>
                        , content:
                            <Grid container direction='column' alignItems="flex-start">
                                <Typography color={theme.palette.secondary.main}
                                            variant='popupContent'>
                                    I {i18n.t('popup.degree')}
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/pl/przelicznik-punktow" : "https://rekrutacja.p.lodz.pl/en/grades-required-entry"}
                                            target="_blank"
                                            color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/pl/przelicznik-punktow" : "https://rekrutacja.p.lodz.pl/en/grades-required-entry"}
                                </Typography>
                                <Typography align="center" color={theme.palette.secondary.main} variant='popupContent'>
                                    II {i18n.t('popup.degree')}
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/pl/zasady-kwalifikacji" : "https://rekrutacja.p.lodz.pl/en/qualifications-masters-degree-studies"}
                                            target="_blank"
                                            color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/pl/zasady-kwalifikacji" : "https://rekrutacja.p.lodz.pl/en/qualifications-masters-degree-studies"}
                                </Typography>
                                <Typography align="center" color={theme.palette.secondary.main} variant='popupContent'>
                                    III {i18n.t('popup.degree')}
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/zasady-rekrutacji-20222023" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023"}
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/zasady-rekrutacji-20222023" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023"}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 'NEW' + "certificateNumber":
                    return ({
                        title:
                            <Typography align="center" color={theme.palette.secondary.main} fontWeight='bold'
                                        variant='popupTitle'>
                            </Typography>,
                        content:
                            <img alt='I st NM numer swiadectwa'
                                 src={process.env.PUBLIC_URL + '/IstNMnumerswiadectwa.png'}
                                 style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                        , isOpen: true
                    })
                    break;
                case 'OLD' + "certificateNumber":
                    return ({
                        title:
                            <Typography align="center" color={theme.palette.secondary.main} fontWeight='bold'
                                        variant='popupTitle'>
                            </Typography>,
                        content:
                            <img alt='I st SM numer swiadectwa'
                                 src={process.env.PUBLIC_URL + '/I_st_SM_numer_swiadectwa.png'}
                                 style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                        , isOpen: true
                    })
                    break;
                case 'IB' + "certificateNumber":
                    return ({
                        title:
                            <Typography align="center" color={theme.palette.secondary.main} fontWeight='bold'
                                        variant='popupTitle'>
                            </Typography>,
                        content:
                            <img alt='I st IB numer swiadectwa'
                                 src={process.env.PUBLIC_URL + '/I_st_IB_numer_swiadectwa.jpg'}
                                 style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                        , isOpen: true
                    })
                    break;
                case 'EB' + "certificateNumber":
                    return ({
                        title:
                            <Typography align="center" color={theme.palette.secondary.main} fontWeight='bold'
                                        variant='popupTitle'>
                            </Typography>,
                        content:
                            <img alt='I st EB numer swiadectwa'
                                 src={process.env.PUBLIC_URL + '/I_st_EB_numer_swiadectwa.jpg'}
                                 style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                        , isOpen: true
                    })
                    break;
                case 'NEW' + "certificateFile":
                    return ({
                        title:
                            <Typography align="center" color={theme.palette.secondary.main} fontWeight='bold'
                                        variant='popupTitle'>
                            </Typography>,
                        content:
                            <img alt='I st NM plik swiadectwa'
                                 src={process.env.PUBLIC_URL + '/I_st_NM_plik_swiadectwo.png'}
                                 style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                        , isOpen: true
                    })
                    break;
                case 'OLD' + "certificateFile":
                    return ({
                        title:
                            <Typography align="center" color={theme.palette.secondary.main} fontWeight='bold'
                                        variant='popupTitle'>
                            </Typography>,
                        content:
                            <img alt='I st SM plik swiadectwo'
                                 src={process.env.PUBLIC_URL + '/I_st_SM_plik_swiadectwo.jpg'}
                                 style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                        , isOpen: true
                    })
                    break;
                case 'IB' + "certificateFile":
                    return ({
                        title:
                            <Typography align="center" color={theme.palette.secondary.main} fontWeight='bold'
                                        variant='popupTitle'>
                            </Typography>,
                        content:
                            <img alt='I st IB plik swiadectwo'
                                 src={process.env.PUBLIC_URL + '/I_st_IB_plik_swiadectwo.png'}
                                 style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                        , isOpen: true
                    })
                    break;
                case 'EB' + "certificateFile":
                    return ({
                        title:
                            <Typography align="center" color={theme.palette.secondary.main} fontWeight='bold'
                                        variant='popupTitle'>
                            </Typography>,
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.learnMore')}
                                    </Typography>
                                    <Typography component={Link}
                                                href="https://www.eursc.eu/en/European-Schools/European-Baccalaureate"
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        https://www.eursc.eu/en/European-Schools/European-Baccalaureate
                                    </Typography>
                                </Grid>
                                <img alt='I st EB plik swiadectwo'
                                     src={process.env.PUBLIC_URL + '/I_st_EB_plik_swiadectwo.png'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>

                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 'OTHER' + "certificateFile":
                    return ({
                        title:
                            <></>,
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                        {i18n.t('popup.schemas')}
                                    </Typography>
                                    <Typography component={Link} href="https://kwalifikator.nawa.gov.pl/"
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        https://kwalifikator.nawa.gov.pl/
                                    </Typography>
                                </Grid>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "otherExtraFile1":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>

                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.Apostille')}
                                </Typography>
                                <Typography component={Link} href="https://www.hcch.net/en/instruments/status-table" v
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                    https://www.hcch.net/en/instruments/status-table
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.legalisation')}
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://www.gov.pl/web/dyplomacja/polskie-przedstawicielstwa-na-swiecie" : "https://www.gov.pl/web/diplomacy/certification-of-documents"}
                                            v target="_blank" color={theme.palette.secondary.main}
                                            variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://www.gov.pl/web/dyplomacja/polskie-przedstawicielstwa-na-swiecie" : "https://www.gov.pl/web/diplomacy/certification-of-documents"}

                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "otherExtraFile2":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                        {i18n.t('popup.translationNeeded')}
                                    </Typography>
                                    <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                        {i18n.t('popup.listTranslators')}
                                    </Typography>
                                    <Typography component={Link}
                                                href=
                                                    {i18n.language == 'pl' ? "https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html" : "https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html"}
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        {i18n.language == 'pl' ? "https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html" : "https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "otherExtraFile3":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                        {i18n.t('popup.nawa')}
                                    </Typography>
                                    <Typography component={Link} href="https://kwalifikator.nawa.gov.pl" target="_blank"
                                                color={theme.palette.secondary.main} variant='popupContent'>
                                        https://kwalifikator.nawa.gov.pl
                                    </Typography>
                                </Grid>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "otherExtraFile4":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                        {i18n.t('popup.generalInfo')}
                                    </Typography>
                                    <Typography component={Link}
                                                href="https://www.gov.pl/web/edukacja-i-nauka/uznanie-lub-potwierdzenie-wyksztalcenia-decyzja-kuratora-oswiaty"
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        https://www.gov.pl/web/edukacja-i-nauka/uznanie-lub-potwierdzenie-wyksztalcenia-decyzja-kuratora-oswiaty
                                    </Typography>
                                    <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                        {i18n.t('popup.list')}
                                    </Typography>
                                    <Typography component={Link}
                                                href=" https://www.gov.pl/web/edukacja-i-nauka/kuratoria-oswiaty"
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        https://www.gov.pl/web/edukacja-i-nauka/kuratoria-oswiaty
                                    </Typography>
                                </Grid>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "schoolData.certificate":
                    return ({
                        content:
                            <img alt='I st NM plik swiadectwo'
                                 src={process.env.PUBLIC_URL + '/I_st_NM_plik_swiadectwo.png'}
                                 style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                        , isOpen: true
                    })
                    break;
                case "anex":
                    return ({
                        content:
                            <img alt='I st NM numer aneks' src={process.env.PUBLIC_URL + '/I_st_NM_numer_aneks.png'}
                                 style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                        , isOpen: true
                    })
                    break;
                case "dyplomTechnik":
                    return ({
                        content:
                            <img alt='I st NM plik dyplom technik'
                                 src={process.env.PUBLIC_URL + '/I_st_NM_plik_dyplom_technik.png'}
                                 style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                        , isOpen: true
                    })
                    break;
                case "anexFile":
                    return ({
                        content:
                            <img alt='I_st_NM_plik_aneks.png' src={process.env.PUBLIC_URL + '/I_st_NM_plik_aneks.png'}
                                 style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                        , isOpen: true
                    })
                    break;
                case "gradeScale":
                    return ({
                        title:
                            <Typography align="center" color={theme.palette.secondary.main} fontWeight='bold'
                                        variant='popupTitle'>
                            </Typography>,
                        content:
                            <img alt='I st SM skala ocen' src={process.env.PUBLIC_URL + '/I_st_SM_skala_ocen.png'}
                                 style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                        , isOpen: true
                    })
                    break;
                case 54:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.languageDocumentRequirement')}
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/en/language-requirements " : "https://rekrutacja.p.lodz.pl/en/language-requirements"}
                                            v
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/en/language-requirements " : "https://rekrutacja.p.lodz.pl/en/language-requirements"}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.languagePlExamRequirement')}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 55:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.languageDocumentRequirement')}
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/en/language-requirements " : "https://rekrutacja.p.lodz.pl/en/language-requirements"}
                                            v
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/en/language-requirements " : "https://rekrutacja.p.lodz.pl/en/language-requirements"}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.languageEnExamRequirement')}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "dokumentacja":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/pl/konkurs-na-bezplatne-miejsca" : "https://rekrutacja.p.lodz.pl/en/contest-study-free"}
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>

                                    {i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/pl/konkurs-na-bezplatne-miejsca" : "https://rekrutacja.p.lodz.pl/en/contest-study-free"}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "podanie":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/pl/konkurs-na-bezplatne-miejsca" : "https://rekrutacja.p.lodz.pl/en/contest-study-free"}
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>

                                    {i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/pl/konkurs-na-bezplatne-miejsca" : "https://rekrutacja.p.lodz.pl/en/contest-study-free"}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "payment":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.bank1')}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.bank2')}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.bank3')}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.bank4')}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.bank5')}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "laureate":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.olimpiads')}
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/pl/olimpiady-konkursy" : "https://rekrutacja.p.lodz.pl/pl/olimpiady-konkursy"}
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/pl/olimpiady-konkursy" : "https://rekrutacja.p.lodz.pl/pl/olimpiady-konkursy"}


                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 56:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.languageDocumentRequirement')}
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/en/language-requirements " : "https://rekrutacja.p.lodz.pl/en/language-requirements"}
                                            v
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/en/language-requirements " : "https://rekrutacja.p.lodz.pl/en/language-requirements"}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.languagePlExamRequirement')}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 57 :
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.languageDocumentRequirement')}
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/en/language-requirements " : "https://rekrutacja.p.lodz.pl/en/language-requirements"}
                                            v
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/en/language-requirements " : "https://rekrutacja.p.lodz.pl/en/language-requirements"}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.languageEnExamRequirement')}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "payment":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.bank1')}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.bank2')}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.bank3')}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.bank4')}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.bank5')}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "dyplom_2st":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='II_st_plik_dyplomPL' src={process.env.PUBLIC_URL + '/II_st_plik_dyplomPL.png'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "dyplom_tlumaczenie":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.translationNeeded')}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.listTranslators')}
                                </Typography>
                                <Typography component={Link}
                                            href=
                                                {i18n.language == 'pl' ? "https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html" : "https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html"}
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html" : "https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html"}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "suplement_tlumaczenie":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.translationNeeded')}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.listTranslators')}
                                </Typography>
                                <Typography component={Link}
                                            href="https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html"
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                    https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "apostille_2st":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>

                                    {i18n.t('popup.Apostille')}
                                </Typography>
                                <Typography component={Link} href="https://www.hcch.net/en/instruments/status-table"
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                    https://www.hcch.net/en/instruments/status-table
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>

                                    {i18n.t('popup.legalisation')}

                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://www.gov.pl/web/dyplomacja/polskie-przedstawicielstwa-na-swiecie" : "https://www.gov.pl/web/diplomacy/certification-of-documents"}
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>

                                    {i18n.language == 'pl' ? "https://www.gov.pl/web/dyplomacja/polskie-przedstawicielstwa-na-swiecie" : "https://www.gov.pl/web/diplomacy/certification-of-documents"}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "nawa":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.download')}
                                </Typography>
                                <Typography component={Link} href="https://kwalifikator.nawa.gov.pl" target="_blank"
                                            color={theme.palette.secondary.main} variant='popupContent'>
                                    https://kwalifikator.nawa.gov.pl
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "nostryfikacja":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.nostrofikation')}

                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://p.lodz.pl/ksztalcenie/nostryfikacja-dyplomu" : "https://p.lodz.pl/en/education/nostrification-diploma"}
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>

                                    {i18n.language == 'pl' ? "https://p.lodz.pl/ksztalcenie/nostryfikacja-dyplomu" : "https://p.lodz.pl/en/education/nostrification-diploma"}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "certyfikat_jezykowy_pl_2st":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='II st plik zaswiadczenie'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_zaswiadczenie.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "transkrypt":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='II st plik suplement'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_transcript/0001.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                                <img alt='II st plik suplement'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_transcript/0002.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                                <img alt='II st plik suplement'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_transcript/0003.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                                <img alt='II st plik suplement'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_transcript/0004.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "suplement":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='II st plik suplement'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_suplement/0001.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                                <img alt='II st plik suplement'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_suplement/0002.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                                <img alt='II st plik suplement'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_suplement/0003.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                                <img alt='II st plik suplement'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_suplement/0004.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                                <img alt='II st plik suplement'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_suplement/0005.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                                <img alt='II st plik suplement'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_suplement/0006.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                                <img alt='II st plik suplement'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_suplement/0007.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>

                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "podanie_2_st":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='II st plik suplement'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_suplement.pdf'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "dokumentacja_2_st":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='II st plik transcript'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_transcript.pdf'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "suplement_tlumaczenie":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='II st plik transcript'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_transcript.pdf'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "suplement_tlumaczenie":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='II st plik transcript'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_transcript.pdf'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "zaswiadczenie":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='II_st_plik_zaswiadczenie'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_zaswiadczenie.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "dyplom_zaswiadczenie":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='II_st_plik_dyplomPL' src={process.env.PUBLIC_URL + '/II_st_plik_dyplomPL.png'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                                <img alt='II_st_plik_zaswiadczenie'
                                     src={process.env.PUBLIC_URL + '/II_st_plik_zaswiadczenie.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "dokumentacja":
                    return ({
                        content: <Grid container direction='column' alignItems="flex-start" style={{
                            width: "100%", display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Typography component={Link}
                                        href={i18n.t('popup.freeSpaces')}
                                        target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                {i18n.t('popup.freeSpaces')}
                            </Typography>
                        </Grid>
                        , isOpen: true
                    })
                    break;
                case "podanie":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography component={Link}
                                            href={i18n.t('popup.freeSpaces')}
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.freeSpaces')}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case "scale":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='II_st_skala_ocen' src={process.env.PUBLIC_URL + '/II_st_skala_ocen.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 21:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='III_st_plik_dyplomPL'
                                     src={process.env.PUBLIC_URL + '/III_st_plik_dyplomPL.png'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 18:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.zalacznik')} 7
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                            v target="_blank" color={theme.palette.secondary.main}
                                            variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 22:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.zalacznik')} 5
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                            v target="_blank" color={theme.palette.secondary.main}
                                            variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 20:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.zalacznik')} 6
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                            v target="_blank" color={theme.palette.secondary.main}
                                            variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 23:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.CV')}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 24:
                    return ({

                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.zalacznik')} 1
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                            v target="_blank" color={theme.palette.secondary.main}
                                            variant='popupContent'>

                                    {i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 25:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.thems')}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 26:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.zalacznik')} 2
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                            v target="_blank" color={theme.palette.secondary.main}
                                            variant='popupContent'>
                                    https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do
                                    {i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 27:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography component={Link}
                                            href="https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do"
                                            v target="_blank" color={theme.palette.secondary.main}
                                            variant='popupContent'>
                                    https://www.cj.p.lodz.pl/images/documents/Studia_3_stopien_wykaz_certyfikatyzwalniajacezegzaminu.pdf </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;

                case 28:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.zalacznik')} 8
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                            v target="_blank" color={theme.palette.secondary.main}
                                            variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                    https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 29:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.zalacznik')} 4
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}
                                            v target="_blank" color={theme.palette.secondary.main}
                                            variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://ids.p.lodz.pl/rekrutacja/rekrutacja-20222023/zasady-rekrutacji-20222023/wzory-dokumentow-rekrutacyjnych-do" : "https://ids.p.lodz.pl/en/recruitment/recruitment-20222023/rules-recruitment-academic-year-20222023/templates-recruitment"}

                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 30:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>

                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.Apostille')}
                                </Typography>
                                <Typography component={Link} href="https://www.hcch.net/en/instruments/status-table" v
                                            target="_blank" color={theme.palette.secondary.main} variant='popupContent'>
                                    https://www.hcch.net/en/instruments/status-table
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.legalisation')}
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://www.gov.pl/web/dyplomacja/polskie-przedstawicielstwa-na-swiecie" : "https://www.gov.pl/web/diplomacy/certification-of-documents"}
                                            v target="_blank" color={theme.palette.secondary.main}
                                            variant='popupContent'>
                                    {i18n.language == 'pl' ? "https://www.gov.pl/web/dyplomacja/polskie-przedstawicielstwa-na-swiecie" : "https://www.gov.pl/web/diplomacy/certification-of-documents"}

                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 34:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.translationNeeded')}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.listTranslators')}
                                </Typography>
                                <Typography component={Link}
                                            href="https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html"
                                            v target="_blank" color={theme.palette.secondary.main}
                                            variant='popupContent'>
                                    https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 38:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                    {i18n.t('popup.oswiadczenie')}
                                    Oświadczenie Uczelni, że uzyskany przez kandydata dyplom drugiego stopnia uprawnia
                                    go do ubiegania się o nadanie stopnia doktora w kraju, w którym dyplom został
                                    wydany.
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 'help': {
                }
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='rejestracjaPL'
                                     src={process.env.PUBLIC_URL + '/rejestracja' + i18next.language.toUpperCase() + '.png'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 1:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.learnMore')}
                                    </Typography>
                                    <Typography component={Link}
                                                href="https://www.gov.pl/app/rdp/web/rdp/karta-polaka-wzor-2017"
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        https://www.gov.pl/app/rdp/web/rdp/karta-polaka-wzor-2017

                                    </Typography>
                                </Grid>
                                <img alt='Karta polaka przykład'
                                     src={process.env.PUBLIC_URL + '/DO_quiz_karta_polaka.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 2:
                    return ({

                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.learnMore')}
                                    </Typography>
                                    <Typography component={Link}
                                                href={i18n.language == 'pl' ? "https://mos.cudzoziemcy.gov.pl/" : "https://mos.cudzoziemcy.gov.pl/en"}
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        {i18n.language == 'pl' ? "https://mos.cudzoziemcy.gov.pl/" : "https://mos.cudzoziemcy.gov.pl/en"}
                                    </Typography>
                                </Grid>
                                <img alt='Karta pobytu przykład'
                                     src={process.env.PUBLIC_URL + '/DO_quiz_karta_pobytu.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 3:
                    return ({
                        content:
                            <Grid container direction='column' display='flex' style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',

                                justifyContent: 'center'
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography align="center" color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.learnMore')}
                                    </Typography>
                                    <Typography component={Link}
                                                href={i18n.language == 'pl' ? "https://www.gov.pl/web/udsc/prawa-i-obowiazki--wnioskodawca" : "https://www.gov.pl/web/udsc-en/refugee-procedure2"}
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        {i18n.language == 'pl' ? "https://www.gov.pl/web/udsc/prawa-i-obowiazki--wnioskodawca" : "https://www.gov.pl/web/udsc-en/refugee-procedure2"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 4:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.learnMore')}
                                    </Typography>
                                    <Typography component={Link}
                                                href={i18n.language == 'pl' ? "https://mos.cudzoziemcy.gov.pl/" : "https://mos.cudzoziemcy.gov.pl/en"}
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>

                                        {i18n.language == 'pl' ? "https://mos.cudzoziemcy.gov.pl/" : "https://mos.cudzoziemcy.gov.pl/en"}
                                    </Typography>
                                </Grid>
                                <img alt='Obywatelstwo przykład' src={process.env.PUBLIC_URL + '/DO_quiz_obywatel.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 5:
                    return ({
                        content:
                            <Grid container direction='column' display='flex' style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography align="center" color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.learnMore')}
                                    </Typography>
                                    <Typography component={Link} href="https://certyfikatpolski.pl/" target="_blank"
                                                color={theme.palette.secondary.main} variant='popupContent'>
                                        https://certyfikatpolski.pl/
                                    </Typography>
                                </Grid>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 6:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                        {i18n.t('popup.marriage')}
                                    </Typography>
                                    <Typography color={theme.palette.secondary.main} variant='popupContent'>
                                        {i18n.t('popup.learnMore')}
                                    </Typography>
                                    <Typography component={Link}
                                                href={i18n.language == 'pl' ? "https://mos.cudzoziemcy.gov.pl/" : "https://mos.cudzoziemcy.gov.pl/en"}
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        {i18n.language == 'pl' ? "https://mos.cudzoziemcy.gov.pl/" : "https://mos.cudzoziemcy.gov.pl/en"}
                                    </Typography>
                                </Grid>
                                <img alt='Akt slubu' src={process.env.PUBLIC_URL + '/DO_quiz akt_slubu_urodzenia.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 8:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.learnMore')}
                                    </Typography>
                                    <Typography component={Link}
                                                href="https://www.gov.pl/web/uw-mazowiecki/badacze-i-naukowcy"
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        https://www.gov.pl/web/uw-mazowiecki/badacze-i-naukowcy
                                    </Typography>
                                </Grid>
                            </Grid>
                        , isOpen: true

                    })
                    break;
                case 10:
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.learnMore')}
                                    </Typography>
                                    <Typography component={Link}
                                                href={i18n.language == 'pl' ? "https://nawa.gov.pl/studenci" : "https://nawa.gov.pl/en/students"}
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        {i18n.language == 'pl' ? "https://nawa.gov.pl/studenci" : "https://nawa.gov.pl/en/students"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        , isOpen: true

                    })
                    break;
                case "paszport":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.learnMore')}
                                    </Typography>
                                    <Typography component={Link}
                                                href="https://www.gov.pl/web/gov/paszport-informacje-o-dokumencie"
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        https://www.gov.pl/web/gov/paszport-informacje-o-dokumencie
                                    </Typography>
                                </Grid>
                                <img alt='Karta pobytu przykład' src={process.env.PUBLIC_URL + '/DO_passport.jpeg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true

                    })
                    break;
                case "kartaPobytu":
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' style={{alignItems: 'center',}}
                                      alignItems="flex-start">
                                    <Typography color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.visaInformation')}
                                    </Typography>
                                    <Typography component={Link}
                                                href={i18n.language == 'pl' ? "https://www.gov.pl/web/zea/wizy-informacje-ogolne" : "https://www.gov.pl/web/uae/visas"}
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>

                                        {i18n.language == 'pl' ? "https://www.gov.pl/web/zea/wizy-informacje-ogolne" : "https://www.gov.pl/web/uae/visas"}
                                    </Typography>
                                    <Typography color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.przykladPobytu')}
                                    </Typography>
                                    <Typography component={Link}
                                                href={i18n.language == 'pl' ? "https://www.gov.pl/web/uw-lodzki/wydaniewymiana-karty-pobytu" : "https://www.gov.pl/web/uw-lodzki/wydaniewymiana-karty-pobytu"}
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        {i18n.language == 'pl' ? "https://www.gov.pl/web/uw-lodzki/wydaniewymiana-karty-pobytu" : "https://www.gov.pl/web/uw-lodzki/wydaniewymiana-karty-pobytu"}
                                    </Typography>
                                    <Typography color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.wzorPobytu')}
                                    </Typography>
                                    <Typography component={Link}
                                                href={i18n.language == 'pl' ? "https://www.gov.pl/app/rdp/web/rdp/karta-pobytu-wzor-2020" : "https://www.gov.pl/app/rdp/web/rdp/karta-pobytu-wzor-2020"}
                                                target="_blank" color={theme.palette.secondary.main}
                                                variant='popupContent'>
                                        {i18n.language == 'pl' ? "https://www.gov.pl/app/rdp/web/rdp/karta-pobytu-wzor-2020" : "https://www.gov.pl/app/rdp/web/rdp/karta-pobytu-wzor-2020"}
                                    </Typography>
                                </Grid>
                                <img alt='Karta pobytu przykład'
                                     src={process.env.PUBLIC_URL + '/DO_wiza_karta_pobytu.jpg'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 'header.help':
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='procedura_rekrutacji'
                                     src={process.env.PUBLIC_URL + '/procedura_rekrutacji_' + i18next.language.toUpperCase() + '.png'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 'footer.steps':
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img alt='rekrutacja_krok_po_kroku'
                                     src={process.env.PUBLIC_URL + '/rekrutacja_krok_po_kroku_' + i18next.language.toUpperCase() + '.png'}
                                     style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 'personal.narodowosc':
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography color={theme.palette.secondary.main} fontWeight='bold'
                                            variant='popupContent'>
                                    {i18n.t('popup.narodowosc')}
                                </Typography>
                            </Grid>
                        , isOpen: true
                    })
                    break;
                case 'personal.obywatelstwo':
                    return ({
                        content:
                            <Grid container direction='column' alignItems="flex-start" style={{
                                width: "100%", display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid container direction='column' alignItems="flex-start" style={{
                                    width: "100%", display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Typography color={theme.palette.secondary.main} fontWeight='bold'
                                                variant='popupContent'>
                                        {i18n.t('popup.obywatelstwo')}
                                        
                                    </Typography>
                                    <img alt='rekrutacja_krok_po_kroku'
                                         src={process.env.PUBLIC_URL + 'DO passport obywatelstwo.jpeg'}
                                         style={{maxWidth: "100%", maxHeight: "calc(100vh - 64px)"}}/>
                                </Grid>

                            </Grid>
                        , isOpen: true
                    })
                    break;
            }
            break
    }

}

// usage  {popup(popup, dispatchPopup) /*popup*/}
export default function Popup(popup, dispatcher) {

    return (
        <>
            {popup ?
                <Dialog
                    open={popup.isOpen}
                    onClose={() => dispatcher({type: 'CLOSE'})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <AppBar sx={{position: 'relative'}}>
                        <Toolbar>
                            <ThemeProvider theme={theme}>
                                <Box display='flex'>
                                    {popup.title ?
                                        <Box display='flex' style={{
                                            width: "100%",
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(0, 10%)',
                                        }}>
                                            {popup.title}
                                        </Box>
                                        : null}
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => dispatcher({type: 'CLOSE'})}
                                        aria-label="close"
                                        style={{
                                            position: 'absolute',
                                            left: '100%',
                                            top: '50%',
                                            transform: 'translate(-100%, -50%)'
                                        }}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                </Box>
                            </ThemeProvider>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <ThemeProvider theme={theme}>
                            {popup.content}
                        </ThemeProvider>
                    </DialogContent>
                </Dialog>
                : null}
        </>
    )
}