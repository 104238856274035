import {Store} from 'react-notifications-component';
import i18n from '../i18n';
import {CustomEnums} from './CustomEnums';


const options = {
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"]
}

export const createNotificationForResponse = ({ status, message, wiadomoscTyp ,wiadomosc}, title, httpStatus) => {
    if (!message){
        message = wiadomosc
    }
    
    if (httpStatus === 500) {
        errorNotification('default.unexpected.error', ' ');
    } else {
        switch (wiadomoscTyp) {
            case 'error':
                errorNotification(title, message);
                break;
            case 'warn':
                warningNotification(title, message);
                break;
            case 'info':
                infoNotification(title, message);
                break;
            case 'success':
                successNotification(title, message);
                break;
            default:
                errorNotification('default.unexpected.error', " ");
        }
    }
}

export const successNotification = (title, message, duration) => {
    Store.addNotification({
        title: i18n.t((title) ? title : ""),
        message: i18n.t((message) ? message : CustomEnums.OPERACJA_ZAKONCZONA_POWODZENIEM),
        type: "success",
        ...options,
        dismiss: {
            duration: duration !== undefined ? duration : 4000,
            onScreen: true
        }
    });
}

export const infoNotification = (title, message, duration) => {
    Store.addNotification({
        title: i18n.t(title),
        message: i18n.t(message),
        type: "info",
        ...options,
        dismiss: {
            duration: duration !== undefined ? duration : 4000,
            onScreen: true,
            pauseOnHover: true
        }
    });
}

export const warningNotification = (title, message) => {
    Store.addNotification({
        title: i18n.t(title),
        message: addCurrentDate({ message }),
        type: "warning",
        ...options,
        dismiss: {
            duration: 5000,
            showIcon: true
        }
    });
}

export const errorNotification = (title, message) => {
    Store.addNotification({
        title: i18n.t(title),
        message: addCurrentDate({ message }),
        type: "danger",
        ...options,
        dismiss: {
            duration: 0,
            showIcon: true
        }
    });
}

export const clearAllNotifications = () => {
    Store.removeAllNotifications();
}

const addCurrentDate = ({ message }) => {
    if (message) {
        return (
            <div>
                {
                    message.length > 1
                        ?
                        <div>
                            <label>{i18n.t(message)}</label>
                            <br/>
                        </div> : null
                }
                <div style={{'fontSize': '90%', 'marginTop': '10px'}}>
                    <label>
                        {new Date().toLocaleString().replace(',', '')}
                    </label>
                </div>
            </div>
        )
    } else {
        return null;
    }

}