import React, {useContext, useEffect, useRef, useState} from 'react'
import {
    Box,
    Button,
    Grid,
    IconButton,
    TextField,
    ThemeProvider,
    Tooltip,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import 'react-phone-input-2/lib/style.css'
import MenuTile from '../../templates/MenuTile';
import {useStyles, useTheme} from '../../css/Styles';
import InputMask from 'react-input-mask';
import CustomSelectField from '../../templates/CustomSelectField';
import CustomDatePicker from '../../templates/CustomDatePicker';
import CustomRadioGroup from '../../templates/CustomRadioGroup';
import CustomToggleButtonTrueFalseField from '../../templates/CustomToggleButtonTrueFalseField';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {DictionaryTypeEnums} from '../../utils/CustomEnums';
import {getCredentials} from '../../services/UserDataService';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import i18next from 'i18next';
import {getDictionaries, modifyAccessStorage, stringToBoolean} from '../../utils/AppUtils';
import FetchService from '../../services/FetchService';
import {createSaveSchoolSecondDegreeRequest, getSchoolSecondDegreeFormDataFromRequest} from '../../utils/MapperUtils';
import {createNotificationForResponse, errorNotification, warningNotification} from '../../utils/Notifications';
import logLevel from '../../utils/LoggerHelper';
import {ACTIONS} from '../../utils/Consts';
import FileUploadComponent from '../../templates/FileUploadComponent';
import ErrorInfoGrid from '../../templates/ErrorInfoGrid';
import CustomCheckboxField from '../../templates/CustomCheckboxField';
import BackAndSaveButtons from '../../templates/BackAndSaveButtons';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Popup, {popupReducer} from '../popup/Popup';
import {DatesContext} from "../GlobalDatesContext";
import {filesReducer} from "../../utils/Files/FilesReducer";
import {saveFilesToDatabase} from "../../utils/Files/SaveFilesToDatabase";
import {
    checkChineseInObject,
    checkCyrillicInObject,
    checkJapaneseInObject
} from "../../utils/Validation/CheckForCyrillic";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const initialSchoolData = {
    country: null,
    city: null,
    schoolName: null,
    graduationDate: null,
    professionalTitle: null,
    graduatedCourse: null,
    diplomaType: false,
    diplomaOwnership: false,
    diplomaNumber: null,
    diplomaIssueDate: null,
    diplomaIssuePlace: null,
    languageExamPl: false,
    languageExamEn: false,
    languageExamEmail: getCredentials(),
    languageExam: false,
    gradeScale: null,
    graduationResult: null,
    matriculationCertificate: null,
    languageCertificate: false,
    skype: null,
    contest: false
}

const selectInputs = [{id: "country", name: "country"}, {id: "city", name: "city"}, {
    id: "professionalTitle",
    name: "professionalTitle"
}, {id: "gradeScale", name: "gradeScale"}, {id: "graduationResult", name: "graduationResult"},]

const toggleButtonInputs = ["matriculationCertificate"]

const defaultDictValue = [{id: "-", label: "-"}]


const requiredFieldsvalidation = ["country", "city", "schoolName", "graduationDate", "professionalTitle", "graduatedCourse", "diplomaType", "diplomaOwnership", "diplomaNumber", "diplomaIssueDate", "diplomaIssuePlace", "gradeScale", "graduationResult", "matriculationCertificate"]
const requiredLengthForSchoolFields = [
    {name: "country", maxLength: 50},
    {name: "city", maxLength: 50},
    {name: "schoolName", maxLength: 100},
    {name: "professionalTitle", maxLength: 30},
    {name: "graduatedCourse", maxLength: 255},
    {name: "diplomaNumber", maxLength: 50},
    {name: "diplomaIssuePlace", maxLength: 500},
    {name: "gradeScale", maxLength: 10},
    {name: "graduationResult", maxLength: 100}
]

const fieldsDiplomaInfo = ["diplomaNumber", "diplomaIssueDate", "diplomaIssuePlace", "gradeScale", "graduationResult"]
const notGenericFileIds = [52, 53, 56, 57]
const filesForPLDiploma = [42, 43]
const filesForPLDiplomaCertificate = [43]

const filesType = 'GRADUATED_SCHOOL_SECOND_DEGREE'

export default function SchoolSecondDegree({candidateType, degree, abroadSelection}) {
    const {t} = useTranslation();
    const theme = useTheme;
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))

    const [countires, setCountires] = React.useState(defaultDictValue)
    const [cities, setCities] = React.useState(defaultDictValue)
    const [professionalTitles, setProfessionalTitles] = React.useState(defaultDictValue)
    const [gradeScaleValues, setGradeScaleValues] = React.useState([])

    //state for disabling edition
    const [readOnly, setReadOnly] = React.useState(false)

    const [formErrors, setFormErrors] = React.useState([])

    //state for controlling dialog with example certificate
    const [open, setOpen] = React.useState(false);
    const [openScale, setOpenScale] = React.useState(false);

    //accessContext
    const datesContext = useContext(DatesContext)

    // const dates = useContext(DatesContext)

    const [dates, setDates] = useState()

    //data
    const [schoolData, setSchoolData] = useState(initialSchoolData)
    const [errorsState, setErrorsState] = useState([])
    const [langError, setLangError] = useState(false)

    //files info reducer
    const [filesInfo, dispatchFilesInfo] = React.useReducer(filesReducer, [])

    //popup reducer
    const [popup, dispatchPopup] = React.useReducer(popupReducer, null);

    //input references for better file upload styling
    const inputRefs = useRef([])

    //loading awaiting save
    const [loading, setLoading] = React.useState(false)

    const gradeScalesOptions = [{id: 'other', label: t('school.gradeScales.other')}, {
        id: '5_levels',
        label: t('school.gradeScales.5')
    }, {id: '6_levels', label: t('school.gradeScales.6')}]

    const gradeScales5LevelsValuesOptions = [{
        id: 'excellent',
        label: t('school.gradeScaleValue.excellent')
    }, {id: 'very_good', label: t('school.gradeScaleValue.very_good')}, {
        id: 'good',
        label: t('school.gradeScaleValue.good')
    }, {id: 'satisfactory', label: t('school.gradeScaleValue.satisfactory')}, {
        id: 'sufficient',
        label: t('school.gradeScaleValue.sufficient')
    }]

    const gradeScales6LevelsValuesOptions = [{
        id: 'excellent_advanced', label: t('school.gradeScaleValue.excellent_advanced')
    }, {id: 'excellent', label: t('school.gradeScaleValue.excellent')}, {
        id: 'very_good',
        label: t('school.gradeScaleValue.very_good')
    }, {id: 'good', label: t('school.gradeScaleValue.good')}, {
        id: 'satisfactory',
        label: t('school.gradeScaleValue.satisfactory')
    }, {id: 'sufficient', label: t('school.gradeScaleValue.sufficient')}]

    const diplomaOwnershipRadios = [{
        label: t('school.diplomaType.hasDiploma'),
        value: true
    }, {label: t('school.diplomaType.hasNotDiploma'), value: false}]

    function manageAccess(dates) {
        setReadOnly(true)
        if (new Date(dates[degree]?.recruitmentStart) <= new Date(dates?.currentDateTime) && new Date(dates?.currentDateTime) <= new Date(dates[degree]?.recruitmentClosure)) {
            setReadOnly(false)
        }
    }

    function getFileWithCaption(element) {
        let tempName;
        if (hasPlDiploma(schoolData)) {
            if (element.attachmentInfo.typeId === 43) {
                tempName = t('school.diploma')
            }
        }

        if (hasPlCertificate(schoolData)) {
            if (element.attachmentInfo.typeId === 43) {
                tempName = t('school.certificate')
            }
        }

        let data = {...element}
        if (tempName) {
            data.attachmentInfo.namePL = tempName;
            data.attachmentInfo.nameEN = tempName;
        }
        return data;
    }

    async function prepareDictionaries() {
        return getDictionaries(DictionaryTypeEnums.SCHOOL_SECOND_DEGREE).then(secondDegreeSchoolDict => {
            if (secondDegreeSchoolDict) {
                let secondDegreeSchoolDictValues = secondDegreeSchoolDict.value

                let countriesTemp = [];
                let citiesTemp = [];
                let professionalTitlesTemp = [];

                let countiresDict = secondDegreeSchoolDictValues.kraj;
                let citiesDict = secondDegreeSchoolDictValues.miasta;
                let professionalTitlesDict = secondDegreeSchoolDictValues.dyplom_tytul;


                if (countiresDict) {
                    countiresDict.forEach(country => {
                        {
                            i18next.language == 'pl' ? countriesTemp.push({
                                id: country.valuePL,
                                label: country.valuePL
                            }) : countriesTemp.push({id: country.valuePL, label: country.valueEN})
                        }
                    });
                    setCountires(countriesTemp)
                }

                if (citiesDict) {
                    citiesDict.forEach(city => {
                        {
                            i18next.language == 'pl' ? citiesTemp.push({
                                id: city.valuePL,
                                label: city.valuePL
                            }) : citiesTemp.push({id: city.valuePL, label: city.valueEN})
                        }
                    });
                    setCities(citiesTemp)
                }

                if (professionalTitlesDict) {
                    professionalTitlesDict.forEach(title => {

                        {
                            i18next.language == 'pl' ? professionalTitlesTemp.push({
                                id: title.valuePL,
                                label: title.valuePL
                            }) : professionalTitlesTemp.push({id: title.valuePL, label: title.valueEN})
                        }
                    });
                    setProfessionalTitles(professionalTitlesTemp)
                }

                return {
                    countries: countriesTemp,
                    cities: citiesTemp,
                    professionalTitles: professionalTitlesTemp,
                    gradeScales: gradeScalesOptions,
                    gradeScales5Levels: gradeScales5LevelsValuesOptions,
                    gradeScales6Levels: gradeScales6LevelsValuesOptions
                }
            }
        });
    }

    useEffect(() => {
        FetchService.getFilesInfo(degree, filesType).then(response => {
            let tempInputRefs = response.zawartosc?.map((element, index) => {
                return inputRefs.current[index] = React.createRef();
            })
            dispatchFilesInfo({type: ACTIONS.SET_INITIAL_FILES, payload: response.zawartosc, inputRefs: tempInputRefs})
        })

        prepareDictionaries().then(responseDict => {
            FetchService.getSchoolData(degree).then(response => {
                if (response && response.zawartosc) {
                    setSchoolData(getSchoolSecondDegreeFormDataFromRequest(response.zawartosc, responseDict, setGradeScaleValues))
                } else {
                    createNotificationForResponse(response);
                }
            }).catch(error => {
                logLevel.debug("Błąd podczas odczytu danych szkoły 2 stopnia, Error " + error);
                errorNotification('default.errorOnGet', ' ');
            })
        })

        datesContext.fetchGetMoreDates(degree, datesContext.currentSemester).then(result => {
            setDates(result)
            manageAccess(result)
        })
    }, [])

    const changeDataHandler = (event, newValue, ref) => {
        let fields = {...schoolData};

        if (isFormPL(fields)) {
            fields.gradeScale = {id: "6_levels", label: "6 poziomów"}
            setGradeScaleValues(gradeScales6LevelsValuesOptions);
        }

        if (ref && (ref === 'selectOption' || ref === 'blur')) {
            let inputSelectField = selectInputs.find(selectInput => event.target.id.startsWith(selectInput.id))

            //do listy rozwijanej dla skali ocen
            if (inputSelectField.id === 'gradeScale') {
                switch (newValue.id) {
                    case "5_levels": {
                        setGradeScaleValues(gradeScales5LevelsValuesOptions);
                        fields.graduationResult = null
                        break;
                    }
                    case "6_levels": {
                        setGradeScaleValues(gradeScales6LevelsValuesOptions);
                        fields.graduationResult = null
                        break;
                    }
                    default:
                        setGradeScaleValues([]);
                        fields.graduationResult = null
                }
            }

            fields[inputSelectField.name] = newValue
        } else if (toggleButtonInputs.includes(event.target.name)) {
            if (fields[event.target.name] !== null && fields[event.target.name] === stringToBoolean(event.target.value)) {
                fields[event.target.name] = null
            } else {
                fields[event.target.name] = stringToBoolean(event.target.value);
            }
        } else if (event.target.name === 'contest' || event.target.name === 'languageCertificate' || event.target.name === 'languageExam' || event.target.name === 'languageExamPl' || event.target.name === 'languageExamEn') {
            fields[event.target.name] = event.target.checked;
        } else {
            //Czyszczenie danych dyplomu po wybraniu opcji "nie posiadam jeszcze dyplomu"
            if (isSetRadioForHideDiplomaInfo(event.target.name, event.target.value)) {
                fields.diplomaNumber = null
                fields.diplomaIssueDate = null
                fields.diplomaIssuePlace = null
                fields.gradeScale = null
                fields.graduationResult = null
            }
            fields[event.target.name] = event.target.value;
        }


        setSchoolData(fields)
    }

    const changeGraduationDateHandler = (value) => {
        let fields = {...schoolData};
        fields.graduationDate = value
        setSchoolData(fields)
    }

    const changeDiplomaIssueDateHandler = (value) => {
        let fields = {...schoolData};
        fields.diplomaIssueDate = value
        setSchoolData(fields)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleCloseScale = () => {
        setOpenScale(false)
    }


    const handleSave = () => {
        if (handleValidation()) {
            setLoading(true)
            saveFilesToDatabase(filesInfo)
            FetchService.saveSchoolData(createSaveSchoolSecondDegreeRequest(schoolData), degree).then(() => {
                modifyAccessStorage(degree)
                setLoading(false)
            })
        } else {
            if (formErrors && formErrors.length !== 0 && formErrors[0].value) {
                warningNotification(formErrors[0].value, " ");
            } else {
                warningNotification("personal.formValidationError", " ");
            }
        }
    }

    const handleValidation = () => {
        let errors = [];
        let formIsValid = true;
        let tempRequiredFieldsvalidation = requiredFieldsvalidation;

        let fields = {...schoolData};

        if (isSetRadioForHideDiplomaInfo('diplomaOwnership', fields.diplomaOwnership)) {
            tempRequiredFieldsvalidation = tempRequiredFieldsvalidation.filter(reqField => !fieldsDiplomaInfo.includes(reqField))
        }

        tempRequiredFieldsvalidation.filter(reqField => (fields[reqField] === undefined || fields[reqField] === null || (typeof fields[reqField] === 'string' && fields[reqField].trim().length === 0)))
            .forEach(reqField => {
                errors = errors.concat({id: reqField, value: "default.fieldRequired"});
                formIsValid = false;
            })

        requiredLengthForSchoolFields.forEach(field => {
            if (fields[field.name]) {
                if (fields[field.name].length > field.maxLength) {
                    errors = errors.concat({
                        id: field.name,
                        value: "school.tooLong"
                    });
                    formIsValid = false;
                }
            }
        })

        if (schoolData.languageExam === true && schoolData.languageExamPl === false && schoolData.languageExamEn === false) {
            errors.push({id: 'langSelection'});
            setLangError(true);
            formIsValid = false;
        } else {
            setLangError(false);
        }


        if (checkCyrillicInObject(fields)) {
            errors = errors.push({
                id: "cyrillicError",
                value: "cyrillicError"
            })
            formIsValid = false;
        }

        if (checkChineseInObject(fields)) {
            errors = errors.push({
                id: "chineseError",
                value: "chineseError"
            })
            formIsValid = false;
        }

        if (checkJapaneseInObject(fields)) {
            errors = errors.push({
                id: "japaneseError",
                value: "japaneseError"
            })
            formIsValid = false;
        }


        setFormErrors(errors)
        return formIsValid;
    }

    const isDiplomaInfoHidden = (schoolData) => {
        if (schoolData.diplomaOwnership === null || schoolData.diplomaOwnership === undefined || schoolData.diplomaOwnership?.toString() === 'false') {
            return true;
        } else {
            return false;
        }
    }

    const isSetRadioForHideDiplomaInfo = (field, value) => {
        if (field === 'diplomaOwnership' && value?.toString() === 'false') {
            return true;
        } else {
            return false;
        }
    }

    const isFormPoland = (schoolData) => {
        return schoolData && schoolData.country && (schoolData.country.id == 'Polska' || schoolData.country.id == 'Poland');
    }

    const isFormPL = (schoolData) => {
        if (schoolData && schoolData.schoolName) {
            const schoolName = schoolData.schoolName.toLowerCase();

            const containsPolitechnika = schoolName.includes("politechnika");
            const containsLodzOrLodz = schoolName.includes("łód") || schoolName.includes("lod");
            const containsUniversityOfTechnology = schoolName.includes("university of technology");

            return (containsPolitechnika && containsLodzOrLodz) || (containsLodzOrLodz && containsUniversityOfTechnology);
        }
        return false;
    }

    const hasPlDiploma = (schoolData) => {
        return isFormPoland(schoolData) && schoolData.diplomaOwnership?.toString() === 'true'
    }

    const hasPlCertificate = (schoolData) => {
        return isFormPoland(schoolData) && schoolData.diplomaOwnership?.toString() === 'false'
    }

    const hasForeignDiploma = (schoolData) => {
        return !isFormPoland(schoolData) && schoolData.diplomaOwnership?.toString() === 'true'
    }

    const hasForeignCertificate = (schoolData) => {
        return !isFormPoland(schoolData) && schoolData.diplomaOwnership?.toString() === 'false'
    }

    return (<ThemeProvider theme={theme}>
        <Box sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: isMobile ? 'center' : 'flex-start',
            flexDirection: isMobile ? 'column' : 'row'
        }}>
            <Grid sx={{padding: '2.5vh'}} display="flex" justifyItems="end">
                <MenuTile title={t('school.schoolSecondDegree')} locked={false}/>
            </Grid>
            <Grid sx={{padding: isMobile ? '1vh' : '3vh'}} container direction={'row'} justifyContent="flex-start"
                  alignItems="flex-start">
                <Grid style={{
                    width: '100%', textAlign: 'left', marginBottom: '2vh', marginTop: '2vh', paddingLeft: '1.5vw'
                }}>
                    <Typography fontWeight='bold' variant='h2'>{t('school.graduatedUniversity')}</Typography>
                </Grid>
                <Grid style={{width: '100%'}} container direction={isMobile ? 'column' : 'row'}
                      justifyContent="space-between">
                    <Grid style={{width: isMobile ? '100%' : '45%'}}>
                        <CustomSelectField editState={readOnly} name="country" id="country"
                                           title={t('personal.kraj')} options={countires} value={schoolData.country}
                                           freeSolo={true} required={true} changeHandler={changeDataHandler}
                                           errors={formErrors}/>
                        <CustomSelectField editState={readOnly} name="city" id="city" title={t('school.city')}
                                           options={cities} value={schoolData.city} freeSolo={true} required={true}
                                           changeHandler={changeDataHandler} errors={formErrors}/>
                    </Grid>
                    <Grid style={{width: isMobile ? '100%' : '45%'}}>
                        <CustomGridField disabledState={readOnly} name="schoolName" title={t('school.university')}
                                         label={t('school.university')} value={schoolData.schoolName}
                                         changeHandler={changeDataHandler} required={true} errors={formErrors}/>
                        <Box style={{
                            display: 'flex',
                            width: isMobile ? '97%' : '92%',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}>
                            <CustomDatePicker editState={readOnly} name="graduationDate"
                                              title={t('school.graduationUniversityDate')}
                                              label={t('school.graduationUniversityDate')}
                                              value={schoolData.graduationDate} disabledState={false}
                                              changeHandler={changeGraduationDateHandler} required={true}
                                              errors={formErrors} disableFuture={false}/>
                            <Tooltip title={t('popup.defenseDate')} arrow>
                                <IconButton color='secondary' variant="contained"
                                            children={<HelpOutlineIcon sx={{fontSize: 30}}/>}/>
                            </Tooltip>
                        </Box>
                        <CustomSelectField editState={readOnly} name={`professionalTitle`} id={`professionalTitle`}
                                           title={t('school.professionalTitle')} options={professionalTitles}
                                           value={schoolData.professionalTitle} changeHandler={changeDataHandler}
                                           required={true} errors={formErrors}/>
                        <CustomGridField disabledState={readOnly} name="graduatedCourse"
                                         title={t('school.graduatedCourse')} label={t('school.graduatedCourse')}
                                         value={schoolData.graduatedCourse} changeHandler={changeDataHandler}
                                         required={true} errors={formErrors}/>
                    </Grid>
                </Grid>

                {/* DANE DYPLOMU */}
                <Grid style={{
                    width: '100%', textAlign: 'left', marginBottom: '2vh', marginTop: '2vh', paddingLeft: '1.5vw'
                }}>
                    <Typography fontWeight='bold' variant='h2'>{t('school.diplomaData')}</Typography>
                </Grid>

                <CustomRadioGroup disabledState={readOnly} name={`diplomaOwnership`}
                                  value={schoolData.diplomaOwnership} radioData={diplomaOwnershipRadios}
                                  changeHandler={changeDataHandler} errors={formErrors}/>
                {isDiplomaInfoHidden(schoolData) ? <Grid sx={{width: isMobile ? '100%' : '50%'}}>
                    {isFormPL(schoolData) ? <></> : <Box style={{
                        display: 'flex',
                        width: isMobile ? '96%' : '90%',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}>
                        <CustomSelectField editState={readOnly} id={'gradeScale'} name="gradeScale"
                                           title={t('school.gradeScale')} options={gradeScalesOptions}
                                           value={schoolData.gradeScale} changeHandler={changeDataHandler}
                                           required={true} errors={formErrors}/>
                        <Tooltip title={t('popup.learnMore')} arrow>
                            <IconButton color='secondary' variant="contained" onClick={() => {
                                dispatchPopup({type: 'OPEN', payload: 'scale'})
                            }} children={<HelpOutlineIcon sx={{fontSize: 30}}/>}/>
                        </Tooltip>
                    </Box>}


                    <CustomSelectField editState={readOnly} id={'graduationResult'} name="graduationResult"
                                       title={t('school.graduationResult')} options={gradeScaleValues}
                                       value={schoolData.graduationResult}
                                       freeSolo={(schoolData.gradeScale?.id === 'other') ? true : false}
                                       changeHandler={changeDataHandler} required={true} errors={formErrors}/>
                </Grid> : <>
                    <Grid style={{width: '100%'}} container direction={isMobile ? 'column' : 'row'}
                          justifyContent="space-between">
                        <Grid style={{width: isMobile ? '100%' : '80%'}}>
                            <Grid container direction={isMobile ? 'column' : 'row'}
                                  justifyContent='space-between' alignItems='center'
                                  style={{width: '100%', textAlign: 'left'}}>
                                <Box style={{
                                    display: 'flex',
                                    width: isMobile ? '100%' : '50%',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <CustomGridField disabledState={readOnly} name="diplomaNumber"
                                                     title={t('school.diplomaNumber')}
                                                     label={t('school.diplomaNumber')}
                                                     value={schoolData.diplomaNumber}
                                                     changeHandler={changeDataHandler} required={true}
                                                     errors={formErrors}/>
                                </Box>
                                <Box style={{
                                    display: 'flex',
                                    width: isMobile ? '100%' : '50%',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <CustomDatePicker editState={readOnly} name="diplomaIssueDate"
                                                      title={t('school.diplomaIssueDate')}
                                                      label={t('school.diplomaIssueDate')}
                                                      value={schoolData.diplomaIssueDate} disabledState={false}
                                                      changeHandler={changeDiplomaIssueDateHandler}
                                                      required={true} errors={formErrors}
                                                      disableFuture={false}/>
                                </Box>
                            </Grid>
                            <Grid sx={{width: isMobile ? '100%' : '50%'}}>
                                <CustomGridField disabledState={readOnly} name="diplomaIssuePlace"
                                                 title={t('school.diplomaIssuePlace')}
                                                 label={t('school.diplomaIssuePlace')}
                                                 value={schoolData.diplomaIssuePlace}
                                                 changeHandler={changeDataHandler} capital={true}
                                                 required={true} errors={formErrors}/>
                                <Box style={{
                                    display: 'flex',
                                    width: isMobile ? '96%' : '90%',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <CustomSelectField editState={readOnly} id={'gradeScale'} name="gradeScale"
                                                       title={t('school.gradeScale')}
                                                       options={gradeScalesOptions}
                                                       value={schoolData.gradeScale}
                                                       editState={isFormPL(schoolData)}
                                                       changeHandler={changeDataHandler} required={true}
                                                       errors={formErrors}/>
                                    <Tooltip title={t('popup.learnMore')} arrow>
                                        <IconButton color='secondary' variant="contained" onClick={() => {
                                            dispatchPopup({type: 'OPEN', payload: 'scale'})
                                        }} children={<HelpOutlineIcon sx={{fontSize: 30}}/>}/>
                                    </Tooltip>
                                </Box>
                                <CustomSelectField editState={readOnly} id={'graduationResult'}
                                                   name="graduationResult" title={t('school.graduationResult')}
                                                   options={gradeScaleValues}
                                                   value={schoolData.graduationResult}
                                                   freeSolo={(schoolData.gradeScale?.id === 'other') ? true : false}
                                                   changeHandler={changeDataHandler} required={true}
                                                   errors={formErrors}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </>}

                {/* DODATKOWE INFORMACJE O MATURZE */}
                <Grid style={{
                    width: '100%', textAlign: 'left', marginBottom: '2vh', marginTop: '2vh', paddingLeft: '1.5vw'
                }}>
                    <Typography fontWeight='bold'
                                variant='h2'>{t('school.additionaInfoMatriculationCertificate')}</Typography>
                </Grid>
                <CustomToggleButtonTrueFalseField disabledState={readOnly} name="matriculationCertificate"
                                                  title={t('school.matriculationCertificate')}
                                                  label={t('school.matriculationCertificate')}
                                                  value={schoolData.matriculationCertificate}
                                                  changeHandler={changeDataHandler} errors={formErrors}/>
                {candidateType === 'ABROAD' ? <>
                    <Grid style={{
                        width: '100%',
                        textAlign: 'left',
                        marginBottom: '2vh',
                        marginTop: '2vh',
                        paddingLeft: '1.5vw'
                    }}>
                        <Typography fontWeight='bold'
                                    variant='h2'>{t('school.languageCertificate')}</Typography>
                    </Grid>
                    <CustomCheckboxField disabledState={readOnly} name="languageCertificate"
                                         title={t('school.languageCertificateCheckbox')}
                                         label={t('school.languageCertificate')}
                                         value={schoolData.languageCertificate}
                                         changeHandler={changeDataHandler}
                                         className={classes.daneOsoboweGridField}/>
                    {schoolData.languageCertificate ? <>
                        {filesInfo?.map((element, index) => {
                            return (<React.Fragment key={element.attachmentInfo.typeId}>
                                {(element.attachmentInfo.typeId === 56 || element.attachmentInfo.typeId === 57) ? FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, element.attachmentInfo.typeId, readOnly) : null}
                            </React.Fragment>)
                        })}
                    </> : null}
                    <Grid style={{
                        width: '100%',
                        textAlign: 'left',
                        marginBottom: '2vh',
                        marginTop: '2vh',
                        paddingLeft: '1.5vw'
                    }}>
                        <Typography fontWeight='bold' variant='h2'>{t('school.languageExam')}</Typography>
                    </Grid>
                    <CustomCheckboxField disabledState={readOnly} name="languageExam"
                                         title={t('school.languageExamCheckbox')}
                                         label={t('school.languageExamCheckbox')}
                                         value={schoolData.languageExam} changeHandler={changeDataHandler}
                                         className={classes.daneOsoboweGridField}/>
                    {schoolData.languageExam ? <>
                        <Grid container direction='row' justifyContent='space-between'>
                            <Box style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                textAlign: 'left',
                                alignItems: isMobile ? 'flex-start' : 'center',
                                paddingLeft: '1.5vw',
                                flexDirection: isMobile ? 'column' : 'row'
                            }}>
                                <Typography style={{marginBottom: '0.5vh'}}
                                            variant='h6'>{t('school.languageExamLanguageChoice')}</Typography>
                            </Box>
                            <Box style={{width: isMobile ? '100%' : '50%', alignItems: 'center'}}>
                                <FormControl
                                    required
                                    errors={errorsState}
                                    name="lang"
                                    component="fieldset"
                                    sx={{m: 3, width: '100%'}}
                                    variant="standard"
                                >
                                    {/* <FormLabel component="legend" sx={{ textAlign: 'left' }}>Zaznacz minimum 1 opcję</FormLabel> */}
                                    <FormGroup sx={{flexDirection: 'row'}}>
                                        <FormControlLabel
                                            control={<Checkbox disabled={readOnly}
                                                               checked={schoolData.languageExamPl}
                                                               onChange={changeDataHandler} name="languageExamPl"
                                                               sx={{
                                                                   color: '#30376c',
                                                                   '&.Mui-checked': {color: '#30376c'},
                                                               }}/>}
                                            label={t('school.languagePL')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox disabled={readOnly}
                                                               checked={schoolData.languageExamEn}
                                                               onChange={changeDataHandler} name="languageExamEn"
                                                               sx={{
                                                                   color: '#30376c',
                                                                   '&.Mui-checked': {color: '#30376c'},
                                                               }}/>}
                                            label={t('school.languageEN')}
                                        />
                                    </FormGroup>
                                    <FormHelperText
                                        className={"btn-group pull-right " + (langError ? 'errorShow' : 'errorHidden')}>{t('school.langSelectionError')}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid container direction='row' justifyContent='space-between'>
                            <Box style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                textAlign: 'left',
                                alignItems: isMobile ? 'flex-start' : 'center',
                                paddingLeft: '1.5vw',
                                flexDirection: isMobile ? 'column' : 'row'
                            }}>
                                <Typography style={{marginBottom: '0.5vh'}}
                                            variant='h6'>{t('school.languageExamEmailCaption')}</Typography>
                            </Box>
                            <Box style={{width: isMobile ? '100%' : '50%', alignItems: 'center'}}>
                                <CustomGridField disabledState={readOnly} name="languageExamEmail"
                                                 title={t('school.languageExamEmail')}
                                                 label={t('school.languageExamEmail')}
                                                 value={schoolData.languageExamEmail}
                                                 changeHandler={changeDataHandler} capital={false}
                                                 required={true}/>
                            </Box>
                        </Grid>

                        {/*TODO uncomment for next recrutation  
                        <Grid container direction='row' justifyContent='space-between'>
                            <Box style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                textAlign: 'left',
                                alignItems: isMobile ? 'flex-start' : 'center',
                                paddingLeft: '1.5vw',
                                flexDirection: isMobile ? 'column' : 'row'
                            }}>
                                <Typography style={{marginBottom: '0.5vh'}}
                                            variant='h6'>{t('school.skypeCaption')}</Typography>
                            </Box>
                            <Box style={{width: isMobile ? '100%' : '50%', alignItems: 'center'}}>
                                <CustomGridField disabledState={readOnly} name="skype"
                                                 title={t('school.skype')} label={t('school.skype')}
                                                 value={schoolData.skype} changeHandler={changeDataHandler}
                                                 capital={false} required={true} errors={formErrors}/>
                            </Box>
                        </Grid>
                         */}
                        {filesInfo?.map((element, index) => {
                            return (<React.Fragment key={element.attachmentInfo.typeId}>
                                {element.attachmentInfo.typeId === 4 ? FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "payment", readOnly) : null}
                            </React.Fragment>)
                        })}
                    </> : null}
                    {abroadSelection === 9 ? <>
                        <Grid style={{
                            width: '100%',
                            textAlign: 'left',
                            marginBottom: '2vh',
                            marginTop: '2vh',
                            paddingLeft: '1.5vw'
                        }}>
                            <Typography fontWeight='bold'
                                        variant='h2'>{t('school.contestTitle')}</Typography>
                        </Grid>
                        <CustomCheckboxField disabledState={readOnly} name="contest"
                                             title={t('school.contest')} label={t('school.contest')}
                                             value={schoolData.contest} changeHandler={changeDataHandler}
                                             className={classes.daneOsoboweGridField}/>
                        {schoolData.contest ? <>
                            {filesInfo?.map((element, index) => {
                                return (<>
                                    {element.attachmentInfo.typeId === 52 || element.attachmentInfo.typeId === 53 ?
                                        <React.Fragment key={element.attachmentInfo.typeId}>
                                            {FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, "podanie", readOnly)}
                                        </React.Fragment> : null}
                                </>)
                            })}
                        </> : null}
                    </> : null}
                </> : null}
                {/* FILE UPLOAD */}
                <Grid style={{
                    width: '100%', textAlign: 'left', marginBottom: '2vh', marginTop: '2vh', paddingLeft: '1.5vw'
                }}>
                    <Typography fontWeight='bold' variant='h2'>{t('school.requiredFiles')}</Typography>
                    <Typography variant='h6'>{t('school.filesNaming')}</Typography>
                </Grid>

                {filesInfo?.map((element, index) => {
                    return (<React.Fragment key={element.attachmentInfo.typeId}>
                        {(hasPlDiploma(schoolData) && filesForPLDiploma.includes(element.attachmentInfo.typeId)) ? FileUploadComponent(index, getFileWithCaption(element), dispatchFilesInfo, dispatchPopup, (element.attachmentInfo.namePL == "Zaświadczenie" ? "zaswiadczenie" : element.attachmentInfo.nameCode), readOnly) : null}
                        {((hasPlCertificate(schoolData) || hasForeignCertificate(schoolData)) && filesForPLDiplomaCertificate.includes(element.attachmentInfo.typeId)) ? FileUploadComponent(index, getFileWithCaption(element), dispatchFilesInfo, dispatchPopup, (element.attachmentInfo.namePL == "Zaświadczenie" ? "zaswiadczenie" : element.attachmentInfo.nameCode), readOnly) : null}
                        {!notGenericFileIds.includes(element.attachmentInfo.typeId) && hasForeignDiploma(schoolData) ? FileUploadComponent(index, element, dispatchFilesInfo, dispatchPopup, (element.attachmentInfo.nameCode == "dyplom_2st" ? "dyplom_zaswiadczenie" : element.attachmentInfo.nameCode), readOnly) : null}

                    </React.Fragment>)
                })}
                <BackAndSaveButtons saveButtonFunction={handleSave} loading={loading} readOnly={readOnly}/>

            </Grid>
        </Box>
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    width: "100%", maxHeight: '100vh'
                }
            }}
        >
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {t('school.exampleDiploma')}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                        OK
                    </Button>
                </Toolbar>
            </AppBar>
            <Grid container sx={{width: '100%'}} alignItems='flex-start' justifyContent='center'>
                <img alt='exampleDiploma' src={process.env.PUBLIC_URL + "certificateNumber.png"} height="100%"
                     width='100%'/>

            </Grid>
        </Dialog>

        <Dialog
            fullScreen
            open={openScale}
            onClose={handleCloseScale}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    width: "100%", maxHeight: '100vh'
                }
            }}
        >
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseScale}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {t('school.exampleDiploma')}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleCloseScale}>
                        OK
                    </Button>
                </Toolbar>
            </AppBar>
            <Grid container sx={{width: '100%'}} alignItems='flex-start' justifyContent='center'>
                <img alt='exampleDiploma' src={process.env.PUBLIC_URL + "certificateScale.png"} height="100%"/>

            </Grid>
        </Dialog>
        {/* ------------------------POPUPS------------------------ */}
        {Popup(popup, dispatchPopup) /*popup*/}
        {/* ------------------------POPUPS------------------------ */}
    </ThemeProvider>)
}

export function CustomGridField({
                                    label,
                                    value,
                                    disabledState = false,
                                    dataType = 'text',
                                    changeHandler,
                                    name,
                                    required,
                                    capital,
                                    mask,
                                    year,
                                    errors
                                }) {

    const classes = useStyles();
    if (value == null) {
        value = ''
    }

    return (<Grid item xs className={classes.daneOsoboweGridField}>
        {mask ? <InputMask
                mask={year ? "9999" : "99999999999"}
                value={value}
                disabled={disabledState}
                maskChar=" "
                onChange={changeHandler}
            >
                {inputProps => <TextField
                    {...inputProps}
                    className={(errors && errors.find(errors => errors.id === name)) ? classes.customTextFieldError : classes.customTextField}
                    InputProps={{
                        classes: {
                            input: classes.resizeDaneOsobowe,
                        },
                    }}
                    variant="outlined"
                    label={label} name={name} required={required}
                />}
            </InputMask>

            : <TextField
                className={(errors && errors.find(errors => errors.id === name)) ? classes.customTextFieldError : classes.customTextField}
                type={dataType}
                InputProps={{
                    classes: {
                        input: capital ? classes.resizeDaneOsoboweCapital : classes.resizeDaneOsobowe,
                    },
                }}
                variant="outlined"
                label={label} value={value} name={name} onChange={changeHandler} disabled={disabledState}
                required={required}
            />}
        <ErrorInfoGrid errors={errors} fieldName={name}></ErrorInfoGrid>
    </Grid>)
}