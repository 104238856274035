import React from 'react';
import { useStyles, useTheme } from '../css/Styles'
import { Typography,  Grid, Box, useMediaQuery, ThemeProvider, TextField, CardContent, Card, Button, InputAdornment, IconButton} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { PagesEnums } from '../utils/CustomEnums';
import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FetchService from '../services/FetchService'
import { useParams } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { createNotificationForResponse } from '../utils/Notifications';


async function FetchActivateAccount(activationLink, password) {
   return await FetchService.activateAccount(activationLink, password);
}

async function FetchResetPassword(activationLink, password) {
   return await FetchService.resetPassword(activationLink, password);
}

export default function Registration() {
   const theme = useTheme;
   const classes = useStyles();
   const { t } = useTranslation();
   const isMobile = useMediaQuery(theme.breakpoints.down('phone'))

   const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9~!@#$%^&*()-_=+\/.,?;\[\]\{\}':])(?=.*[a-z])[a-zA-Z0-9~!@#$%^&*()-_=+\/.,?;\[\]\{\}':]{8,100}$/;
   const [showPassword, setShowPassword] = React.useState(false)
   const [showRepeatPassword, setShowRepeatPassword] = React.useState(false)
   const [buttonClick, setButtonClick] = React.useState(0)
   const [loading, setLoading] = React.useState(false)
   const [password, setPassword] = React.useState('')
   const [passwordValidity, setPasswordValidity] = React.useState()
   const [repeatPassword, setRepeatPassword] = React.useState('')
   const [equality, setEquality] = React.useState(false)

   const { id } = useParams()
   const { reset } = useParams()

   
   const handleClickShowPassword = () => {
      setShowPassword(!showPassword)
   };

   const handleClickShowRepeatPassword = () => {
      setShowRepeatPassword(!showRepeatPassword)
   }

   function checkEquality() {
      return password === repeatPassword
   }

   const passwordHandler = (event) => {
      setPassword(event.target.value)
      setPasswordValidity(passwordRegex.test(event.target.value))
   }

   const repeatPasswordHandler = (event) => {
      setRepeatPassword(event.target.value)
   }

   const loginButtonHandler = () => {
      setEquality(checkEquality())
      setButtonClick(1)
      if(parseInt(reset) === 0){
         setLoading(true)
         if(checkEquality() && passwordValidity){
            FetchActivateAccount(id, password).then(response => {
               createNotificationForResponse(response)
            })
         }
      }else{
         setLoading(true)
         if(checkEquality() && passwordValidity){
            FetchResetPassword(id, password).then(response => {
               createNotificationForResponse(response)
            })
         }      
      }
      setLoading(false)
   }

   const BootstrapButton = styled(Button)({
      // boxShadow: 'none',
      // textTransform: 'none',
      fontSize: 15,
      color: 'white',
      // border: '1px solid',
      borderColor: 'none',
      lineHeight: 1,
      minHeight: 60,
      backgroundColor: '#30376c',
      fontFamily: [
         "Arial", 
         "sans-serif"
      ].join(','),
      '&:hover': {
        backgroundColor: theme.palette.secondary.hovered,
        borderColor: 'none',
        boxShadow: 'none',
      },
      // '&:active': {
      //   boxShadow: 'none',
      //   backgroundColor: '#f2f2f2',
      //   borderColor: 'none',
      // },
      // '&:focus': {
      //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      // },
    });

   return (
      <div style={{width: '100%', height: 'auto'}}>
      <ThemeProvider theme={theme}>
         <Box sx={{display: 'flex', width:'100%', height: '100%', padding: 2, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>     
            <Grid container sx={{width: '100%'}} alignItems='center' justifyContent='center' direction='column'>
               <Grid container sx={{width: isMobile ? '90%' : '50%'}}  direction='column' alignItems='center' justifyContent='center'>
                  <Typography sx={{color: theme.palette.secondary.main}} fontWeight='bold' variant='h7'>{parseInt(reset) === 0 ? t('registration.setPassword') : t('registration.changePassword')}</Typography>
                  <TextField 
                     sx={{margin: 2, width: '90%'}} 
                     className={classes.customTextField} 
                     type={showPassword ? 'text' : 'password'}
                     label={t('registration.insertPassword')} 
                     variant="outlined"
                     error={!passwordValidity && password !== '' ? true : false} 
                     value={password} 
                     onChange={passwordHandler}
                     InputProps={{
                        endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                     }}
                  />
                  {!passwordValidity && password !== '' ?
                     <Grid container sx={{width: '90%'}} alignItems='flex-start'>
                        <Typography variant='h3' color={theme.palette.delete.main}>
                           {t('registration.incorrectPasswordFormat')} 
                        </Typography> 
                     </Grid>
                  : null}
                  <TextField 
                     sx={{margin: 2, width: '90%'}} 
                     className={classes.customTextField} 
                     type={showRepeatPassword ? 'text' : 'password'}
                     label={t('registration.repeatPassword')}
                     variant="outlined" 
                     value={repeatPassword} 
                     onChange={repeatPasswordHandler}
                     InputProps={{
                        endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowRepeatPassword}
                            edge="end"
                          >
                            {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                     }}
                  />
                  {!equality && buttonClick !== 0 ?
                     <Grid container sx={{width: '90%'}} alignItems='flex-start'>
                        <Typography variant='h3' color={theme.palette.delete.main}>
                           {t('registration.nonIdenticalPasswords')} 
                        </Typography> 
                     </Grid>
                  : null}
                  <Card sx={{width: '80%', backgroundColor: theme.palette.additional.main, margin: '1vh', borderColor: theme.palette.secondary.main}} variant="outlined">
                     <CardContent>
                        <Typography sx={{color: theme.palette.secondary.main}} fontWeight='bold' variant='h2'>{parseInt(reset) === 0 ? t('registration.passwordInfo') : t('registration.passwordResetInfo')}</Typography>
                     </CardContent>
                  </Card>
                  <BootstrapButton sx={{width: isMobile ? '100%' : '60%', marginTop: '4vh'}} variant='standard' onClick={loginButtonHandler} 
                  component={NavLink} to={checkEquality() && passwordValidity ? PagesEnums.HOME.link : ''}
                  >{t('registration.confirm')}</BootstrapButton>  
                  {password === '' && repeatPassword === '' && buttonClick !== 0 ?
                     <Grid container sx={{width: '90%'}} justifyContent='center'>
                        <Typography variant='h3' color={theme.palette.delete.main}>
                           {t('registration.insertPassword')} 
                        </Typography> 
                     </Grid>
                  : null}
                  {loading ?
                  <LinearProgress sx={{width: isMobile ? '100%' : '60%', marginTop: '2vh'}} color="secondary"/> : <div></div>}

               </Grid>
               <Grid container style={{width: '80%',  marginTop: '2vh'}} justifyContent={isMobile ? 'center' : "flex-end"}>
                  <Button 
                     sx={{
                     width: '5%',
                     minWidth: 100,
                     color: theme.palette.primary.main, 
                     backgroundColor: theme.palette.secondary.main, 
                     '&:hover': 
                        {backgroundColor: theme.palette.secondary.hovered, 
                        borderColor: 'none', boxShadow: 'none',},}
                     } 
                     component={NavLink}
                     to={PagesEnums.REGISTER.link}
                     variant='standard'>
                     {t('backButton')}
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </ThemeProvider>
      </div>
   );

}