import { createNotificationForResponse} from '../../utils/Notifications';
import { apiGatewayUrl as ApiUrl, HTTP_POST } from '../../utils/Consts';
import AuthHeader from '../../services/AuthHeader';
const URL = ApiUrl;

class FetchServiceMessages {   
    getMessages = async (profileId) => {
        const response = await fetch(URL + 'getMessageByProfileId', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                "profile_id": profileId,
            })
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    countUnreadMessages = async (profileId) => {
        const response = await fetch(URL + 'countUnreadMessages', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                "profile_id": profileId,
            })
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    setUnread = async (messageProfileId) => {
        const response = await fetch(URL + 'markMessageAsUnread', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                "messageProfileId": messageProfileId,
            })
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    setRead = async (messageProfileId) => {
        const response = await fetch(URL + 'markMessageAsRead', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                "messageProfileId": messageProfileId,
            })
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    

}

export default new FetchServiceMessages();