import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField } from "@material-ui/core";
import { Controller, useForm } from 'react-hook-form';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})


 /* PARAMETRY
    title: String
    content: String
    visible: boolean
    form: [{formLabel: String, formAction: void, constraints: {name: {value, message }}}]   (opcjonalny)
    buttons: [{buttonLabel: String, buttonAction: void, red: boolean}]     (opcjonalny)
                                                        ^ domyślny kolor przycisku to niebieski, przekazanie red: true spowoduje zmianę na czerwony


    Przykład wywołania:

        const handleChange = (event, ifDisabled) => {
            if(ifDisabled !== null) {
                setDisabled(ifDisabled)
            }
            if(event) {
                setText(event.target.value)
            }
        }
        <DialogComponent title={"TYTUŁ"} content={"TEKST"} visible={openDialog}
        form={[
        { formLabel: "USER", formAction: handleChange, constraints: { minLength: {value: 6, message: "ZA KRÓTKI"}, required: 'required', pattern: { value: onlyDigits, message: 'Zły format'} } },
        { formLabel: "MAIL", formAction: handleChange }
        ]}
        buttons={[
        { buttonLabel: "TAK", buttonAction: handleClose },
        { buttonLabel: "NIE", buttonAction: handleClose, red: true }
        ]}
    />
    */


export default function DialogComponent({ titleClassname, title, contentClassname, content, visible, form, buttons }) {

    const { control } = useForm({ mode: "onChange" });

    return (
        <Dialog
            open={visible}
            TransitionComponent={Transition}>
            <DialogTitle className={titleClassname}>
                {title}
            </DialogTitle>
            <DialogContent className={contentClassname}>
                <DialogContentText>
                    {content}
                </DialogContentText>
                {form ?
                    form.map(element => {
                        return (
                            <div>
                                <form>
                                    <Controller
                                        name={element.formLabel}
                                        control={control}
                                        rules={element.constraints}
                                        render={({ field: { onChange, onBlur }, fieldState: { error }, formState: { isValid } }) =>
                                            <TextField
                                                margin="dense"
                                                fullWidth
                                                label={element.formLabel}
                                                variant="filled"
                                                onChange={event => {
                                                    onChange(event);
                                                    element.formAction(event, null)
                                                }}
                                                onBlur={event => {
                                                    onBlur(event);
                                                    element.formAction(null, !isValid);
                                                }}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        }
                                    />
                                </form>
                            </div>
                        )
                    }) : null}
            </DialogContent>
            <DialogActions>
                {buttons ?
                    buttons.map((element, index) => {
                        return (
                            <Button 
                                key={index}
                                color={element.red ? 'secondary' : 'primary'}
                                onClick={() => element.buttonAction()}>
                                {element.buttonLabel}
                            </Button>
                        )
                    }) : null}
            </DialogActions>
        </Dialog>
    )
}