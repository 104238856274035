import React from 'react';
import {useStyles, useTheme} from '../css/Styles';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Link,
    ThemeProvider,
    Toolbar,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import {styled} from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import {useTranslation} from 'react-i18next';
import i18n from "i18next";
import Popup, {popupReducer} from "../components/popup/Popup";

export default function Footer() {
    const theme = useTheme;
    const classes = useStyles();
    const {t} = useTranslation();

    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))

    const [popup, dispatchPopup] = React.useReducer(popupReducer, null);


    const BootstrapButton = styled(Button)({
        // boxShadow: 'none',
        // textTransform: 'none',
        fontSize: 15,
        color: 'white',
        padding: '6px 12px',
        // border: '1px solid',
        borderColor: 'none',
        lineHeight: 1,
        backgroundColor: 'none',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: theme.palette.secondary.hovered,
            borderColor: 'none',
            boxShadow: 'none',
        },
        // '&:active': {
        //   boxShadow: 'none',
        //   backgroundColor: '#f2f2f2',
        //   borderColor: 'none',
        // },
        // '&:focus': {
        //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    });

    return (
        <ThemeProvider theme={theme}>
            {Popup(popup, dispatchPopup) /*popup*/}
            <div>
                <Toolbar sx={{padding: 2}} className={classes.footerBlue}>
                    <Grid container direction={isMobile ? 'column' : 'row'} alignItems="center"
                          justifyContent="space-between" className={classes.content}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: "flex-start",
                            flexDirection: isMobile ? 'column' : 'row'
                        }}>
                            <Grid className={classes.footerTypo} sx={{minWidth: 320, padding: 1}} container
                                  direction='column' alignItems="flex-start">
                                <Typography className={classes.footerText} variant='h4'>
                                    {t("footer.plRecrutation")}
                                </Typography>
                                <Typography className={classes.footerText} variant='h4'>
                                    {t("footer.plAddress")}
                                </Typography>
                                <Typography className={classes.footerText} variant='h5'>
                                    {t("footer.plAdditionalInfo")}
                                </Typography>
                            </Grid>

                            <Grid className={classes.footerTypo} sx={{minWidth: 270, padding: 1}} container
                                  direction='column' alignItems="flex-start">
                                <Typography component={Link} href="https://rekrutacja.p.lodz.pl/rekrutacja_kontakt"
                                            target="_blank" className={classes.footerText} variant='h5'>
                                    {t("footer.contactPL")}
                                </Typography>
                                <Typography component={Link}
                                            href={i18n.language == 'pl' ? "https://rekrutacja.p.lodz.pl/pl/contact/" : "https://rekrutacja.p.lodz.pl/en/contact-0"}
                                            target="_blank" className={classes.footerText} variant='h5'>
                                    {t("footer.contactForeign")}
                                </Typography>
                                <Typography component={Link} href="https://ids.p.lodz.pl/kontakt/" target="_blank"
                                            className={classes.footerText} variant='h5'>
                                    {t("footer.contactPhD")}
                                </Typography>
                            </Grid>

                            <Grid className={classes.footerTypo} sx={{minWidth: 220, padding: 1}} container
                                  direction='column' alignItems="flex-start">
                                <Typography component={Link}
                                            onClick={() => dispatchPopup({type: 'OPEN', payload: 'footer.steps'})}
                                            sx={{fontWeight: 'bold'}} className={classes.footerText} variant='h4'>
                                    {t("footer.steps")}
                                </Typography>
                                <Typography component={Link}
                                            href="https://rekrutacja.p.lodz.pl/sites/default/files/2024-05/regulamin_rekrutacji%20_2024_2025.pdf"
                                            target="_blank" className={classes.footerText} variant='h5'>
                                    {t("footer.rules")} 
                                </Typography>
                                <Typography component={Link}
                                            href="https://rekrutacja.p.lodz.pl/sites/default/files/2024-05/RODO_GDPR_2024.pdf"
                                            target="_blank" className={classes.footerText} variant='h5'>
                                    {t("footer.rodo")}
                                </Typography>
                            </Grid>

                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                            <BootstrapButton variant="standard" component={Link} href="https://rekrutacja.p.lodz.pl/"
                                             target="_blank">{t("footer.recrutationButton")}</BootstrapButton>
                            <IconButton color='primary' aria-label="fb" size="large" component={Link}
                                        href="https://www.facebook.com/Politechnika.Lodzka/" target="_blank">
                                <FacebookIcon sx={{fontSize: 40}}/>
                            </IconButton>
                            <IconButton color='primary' aria-label="yt" size="large" component={Link}
                                        href="https://www.youtube.com/channel/UCbBnxpxg1DbSrIBwvebJghg" target="_blank">
                                <YouTubeIcon sx={{fontSize: 40}}/>
                            </IconButton>
                            <IconButton color='primary' aria-label="ig" size="large" component={Link}
                                        href="https://www.instagram.com/politechnika_lodzka/" target="_blank">
                                <InstagramIcon sx={{fontSize: 40}}/>
                            </IconButton>
                        </Box>
                    </Grid>
                </Toolbar>
            </div>
        </ThemeProvider>
    )
}