import React, {useEffect, useState, useCallback, useContext} from 'react';
import { useStyles } from '../css/Styles';
import { get, refreshTokenStorage } from "../services/StorageService";
import { useTranslation } from 'react-i18next';
import { secondsBeforeJwtExpire } from '../utils/Consts';
import AuthService from '../services/AuthService';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Button, Snackbar } from '@material-ui/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { SessionTypesEnums } from "../utils/CustomEnums";
import {DatesContext} from "./GlobalDatesContext";

const LoadingCss = `
border-color: white;
border-bottom-color: #37ADAF;
`

export default function UserSessionCheck() {

    const [time, setTime] = useState(1);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = require("jsonwebtoken");
    const { t } = useTranslation();
    const classes = useStyles();
    const datesContext = useContext(DatesContext)

    
    const checkJWTexpired = useCallback(() => {
        let jwt = get("token", SessionTypesEnums.COOKIE);
        if (jwt) {
            const expireTimestamp = token.decode(jwt)["user"]["exp"]
            const expireDate = new Date(parseInt(expireTimestamp))
            const period = 1000 * secondsBeforeJwtExpire;
            const currentDate = new Date();
            const seconds = parseInt((expireDate.getTime() - datesContext.currentDateTime) / 1000);
            const time = ~~(seconds / 60) + ':' + (seconds % 60 < 10 ? "0" : "") + seconds % 60;    // zamiana sekund na format mm:ss
            setTime(time);
            if (expireDate.getTime() - currentDate.getTime() < period) {
                if (expireDate.getTime() < currentDate.getTime()) {
                    AuthService.logout();
                }
                setVisible(true);
            }
        }
    }, [token]);

    const refreshToken = () => {
        setLoading(true);
        const refreshToken = get("refreshToken", SessionTypesEnums.COOKIE);
        AuthService.refreshToken(refreshToken)
            .then(response => {
                refreshTokenStorage(response);
                setLoading(false);
                setVisible(false);
            });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            checkJWTexpired();
        }, 1000);
        return () => clearInterval(interval);
    }, [checkJWTexpired])

    return (
        <Snackbar
            open={visible}
            onClick={() => refreshToken()}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            message={
                <div className={classes.sessionTitle}>
                    <label>{t('auth.refreshToken1')} {time} s. {t('auth.refreshToken2')}</label>
                </div>
            }
            action={
                <Button className={classes.sessionButton}>
                    {t('auth.extendSession')} {loading ? <ClipLoader size={36} css={LoadingCss} />
                        : <AutorenewIcon className={classes.sessionIcon} />}
                </Button>
            }
        />
    )
}